import React, { useEffect, useState } from 'react';
import DashboardLayout from '../layouts/DashboardLayout';
import { Button, Table, Modal, Form, Input, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { getCategories, createCategory, bulkUploadCategories } from '../services/orderServiceApi/categoriesApi';
import { ApiResponse } from '../types/response';
import { CreateCategoryDto } from '../types/category';
import DownloadTemplateButton from '../components/DownloadTemplateButton';

const Categories = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [categories, setCategories] = useState<CreateCategoryDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      const responseData: ApiResponse<CreateCategoryDto[]> = response?.data;
      if (responseData?.status && responseData?.statusCode === 200) {
        setCategories(responseData.result);
      }
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  const handleAddCategory = async (values: CreateCategoryDto) => {
    setLoading(true);
    try {
      const response = await createCategory(values);
      const responseData: ApiResponse<CreateCategoryDto> = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Category added successfully!");
        setIsModalVisible(false);
        fetchCategories();
        form.resetFields();
      } else {
        message.error("Failed to add category");
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error adding category");
      console.error("Error adding category:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBulkUpload = async (file: File) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: CreateCategoryDto[] = XLSX.utils.sheet_to_json(worksheet);

      if (jsonData?.length === 0) {
        message.error("Empty File!");
        return;
      }

      if (!jsonData.every(item => item.name)) {
        message.error('Invalid file format. Ensure it contains "name" column.');
        return;
      }

      const response: any = await bulkUploadCategories(jsonData);
      const responseData = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Categories uploaded successfully!");
        fetchCategories();
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error uploading categories");
      console.error("Error uploading categories:", error);
    }
  };

  const columns = [
    {
      title: 'Category Name',
      dataIndex: 'name',
      key: 'name',
    }
  ];

  return (
    <DashboardLayout>
      <DashboardLayout.Header title="Manage Categories">
        <DownloadTemplateButton templateName="bulk_categories_template.xlsx" buttonText="Download Categories Template" />
        <Button type="primary" onClick={() => setIsModalVisible(true)}>Add Category</Button>
        <Upload
          beforeUpload={(file) => {
            handleBulkUpload(file);
            return false;
          }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Bulk Upload</Button>
        </Upload>
      </DashboardLayout.Header>
      <DashboardLayout.Body>
        <Table dataSource={categories} columns={columns} rowKey="id" />

        <Modal
          title="Add New Category"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleAddCategory}
          >
            <Form.Item
              name="name"
              label="Category Name"
              rules={[{ required: true, message: 'Please enter the category name' }]}
            >
              <Input placeholder="Enter category name" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Add Category
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </DashboardLayout.Body>
    </DashboardLayout>
  );
}

export default Categories;
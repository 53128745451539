import moment from "moment";
import { IBomItemForm } from "../types/groyyo_plus/bomItem";

export function roundNumber(number: string | number) {
  return parseFloat(number?.toString() || "0")
    .toFixed(2)
    .replace(/\.?0+$/, "");
}

export const setToken = (token: string) => {
  localStorage.setItem("authToken", token);
};

export const getToken = () => {
  return localStorage.getItem("authToken");
};

export const removeToken = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("userDetails");
  localStorage.removeItem("selectedModule");
};

export const isAuthenticated = () => {
  return !!getToken();
};

export const formatDate = (isoDate: string) => {
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const createItemWrapper = (
  createFunction: (data: any) => Promise<any>
) => {
  return async (data: any) => {
    await createFunction(data);
  };
};

export const calculateIndividualBomTotal = (form: IBomItemForm) => {
  let qty = parseFloat(form.quantity || "0");
  qty += Math.round((qty * parseFloat(form.extra || "0")) / 100);
  return qty * parseFloat(form.rate || "0") || 0;
};

export function formatDateToDDMMYYYY(dateString: string) {
  if (!dateString) return "";
  const parts = dateString.split("-");
  if (parts.length !== 3) return dateString;
  return parts.reverse().join("/");
}

export function formatToDDMMYYYY(dateString: string) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

const SECONDS_IN_DAY = 24 * 60 * 60;
const MISSING_LEAP_YEAR_DAY = SECONDS_IN_DAY * 1000;
const MAGIC_NUMBER_OF_DAYS = 25567 + 2;

export const getJsDateFromExcel = (excelDate: number) => {
  if (!Number(excelDate)) {
    return moment(excelDate)?.isValid()
      ? moment(excelDate).format("DD/MM/YYYY")
      : "";
  }

  const delta = excelDate - MAGIC_NUMBER_OF_DAYS;
  const parsed = delta * MISSING_LEAP_YEAR_DAY;
  const date = new Date(parsed);

  return moment(date)?.isValid() ? moment(date).format("DD/MM/YYYY") : "";
};

export function convertNumber(number: number) {
  let res;
  if (number < 1000) {
    res = number.toString();
  } else if (number < 100000) {
    res = `${roundNumber((number / 1000).toFixed(1))}K`;
  } else {
    res = `${roundNumber((number / 10000000).toFixed(2))} Cr`;
  }
  return res;
}

export function restructurePermissions(userVerticals: any) {
  const permissionMap: any = {};    
  for (const key in PERMISSION_ACTION) {
    permissionMap[key] = [];
  }  
  userVerticals?.forEach((vertical: any) => {
    vertical?.userPermissions?.forEach(({ permission }: any) => {
      if (permission?.action && permission?.module_name) {
        if (!permissionMap[permission?.action].includes(permission?.module_name)) {
          permissionMap[permission?.action].push(permission?.module_name);
        }
      }
    });
  });
  
  return permissionMap;
}

export const PERMISSION_ACTION = {
  VIEW: "VIEW",
  CREATE: "CREATE",
  EDIT: "EDIT",
  DELETE: "DELETE",
};

export const PERMISSION_MODULE = {
  DASHBOARD: "DASHBOARD",
  SALES_ORDERS: "SALES_ORDERS",
  BOM: "BOM",
  MATERIAL_RECEIVABLE: "MATERIAL_RECEIVABLE",
  MATERIAL_ISSUE: "MATERIAL_ISSUE",
  MATERIAL_TRACKING: "MATERIAL_TRACKING",
  PRODUCTION: "PRODUCTION",
  WIP: "WIP",
  DISPATCH: "DISPATCH",
  FACTORY: "FACTORY",
  VERTICAL: "VERTICAL",
  SETTINGS: "SETTINGS",
  USER_MANAGEMENT: "USER_MANAGEMENT",
};

import React from 'react';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import CustomDropdownWithAdd from '../../../../components/CustomDropdownWithAdd';
import { createUnit, getUnits } from '../../../../services/orderServiceApi/unitApi';
import { IBomItemForm } from '../../../../types/groyyo_plus/bomItem';
import CustomDropdown from '../../../../components/CustomDropdown';
import { getAllCurrencies } from '../../../../services/orderServiceApi/salesOrderApi';
import { createSupplier, getSupplierList } from '../../../../services/orderServiceApi/supplierApi';
import dayjs from 'dayjs';
import { createItemWrapper } from '../../../../utilities/helper';
import { useTranslation } from 'react-i18next';

interface CollectNewMaterialListItemProps {
    collectNewMaterial: IBomItemForm;
    formData: any;
    errors: any;
    onValuesChange: (changedValues: any) => void;
}

const CollectNewMaterialListItem: React.FC<CollectNewMaterialListItemProps> = ({
    collectNewMaterial,
    formData,
    errors,
    onValuesChange,
}) => {
    const [form] = Form.useForm();
    const { t }:any = useTranslation();

    return (
        <div className="shadow-md rounded-lg mb-4">
            <div className="flex flex-row items-center justify-between cursor-pointer p-2">
                <div className="flex flex-row gap-4">
                    <img
                        src={
                            collectNewMaterial?.material?.type === "Fabric"
                                ? collectNewMaterial?.material?.fabric?.imageUrl || "/svg/NoImage.svg"
                                : collectNewMaterial?.material?.trim?.imageUrl || "/svg/NoImage.svg"
                        }
                        alt={
                            collectNewMaterial?.material?.type === "Fabric"
                                ? collectNewMaterial?.material?.fabric?.name
                                : collectNewMaterial?.material?.trim?.name
                        }
                        className="!w-[3rem] !h-[3rem] rounded-md"
                    />
                    <div className="flex flex-col justify-start">
                        <p className="font-semibold">
                            {collectNewMaterial?.material?.type === "Fabric"
                                    ? collectNewMaterial?.material?.fabric?.name
                                    : collectNewMaterial?.material?.trim?.name}
                        </p>
                        {'composition' in collectNewMaterial && (
                            <p className="text-xs">{String(collectNewMaterial?.composition)}</p>
                        )}
                    </div>
                </div>
                <div className="mr-5 flex gap-2 font-semibold text-lg">
                    <span className="italic">{formData?.total?.toFixed(2)}</span>
                    <span>{formData?.currency?.code}</span>
                </div>
            </div>
            <div className="p-2">
                <Form layout="vertical" form={form}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <CustomDropdownWithAdd
                                form={form}
                                label={t('sales_order_bom.collect_new_materials.list_item.supplier')}
                                placeholder={t('sales_order_bom.collect_new_materials.list_item.supplier_placeholder')}
                                fetchItems={getSupplierList}
                                addItem={createItemWrapper(createSupplier)}
                                onSelect={(item) => onValuesChange({ supplier: item })
                                }
                                modalFields={[
                                    { name: 'name', label: t('sales_order_bom.collect_new_materials.supplier_form.name'), required: true },
                                    { name: 'addressLine1', label: t('sales_order_bom.collect_new_materials.supplier_form.address1'), required: true },
                                    { name: 'addressLine2', label: t('sales_order_bom.collect_new_materials.supplier_form.address2'), required: false },
                                    { name: 'city', label: t('sales_order_bom.collect_new_materials.supplier_form.city'), required: true },
                                    { name: 'state', label: t('sales_order_bom.collect_new_materials.supplier_form.state'), required: true },
                                    { name: 'country', label: t('sales_order_bom.collect_new_materials.supplier_form.country'), required: true },
                                    { name: 'pinCode', label: t('sales_order_bom.collect_new_materials.supplier_form.pin_code'), required: true },
                                    { name: 'gstNo', label: t('sales_order_bom.collect_new_materials.supplier_form.gst_no'), required: false },
                                    { name: 'contactPerson', label: t('sales_order_bom.collect_new_materials.supplier_form.contact_person'), required: false },
                                    { name: 'contactNo', label: t('sales_order_bom.collect_new_materials.supplier_form.contact_no'), required: false },
                                    { name: 'email', label: t('sales_order_bom.collect_new_materials.supplier_form.email'), required: false },
                                ]}
                                formItemName={`supplier-${collectNewMaterial.id}`}
                                required={true}
                                value={formData?.supplier?.name || ''}
                                validateStatus={errors?.supplier ? 'error' : undefined}
                                help={errors?.supplier && t('sales_order_bom.collect_new_materials.list_item.supplier_help')}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('sales_order_bom.collect_new_materials.list_item.quantity')}
                                validateStatus={errors?.quantity ? 'error' : ''}
                                help={errors?.quantity && t('sales_order_bom.collect_new_materials.list_item.quantity_help')}
                            >
                                <Input
                                    placeholder={t('sales_order_bom.collect_new_materials.list_item.quantity_placeholder')}
                                    value={formData?.quantity}
                                    onChange={(e) =>
                                        onValuesChange({quantity: e.target.value})
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <CustomDropdownWithAdd
                                form={form}
                                label={t('sales_order_bom.collect_new_materials.list_item.unit')}
                                placeholder={t('sales_order_bom.collect_new_materials.list_item.unit_placeholder')}
                                fetchItems={getUnits}
                                addItem={createItemWrapper(createUnit)}
                                onSelect={(item) =>
                                    onValuesChange({unit: item})
                                }
                                modalFields={[
                                    { name: 'name', label: t('sales_order_bom.collect_new_materials.unit_form.name'), required: true },
                                ]}
                                formItemName={`unit-${collectNewMaterial.id}`}
                                required={true}
                                value={formData?.unit?.name || ''}
                                validateStatus={errors?.unit ? 'error' : undefined}
                                help={errors?.unit && t('sales_order_bom.collect_new_materials.list_item.unit_help')}
                            />
                        </Col>
                        <Col span={12}>
                            <CustomDropdown
                                label={t('sales_order_bom.collect_new_materials.list_item.currency')}
                                placeholder={t('sales_order_bom.collect_new_materials.list_item.currency_placeholder')}
                                fetchItems={getAllCurrencies}
                                onSelect={(item) => onValuesChange({currency: item})}
                                name="currency"
                                required={true}
                                value={formData?.currency?.name}
                                validateStatus={errors?.currency ? 'error' : undefined}
                                help={errors?.currency && t('sales_order_bom.collect_new_materials.list_item.currency_help')}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('sales_order_bom.collect_new_materials.list_item.extra')}
                                validateStatus={errors?.extra ? 'error' : ''}
                                help={errors?.extra && t('sales_order_bom.collect_new_materials.list_item.extra_help')}
                            >
                                <Input
                                    placeholder={t('sales_order_bom.collect_new_materials.list_item.extra_placeholder')}
                                    value={formData?.extra}
                                    onChange={(e) =>
                                        onValuesChange({extra: e.target.value})
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('sales_order_bom.collect_new_materials.list_item.average')}
                                validateStatus={errors?.average ? 'error' : ''}
                                help={errors?.average && t('sales_order_bom.collect_new_materials.list_item.average_help')}
                            >
                                <Input
                                    placeholder={t('sales_order_bom.collect_new_materials.list_item.average_placeholder')}
                                    value={formData?.average}
                                    onChange={(e) =>
                                        onValuesChange({average: e.target.value})
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('sales_order_bom.collect_new_materials.list_item.rate')}
                                validateStatus={errors?.rate ? 'error' : ''}
                                help={errors?.rate && t('sales_order_bom.collect_new_materials.list_item.rate_help')}
                            >
                                <Input
                                    placeholder={t('sales_order_bom.collect_new_materials.list_item.rate_placeholder')}
                                    value={formData?.rate}
                                    onChange={(e) =>
                                        onValuesChange({rate: e.target.value})
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={t('sales_order_bom.collect_new_materials.list_item.in_house_date')}
                                validateStatus={errors?.inHouseDate ? 'error' : ''}
                                help={errors?.inHouseDate && t('sales_order_bom.collect_new_materials.list_item.in_house_date_help')}
                            >
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    placeholder={t('sales_order_bom.collect_new_materials.list_item.in_house_date_placeholder')}
                                    className="w-full"
                                    value={formData?.inHouseDate}
                                    onChange={(date) =>
                                        onValuesChange({inHouseDate: date})
                                    }
                                    disabledDate={(current) => {
                                        return current && current < dayjs().startOf('day');
                                      }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default CollectNewMaterialListItem;
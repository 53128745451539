import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GrDrag } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';

interface SortableItemProps<T> {
    process: T;
    index: number;
    getProcessName: (process: T) => string;
    getDependencyName: (process: T) => string;
    getAdditionalDetails?: (process: T) => React.ReactNode;
}

const SortableItem = <T extends { id: number }>({
    process,
    index,
    getProcessName,
    getDependencyName,
    getAdditionalDetails,
}: SortableItemProps<T>) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: String(process.id) });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? 'grabbing' : 'grab',
    };
    const { t }: any = useTranslation();

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className="p-4 mb-2 border rounded-lg bg-white shadow-sm flex items-center justify-between"
        >
            <div className="flex items-center gap-2">
                <GrDrag className="text-xl text-gray-600" />
                <div>
                    <h3 className="font-bold">{getProcessName(process) || 'Untitled Process'}</h3>
                    <p>
                        <strong>{t("components.sortable_item.dependency_on")}:</strong> {getDependencyName(process) || 'None'}
                    </p>
                    {getAdditionalDetails && <div>{getAdditionalDetails(process)}</div>}
                </div>
            </div>
            <div className="text-lg font-bold text-orange-500">{index + 1}</div>
        </div>
    );
};

export default SortableItem;
import React, { useState } from 'react';
import { Modal, Tabs, Form, Input, Select, Button, Upload, Row, Col, message } from 'antd';
import { motion, AnimatePresence } from 'framer-motion';
import palette from '../../../../constants/pallete';
import CustomDropdownWithAdd from '../../../../components/CustomDropdownWithAdd';
import { createUnit, getUnits } from '../../../../services/orderServiceApi/unitApi';
import { uploadFile } from '../../../../services/orderServiceApi/salesOrderApi';
import { UploadOutlined } from '@ant-design/icons';
import { createTrimColor, createTrimSize, getTrimColorList, getTrimSizeList } from '../../../../services/orderServiceApi/trimApi';
import { createFabricColor, createFabricSize, getFabricColorList, getFabricSizeList } from '../../../../services/orderServiceApi/fabricApi';
import { createItemWrapper } from '../../../../utilities/helper';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;

interface CreateNewMaterialProps {
    visible: boolean;
    onClose: () => void;
    onSubmit: (data: any) => void;
}

const CreateNewMaterial: React.FC<CreateNewMaterialProps> = ({ visible, onClose, onSubmit }) => {
    const [activeTab, setActiveTab] = useState<'Fabric' | 'Trim'>('Fabric');
    const [form] = Form.useForm();
    const [file, setFile] = useState<string | undefined>();
    const [unit, setUnit] = useState<{ id: number; name: string }>({ id: -1, name: '' });
    const [color, setColor] = useState<{ id: number; name: string }>({ id: -1, name: '' });
    const [size, setSize] = useState<{ id: number; name: string }>({ id: -1, name: '' });
    const { t }: any = useTranslation();

    const resetStates = () => {
        form.resetFields();
        setFile(undefined);
        setUnit({ id: -1, name: '' });
        setColor({ id: -1, name: '' });
        setSize({ id: -1, name: '' });
    };

    const handleTabChange = (key: string) => {
        setActiveTab(key as 'Fabric' | 'Trim');
        resetStates();
    };

    const isFabric = activeTab === 'Fabric';

    const beforeUpload = (file: any) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
            message.error(t('sales_order_bom.create_new_material.image.upload_requirement'));
        }
        return isLt5M;
    };

    const handleImageUpload = async (info: any) => {
        const file = info.file;
        if (!file || !beforeUpload(file)) return;

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await uploadFile(formData);
            const responseData = response?.data;
            const uploadedUrl = responseData?.result?.imageUrl;
            if (uploadedUrl) {
                setFile(uploadedUrl);
                message.success(t('sales_order_bom.create_new_material.image.upload_success'));
            }
        } catch (error) {
            message.error(t('sales_order_bom.create_new_material.image.upload_failed'));
            console.error('Upload error:', error);
        }
    };

    const handleDone = async () => {
        try {
            const values = await form.validateFields();
            if (!file) {
                message.error(t('sales_order_bom.create_new_material.image.image_required'));
                return;
            }

            const payload = {
                ...values,
                type: activeTab,
                imageUrl: file,
                unitId: unit?.id,
                fabricSizeId: size?.id,
                fabricColorId: color?.id
            };

            onSubmit(payload);
            resetStates();
            onClose();
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const handleCancel = () => {
        resetStates();
        onClose();
    };

    const tabContentVariants = {
        hidden: { opacity: 0, x: 20 },
        visible: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -10 },
    };

    return (
        <Modal
            open={visible}
            onCancel={handleCancel}
            footer={null}
            maskClosable={false}
            width="60vw"
            bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                height: '70vh',
            }}
        >
            <Tabs
                activeKey={activeTab}
                onChange={handleTabChange}
                tabBarStyle={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#f9f9f9',
                }}
                className="custom-tabs"
            >
                <TabPane
                    tab={
                        <span
                            style={{
                                padding: '0.5rem 1rem',
                                backgroundColor: isFabric ? palette.secondary : 'transparent',
                                color: isFabric ? 'white' : 'black',
                                borderRadius: '4px',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            {t('sales_order_bom.create_new_material.tabs.fabric')}
                        </span>
                    }
                    key="Fabric"
                />
                <TabPane
                    tab={
                        <span
                            style={{
                                padding: '0.5rem 1rem',
                                backgroundColor: !isFabric ? palette.secondary : 'transparent',
                                color: !isFabric ? 'white' : 'black',
                                borderRadius: '4px',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            {t('sales_order_bom.create_new_material.tabs.trim')} 
                        </span>
                    }
                    key="Trim"
                />
            </Tabs>
            <AnimatePresence mode="wait">
                <motion.div
                    key={activeTab}
                    variants={tabContentVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    transition={{ duration: 0.5 }}
                    style={{
                        flex: 1,
                        overflowY: 'auto',
                        paddingRight: '1rem',
                    }}
                >
                    <Form form={form} layout="vertical">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item 
                                    label={t('sales_order_bom.create_new_material.form.name')}
                                    name="name" 
                                    rules={[{ required: true, message: t('sales_order_bom.create_new_material.form.name_required') }]}
                                >
                                    <Input
                                        name="name"
                                        placeholder={t('sales_order_bom.create_new_material.form.name_placeholder')}
                                    />
                                </Form.Item>
                            </Col>
                            {isFabric && (
                                <Col span={12}>
                                    <Form.Item
                                        label={t('sales_order_bom.create_new_material.form.composition')}
                                        name="composition"
                                        rules={[{ required: true, message: t('sales_order_bom.create_new_material.form.composition_required') }]}
                                    >
                                        <Input placeholder={t('sales_order_bom.create_new_material.form.name.placeholder')} />
                                    </Form.Item>
                                </Col>
                            )}
                            <Col span={12}>
                                <Form.Item  label={t('sales_order_bom.create_new_material.form.description')} name="description">
                                    <Input placeholder={t('sales_order_bom.create_new_material.form.description_placeholder')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={t('sales_order_bom.create_new_material.form.hsn_code')} name="hsnCode">
                                    <Input placeholder={t('sales_order_bom.create_new_material.form.hsn_code_placeholder')} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <CustomDropdownWithAdd
                                    form={form}
                                    label={t('sales_order_bom.create_new_material.form.color')}
                                    placeholder={t('sales_order_bom.create_new_material.form.color_placeholder')}
                                    fetchItems={isFabric ? getFabricColorList : getTrimColorList}
                                    addItem={createItemWrapper(isFabric ? createFabricColor : createTrimColor)}
                                    onSelect={(item) => setColor({ id: item.id, name: item.name })}
                                    modalFields={[
                                        { name: 'name', label: t('sales_order_bom.create_new_material.form.color_modal.name'), required: true },
                                        { name: 'hexCode', label: t('sales_order_bom.create_new_material.form.color_modal.hex_code'), required: true },
                                    ]}
                                    formItemName='color'
                                    required={true}
                                    value={color?.name}
                                />
                            </Col>
                            <Col span={12}>
                                <CustomDropdownWithAdd
                                    form={form}
                                    label={t('sales_order_bom.create_new_material.form.size')}
                                    placeholder={t('sales_order_bom.create_new_material.form.size_placeholder')}
                                    fetchItems={isFabric ? getFabricSizeList : getTrimSizeList}
                                    addItem={createItemWrapper(isFabric ? createFabricSize : createTrimSize)}
                                    onSelect={(item) => setSize({ id: item.id, name: item.name })}
                                    modalFields={[
                                        { name: 'name', label: t('sales_order_bom.create_new_material.form.size_modal.name'), required: true },
                                    ]}
                                    formItemName='size'
                                    required={true}
                                    value={size?.name}
                                />
                            </Col>
                            <Col span={12}>
                                <CustomDropdownWithAdd
                                    form={form}
                                    label={t('sales_order_bom.create_new_material.form.unit')}
                                    placeholder={t('sales_order_bom.create_new_material.form.unit_placeholder')}
                                    fetchItems={getUnits}
                                    addItem={createItemWrapper(createUnit)}
                                    onSelect={(item) => setUnit({ id: item.id, name: item.name })}
                                    modalFields={[
                                        { name: 'name', label: t('sales_order_bom.create_new_material.form.unit_modal.name'), required: true },
                                    ]}
                                    formItemName='unit'
                                    required={true}
                                    value={unit?.name}
                                />
                            </Col>
                            <Col span={24}>
                                <div className='flex flex-row justify-start gap-4 items-center'>
                                    {file && (
                                        <div style={{ marginTop: '1rem' }}>
                                            <img
                                                src={file}
                                                alt="Preview"
                                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                                className='rounded-lg'
                                            />
                                        </div>
                                    )}
                                    <Upload
                                        accept="image/*"
                                        showUploadList={false}
                                        beforeUpload={beforeUpload}
                                        customRequest={handleImageUpload}
                                    >
                                        <Button
                                            icon={<UploadOutlined />}
                                            style={{ backgroundColor: palette.secondary, color: 'white' }}
                                        >
                                             {t('sales_order_bom.create_new_material.image.upload')}
                                        </Button>
                                    </Upload>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </motion.div>
            </AnimatePresence>
            <Button
                type="primary"
                className="w-full"
                style={{
                    backgroundColor: palette.secondary,
                    borderColor: palette.secondary,
                    marginTop: '1rem',
                }}
                onClick={handleDone}
            >
                {t('sales_order_bom.create_new_material.actions.done')}
            </Button>
        </Modal>
    );
};

export default CreateNewMaterial;
import React, { useEffect, useState } from 'react';
import { getMaterialIssuedPendingOrders } from '../../../../services/orderServiceApi/salesOrderApi';
import { useNavigate } from 'react-router-dom';
import { IMaterialIssued } from '../../../../types/groyyo_plus/materialRecievable';
import { ColumnsType } from 'antd/es/table';
import { sourcingRoute } from '../../../../routes';
import PaginatedTable from '../../../../components/PaginatedTable';
import { MaterialStatusType } from '../../../../constants/enums';
import { useTranslation } from 'react-i18next';

const MaterialIssued = () => {
    const [materialIssuedPendingOrders, setMaterialIssuedPendingOrders] = useState<IMaterialIssued[]>([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { t }:any = useTranslation();

    const fetchMaterialIssuedPendingOrders = async () => {
        setLoading(true);
        try {
            const response = await getMaterialIssuedPendingOrders();
            const responseData = response?.data;

            if (responseData?.status) {
                setMaterialIssuedPendingOrders(responseData?.result);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMaterialIssuedPendingOrders();
    }, []);

    const columns: ColumnsType<IMaterialIssued> = [
        {
            title: t('material.issued.table.so_number'),
            dataIndex: 'soNo',
            key: 'soNo',
            render: (text, record) => (
                <a
                    className="text-blue-400 font-bold"
                    onClick={() => {
                        navigate(`${sourcingRoute}/material-issued/sales-order-item/${record?.id}?materialStatusType=${MaterialStatusType.ISSUED}`);
                    }}
                >
                    {text}
                </a>
            ),
        },
        { title: t('material.issued.table.buyer_name'), dataIndex: ['buyer', 'name'], key: 'buyerName' },
        { title: t('material.issued.table.merchandiser'), dataIndex: 'merchandiserName', key: 'merchandiserName' },
        { title: t('material.issued.table.so_date'), dataIndex: 'soDate', key: 'soDate' },
        { title: t('material.issued.table.expected_date'), dataIndex: 'expectedDate', key: 'expectedDate' },
    ];

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">{t('material.issued.title')}</h1>
            </div>
            <PaginatedTable<IMaterialIssued>
                columns={columns}
                dataSource={materialIssuedPendingOrders}
                rowKey="id"
                loading={loading}
            />
        </div>
    );
};

export default MaterialIssued;
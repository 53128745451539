import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Modal, message } from 'antd';
import { roundNumber } from '../../../../utilities/helper';
import palette from '../../../../constants/pallete';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { MdEdit } from 'react-icons/md';
import { updateBillingDetail } from '../../../../services/orderServiceApi/salesOrderApi';

export interface UpdateBillingDetailPayload {
    subTotal: number;
    discount: number;
    shipmentCharges: number;
    total: number;
    customerNote: string;
    termsAndConditions: string;
    salesOrderId: number;
}

const EditBillingDetail = ({fetchSalesOrderDetail}:{fetchSalesOrderDetail: any}) => {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t }: any = useTranslation();

    const singleSalesOrder = useSelector((state: RootState) => state.singleSalesOrder);

    const [discount, setDiscount] = useState<number>(0);
    const [shipmentCharges, setShipmentCharges] = useState<number>(0);
    const [customerNote, setCustomerNote] = useState<string>('');
    const [termsAndConditions, setTermsAndConditions] = useState<string>('');
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        if (isModalVisible && singleSalesOrder) {
            setDiscount(singleSalesOrder.discount || 0);
            setShipmentCharges(singleSalesOrder.shipmentCharges || 0);
            setCustomerNote(singleSalesOrder.customerNote || '');
            setTermsAndConditions(singleSalesOrder.termsAndCondition || '');

            const initialTotal =
                (singleSalesOrder.subTotal || 0) -
                (singleSalesOrder.subTotal || 0) * ((singleSalesOrder.discount || 0) / 100) +
                (singleSalesOrder.shipmentCharges || 0);

            setTotal(initialTotal);

            form.setFieldsValue({
                discount: singleSalesOrder.discount || 0,
                shipmentCharges: singleSalesOrder.shipmentCharges || 0,
                customerNote: singleSalesOrder.customerNote || '',
                termsAndConditions: singleSalesOrder.termsAndCondition || '',
            });
        }
    }, [isModalVisible, singleSalesOrder, form]);

    const handleFinish = async (values: any) => {
        const discountAmount = Number(singleSalesOrder?.subTotal || 0) * (Number(values.discount || 0) / 100);
        const updatedTotal =
            Number(singleSalesOrder?.subTotal || 0) - discountAmount + Number(values.shipmentCharges || 0);

        const payload: UpdateBillingDetailPayload = {
            subTotal: singleSalesOrder?.subTotal || 0,
            discount: values.discount,
            shipmentCharges: values.shipmentCharges,
            total: updatedTotal,
            customerNote: values.customerNote,
            termsAndConditions: values.termsAndConditions,
            salesOrderId: singleSalesOrder?.id || 0,
        };

        try {
            const response = await updateBillingDetail({ payload });
            const responseData = response?.data;

            if(responseData?.status){
                message.success(responseData?.messag ?? 'Order details updated successfully!');
                setIsModalVisible(false);
                fetchSalesOrderDetail(singleSalesOrder?.id)
            }
        } catch (error) {
            message.error('Failed to update order details. Please try again.');
        }

        setIsModalVisible(false);
    };

    const handleFieldChange = (changedValues: any, allValues: any) => {
        if ('discount' in changedValues) setDiscount(changedValues.discount);
        if ('shipmentCharges' in changedValues) setShipmentCharges(changedValues.shipmentCharges);
        if ('customerNote' in changedValues) setCustomerNote(changedValues.customerNote);
        if ('termsAndConditions' in changedValues) setTermsAndConditions(changedValues.termsAndConditions);

        const discountAmount = Number(singleSalesOrder?.subTotal || 0) * (Number(allValues.discount || 0) / 100);
        const updatedTotal =
            Number(singleSalesOrder?.subTotal || 0) - discountAmount + Number(allValues.shipmentCharges || 0);
        setTotal(updatedTotal);
    };

    const showModal = () => setIsModalVisible(true);
    const handleCancel = () => setIsModalVisible(false);

    return (
        <div>
            <MdEdit className="cursor-pointer text-gray-600 text-2xl" onClick={showModal} />

            <Modal
                title={t('sales_order.updation.billing_details.modal.title')}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={600}
                maskClosable={false}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFinish}
                    onValuesChange={handleFieldChange}
                    initialValues={{
                        discount,
                        shipmentCharges,
                        customerNote,
                        termsAndConditions,
                    }}
                    className="flex flex-col "
                >
                    <div className="flex flex-col p-2 ">
                        <Form.Item
                            label={t('sales_order.updation.billing_details.fields.discount')}
                            name="discount"
                            rules={[
                                { required: true, message: t('sales_order.updation.billing_details.errors.discount_required') },
                            ]}
                        >
                            <Input
                                type="number"
                                placeholder={t('sales_order.updation.billing_details.placeholders.discount')}
                                style={{
                                    border: 'none',
                                    borderBottom: '2px solid #ccc',
                                    textAlign: 'right',
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('sales_order.updation.billing_details.fields.shipment_charges')}
                            name="shipmentCharges"
                            rules={[
                                { required: true, message: t('sales_order.updation.billing_details.errors.shipment_charges_required') },
                            ]}
                        >
                            <Input
                                type="number"
                                placeholder={t('sales_order.updation.billing_details.placeholders.shipment_charges')}
                                style={{
                                    border: 'none',
                                    borderBottom: '2px solid #ccc',
                                    textAlign: 'right',
                                }}
                            />
                        </Form.Item>

                        <div className="flex flex-row justify-between w-full mb-4">
                            <div className="font-bold text-lg">{t('sales_order.updation.billing_details.fields.total')}</div>
                            <div className="flex flex-row gap-2 font-bold">
                                <span>{roundNumber(total)}</span>
                                <span>{singleSalesOrder?.currency?.code}</span>
                            </div>
                        </div>

                        <Form.Item
                            label={t('sales_order.updation.billing_details.fields.customer_note')}
                            name="customerNote"
                            rules={[
                                { required: true, message: t('sales_order.updation.billing_details.errors.customer_note_required') },
                            ]}
                        >
                            <Input.TextArea
                                placeholder={t('sales_order.updation.billing_details.placeholders.customer_note')}
                                rows={3}
                            />
                        </Form.Item>

                        <Form.Item
                            label={t('sales_order.updation.billing_details.fields.terms_and_conditions')}
                            name="termsAndConditions"
                            rules={[
                                { required: true, message: t('sales_order.updation.billing_details.errors.terms_conditions_required') },
                            ]}
                        >
                            <Input.TextArea
                                placeholder={t('sales_order.updation.billing_details.placeholders.terms_and_conditions')}
                                rows={3}
                            />
                        </Form.Item>
                    </div>

                    <Form.Item className="w-full">
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ backgroundColor: palette.secondary, width: '100%' }}
                        >
                            {t('sales_order.updation.billing_details.buttons.update')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default EditBillingDetail;

import React, { useEffect, useState } from 'react';
import { Button, Table, Typography, Space, Modal, Form, Input, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { getBranches, createBranch, bulkUploadBranches } from '../services/orderServiceApi/branchApi';
import { Branch, CreateBranchDto } from '../types/branch';
import { ApiResponse } from '../types/response';
import DashboardLayout from '../layouts/DashboardLayout';
import DownloadTemplateButton from '../components/DownloadTemplateButton';

const Branches: React.FC = () => {
  const [branches, setBranches] = useState<Branch[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<CreateBranchDto>();

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    try {
      const response = await getBranches();
      const responseData: ApiResponse<Branch[]> = response?.data;

      if (responseData?.status && responseData?.statusCode === 200) {
        setBranches(responseData.result);
      }
    } catch (error) {
      console.error("Failed to fetch branches:", error);
    }
  };

  const handleAddBranch = async (values: CreateBranchDto) => {
    setLoading(true);
    try {
      const response = await createBranch(values);
      if (response.status) {
        message.success("Branch added successfully!");
        setIsModalVisible(false);
        fetchBranches();
        form.resetFields();
      } else {
        message.error("Failed to add branch");
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error adding branch");
    } finally {
      setLoading(false);
    }
  };

  const handleBulkUpload = async (file: File) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: CreateBranchDto[] = XLSX.utils.sheet_to_json(worksheet);

      if(jsonData?.length === 0){
        message.error("Empty File!")
      }

      if (!jsonData.every(item => item.name && item.shortHand)) {
        message.error('Invalid file format. Ensure it contains "name" and "shortHand" columns.');
        return;
      }

      const response: any = await bulkUploadBranches(jsonData);

      const responseData = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Branches uploaded successfully!");
        fetchBranches();
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error uploading branches");
      console.error("Error uploading branches:", error);
    }
  };

  const columns = [
    {
      title: 'Branch Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Short Hand',
      dataIndex: 'shortHand',
      key: 'shortHand',
    }
  ];

  return (
      <DashboardLayout>
        <DashboardLayout.Header title="Manage Branches">
        <Space>
          <DownloadTemplateButton templateName="bulk_branches_template.xlsx" buttonText="Download Branch Template" />
          <Button type="primary" onClick={() => setIsModalVisible(true)}>Add Branch</Button>
          <Upload 
            beforeUpload={(file) => {
              handleBulkUpload(file as File);
              return false;
            }}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Bulk Upload</Button>
          </Upload>
        </Space>
      </DashboardLayout.Header>
      <DashboardLayout.Body>
        <Table dataSource={branches} columns={columns} rowKey="id" />

        <Modal
          title="Add New Branch"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleAddBranch}
          >
            <Form.Item
              name="name"
              label="Branch Name"
              rules={[{ required: true, message: 'Please enter the branch name' }]}
            >
              <Input placeholder="Enter branch name" />
            </Form.Item>

            <Form.Item
              name="shortHand"
              label="Short Hand"
              rules={[{ required: true, message: 'Please enter the short hand' }]}
            >
              <Input placeholder="Enter short hand" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Add Branch
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </DashboardLayout.Body>
    </DashboardLayout>
  );
};

export default Branches;
import { ORDER_SERVICE_API } from ".";
import { IDispatchRequest, IInspectionRequest } from "../../types/groyyo_plus/dispatch";
import { orderApi } from "./orderService"

const dispatchUrl = ORDER_SERVICE_API.DISPATCH;

export const getAvailabeForDispatch = () => {
    return orderApi.get(`${dispatchUrl}/available-for-dispatch`)
}

export const getHistoryDispatch = (salesOrderItemId: number) => {
    return orderApi.get(`${dispatchUrl}/dispatch-history?salesOrderItemId=${salesOrderItemId}`)
}

export const addInspection = (payload: IInspectionRequest) => {
    return orderApi.post(`${dispatchUrl}/add-inspection`, payload)
}

export const addDisaptch = (payload: IDispatchRequest) => {
    return orderApi.post(`${dispatchUrl}`, payload)
}
import React, { PropsWithChildren } from 'react';

interface DashboardLayoutProps {
    children: React.ReactNode;
}

const DashboardLayout: React.FC<PropsWithChildren<DashboardLayoutProps>> & {
    Header: React.FC<PropsWithChildren<{ title: string }>>;
    Body: React.FC<PropsWithChildren>;
} = ({ children }) => {
    return (
        <div className='w-full p-[1.5rem] h-auto'>
            <div className='bg-white w-full h-auto rounded-lg'>
                {children}
            </div>
        </div>
    );
};

DashboardLayout.Header = ({ title, children }) => {
    return (
        <div className="border-b border-gray-300 p-4 flex items-center justify-between w-full">
            <h2 className="text-xl font-bold xxs:whitespace-nowrap flex-1">{title}</h2>
            <div className='flex gap-2'>
                {children}    
            </div>
        </div>
    );
};

DashboardLayout.Body = ({ children }) => {
    return (
        <div className="p-4 h-auto ">
            {children}
        </div>
    );
};

export default DashboardLayout;


import { ORDER_SERVICE_API } from ".";
import { TNACreationRequestBody } from "../../types/groyyo_plus/tna";
import { orderApi } from "./orderService";

const tnaUrl = ORDER_SERVICE_API.TNA;
const tnaTemplateUrl = ORDER_SERVICE_API.TNA_TEMPLATE;
const tnaProcessUrl = ORDER_SERVICE_API.TNA_PROCESS;

export const getTna = async({salesOrderItemId}:{salesOrderItemId: number}) => {
    return orderApi.get(`${tnaUrl}/tna-details?salesOrderItemId=${salesOrderItemId}`)
}

export const updateActualDate = async(
    salesOrderItemId: number,
    tnaProcessId: number,
    actualDate: string,
) => {
    return orderApi.post(`${tnaUrl}/update-actual-date?salesOrderItemId=${salesOrderItemId}&tnaProcessId=${tnaProcessId}&actualDate=${actualDate}`)
}

export const createTna = async(payload: {tnaProcessMapping:TNACreationRequestBody[], salesOrderItemId: number}) => {
    return orderApi.post(`${tnaUrl}/create`, payload)
}

export const getTnaTemplates = async () => {
    return orderApi.get(`${tnaTemplateUrl}/all`)
}

export const createTnaTemplates = async (payload: any) => {
    return orderApi.post(`${tnaTemplateUrl}/create`, payload)
}

export const getTnaTemplateDetails = async(tnaTemplateId: number) => {
    return orderApi.get(`${tnaTemplateUrl}/template?id=${tnaTemplateId}`)
}

export const getTnaProcess = async () => {
    return orderApi.get(`${tnaProcessUrl}/all`)
}

export const createTnaProcess = async (payload: any) => {
    return orderApi.post(`${tnaProcessUrl}/create`, payload)
}

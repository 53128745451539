import React, { useEffect, useState } from 'react';
import { getMaterialReceivablePendingOrders } from '../../../../services/orderServiceApi/salesOrderApi';
import { IMaterialReceivablePendingOrder } from '../../../../types/groyyo_plus/materialRecievable';
import PaginatedTable from '../../../../components/PaginatedTable';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { sourcingRoute } from '../../../../routes';
import { MaterialStatusType } from '../../../../constants/enums';
import { useTranslation } from 'react-i18next';

const MaterialReceivable = () => {
    const [materialReceivablePendingOrders, setMaterialReceivablePendingOrders] = useState<IMaterialReceivablePendingOrder[]>([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { t }: any = useTranslation();

    const fetchMaterialReceivablePendingOrders = async () => {
        setLoading(true);
        try {
            const response = await getMaterialReceivablePendingOrders();
            const responseData = response?.data;

            if (responseData?.status) {
                setMaterialReceivablePendingOrders(responseData?.result);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMaterialReceivablePendingOrders();
    }, []);

    const columns: ColumnsType<IMaterialReceivablePendingOrder> = [
        {
            title: t('material.receivable.list.columns.so_number'),
            dataIndex: 'soNo',
            key: 'soNo',
            render: (text, record) => (
                <a
                    className="text-blue-400 font-bold"
                    onClick={() => {
                        navigate(
                            `${sourcingRoute}/material-receive/sales-order-item/${record?.id}?materialStatusType=${MaterialStatusType.RECIEVED}`
                        );
                    }}
                >
                    {text}
                </a>
            ),
        },
        {
            title: t('material.receivable.list.columns.buyer_name'),
            dataIndex: ['buyer', 'name'],
            key: 'buyerName',
        },
        {
            title: t('material.receivable.list.columns.merchandiser'),
            dataIndex: 'merchandiserName',
            key: 'merchandiserName',
        },
        {
            title: t('material.receivable.list.columns.so_date'),
            dataIndex: 'soDate',
            key: 'soDate',
        },
        {
            title: t('material.receivable.list.columns.expected_date'),
            dataIndex: 'expectedDate',
            key: 'expectedDate',
        },
    ];

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">{t('material.receivable.list.title')}</h1>
            </div>
            <PaginatedTable<IMaterialReceivablePendingOrder>
                columns={columns}
                dataSource={materialReceivablePendingOrders}
                rowKey="id"
                loading={loading}
            />
        </div>
    );
};

export default MaterialReceivable;
import React, { useEffect, useState } from 'react';
import { Spin, Table, message } from 'antd';
import { getUsers } from '../../../services/userServiceApi/authApi';
import { ICurrentFactoryUser } from '../../../types/groyyo_plus/user';
import CreateUserControl from '../../../components/CreateUserControl';
import { useTranslation } from 'react-i18next';

const CurrentFactoryUser = () => {
  const { t }: any = useTranslation();
  const [users, setUsers] = useState<ICurrentFactoryUser[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchUsers = async () => {
    try {
      const response = await getUsers();
      const responseData = response?.data;

      if (responseData?.status && Array.isArray(responseData.result)) {
        setUsers(responseData.result);
      } else {
        message.error(t('currentFactoryUser.unexpected_response'));
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error(t('currentFactoryUser.fetch_error'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const columns = [
    {
      title: t('currentFactoryUser.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('currentFactoryUser.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('currentFactoryUser.mobile_number'),
      dataIndex: 'mobileNo',
      key: 'mobileNo',
    },
    {
      title: t('currentFactoryUser.role'),
      dataIndex: ['role', 'name'],
      key: 'role',
    },
  ];

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <h2 className="text-lg font-bold mb-4">{t('currentFactoryUser.factory_members')}</h2>
        <CreateUserControl onUserCreated={fetchUsers} />
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-32">
          <Spin size="large" />
        </div>
      ) : (
        <Table
          dataSource={users}
          columns={columns}
          rowKey={(record) => record.id || record.email}
          pagination={{ pageSize: 5 }}
          bordered
        />
      )}
    </div>
  );
};

export default CurrentFactoryUser;
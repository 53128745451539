import { CreateProductTypeDto } from "../../types/productType";
import { orderApi } from "./orderService"; 
import { ORDER_SERVICE_API } from ".";
;

const productTypeUrl = ORDER_SERVICE_API.PRODUCT_TYPE;

export const getProductTypes = async () => {
    return orderApi.get(`${productTypeUrl}`);
}

export const createProductType = async (payload: CreateProductTypeDto) => {
    return orderApi.post(`${productTypeUrl}`, payload);
}

export const bulkUploadProductTypes = async (productTypes: CreateProductTypeDto[]) => {
    return orderApi.post(`${productTypeUrl}/bulk`, productTypes);
};
import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Skeleton, Select, message, Form } from 'antd';
import palette from '../constants/pallete';
import { useTranslation } from 'react-i18next';

interface Item {
    id: number;
    name: string;
    percentage?: number;
}

interface Field {
    name: string;
    label: string;
    required?: boolean;
    type?: 'text' | 'number';
    endIcon?: any;
}

interface CustomDropdownWithAddProps {
    label: string;
    placeholder: string;
    fetchItems: () => any;
    addItem: (data: Record<string, string>) => Promise<void>;
    onSelect: (item: Item) => void;
    modalFields: Field[];
    formItemName: string;
    required?: boolean;
    form: any;
    value: any;
    validateStatus?: 'success' | 'warning' | 'error' | 'validating' | undefined;
    help?: string;
    disabled?: boolean
}
const CustomDropdownWithAdd: React.FC<CustomDropdownWithAddProps> = ({
    label,
    placeholder,
    fetchItems,
    addItem,
    onSelect,
    modalFields,
    formItemName,
    required = false,
    form,
    value,
    validateStatus,
    help,
    disabled = false
}) => {
    const {t}: any = useTranslation();
    const [items, setItems] = useState<Item[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState<Record<string, string>>({});
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [loading, setLoading] = useState(false);

    const fetchAndSetItems = async () => {
        setLoading(true);
        try {
            const response: any = await fetchItems();
            const responseData = response?.data;
            if (responseData?.status && responseData?.statusCode === 200) {
                const transformedData = responseData?.result?.map((i: any) => ({
                    id: i?.id,
                    name: i?.name,
                    percentage: i?.percentage,
                }));
                setItems(transformedData);
            } else {
                setItems([]);
            }
        } catch (error: any) {
            console.error(t('components.custom_dropdown_with_add.errors.fetch_failed'), error);
            setItems([]);
        } finally {
            setLoading(false);
        }
    };

    const handleAddItem = async () => {
        const newErrors: Record<string, string> = {};
        modalFields.forEach((field) => {
            if (field.required && !formData[field.name]) {
                newErrors[field.name] = t('components.custom_dropdown_with_add.errors.required', {
                  label: field.label,
                });
              }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            await addItem(formData);
            setFormData({});
            setErrors({});
            setShowModal(false);
            fetchAndSetItems();
        } catch (error: any) {
            message.error(
                error?.response?.data?.message ??
                  t('components.custom_dropdown_with_add.errors.add_failed', { label })
              );
              console.error('Error adding item:', error);
        }
    };

    const handleSelect = (selectedValue: number) => {
        const selectedItem = items.find((item) => item.id === selectedValue);
        if (selectedItem) {
            onSelect(selectedItem);
            form.setFieldsValue({ [formItemName]: selectedValue });
        }
    };

    useEffect(() => {
        fetchAndSetItems();
    }, []);

    return (
        <Form.Item
            label={label}
            name={formItemName}
            rules={[{ required, message: t('components.custom_dropdown_with_add.errors.required', { label }) }]}
            validateStatus={validateStatus}
            help={help}
        >
            <Select
                showSearch
                placeholder={placeholder}
                onSelect={handleSelect}
                value={value}
                notFoundContent={loading ? <Skeleton active /> : <div>No data available</div>}
                filterOption={(input, option) =>
                    (option?.label as string)?.toLowerCase().includes(input.toLowerCase())
                }
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <div className="border-t p-2 text-center">
                            <Button
                                type="link"
                                style={{ color: palette.secondary, fontWeight: 'bold' }}
                                onClick={() => setShowModal(true)}
                            >
                                {t('components.custom_dropdown_with_add.add_new', { label })}
                            </Button>
                        </div>
                    </>
                )}
                style={{ width: '100%' }}
                className="w-full border-gray-300 rounded-md !h-10"
                disabled={disabled}
            >
                {items.map((item) => (
                    <Select.Option key={item.id} value={item.id} label={item.name}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>

            <Modal
                title={t('components.custom_dropdown_with_add.modal_title', { label })}
                open={showModal}
                onCancel={() => setShowModal(false)}
                onOk={handleAddItem}
            >
                {modalFields.map((field) => (
                    <div key={field.name} className="mb-2">
                        <label className="block text-gray-700">{field.label}</label>
                        <Input
                            type={field.type}
                            placeholder={t('components.custom_dropdown_with_add.modal_placeholder', {
                                label: field.label,
                              })}
                            value={formData[field.name] || ''}
                            onChange={(e) =>
                                setFormData((prev) => ({
                                    ...prev,
                                    [field.name]: e.target.value,
                                }))
                            }
                            required={field.required}
                            suffix={field?.endIcon ?? ''}
                        />
                        {errors[field.name] && (
                            <p className="text-red-500 text-xs mt-1">{errors[field.name]}</p>
                        )}
                    </div>
                ))}
            </Modal>
        </Form.Item>
    );
};

export default CustomDropdownWithAdd;
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

interface RoleProtectionProps {
  permissionKey: string;
  permissionCategory: "VIEW" | "CREATE" | "EDIT" | "DELETE";
permissionMap: Record<string, string[]> | null; 
  children?: React.ReactNode;
}

const RoleProtection: React.FC<RoleProtectionProps> = ({
  permissionKey,
  permissionCategory,
  permissionMap,
  children,
}) => {
  const location = useLocation();
 
  if (!permissionMap || Object.keys(permissionMap).length === 0) {    
    return (
      <Navigate
        to="/sourcing/dashboard"
        state={{ from: location }}
        replace
      />
    );
  }
  
  const hasPermission =
    permissionMap?.[permissionCategory]?.includes(permissionKey) || false;

  if (!hasPermission) {
    return (
      <Navigate
        to="/sourcing/dashboard"
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
};

export default RoleProtection;
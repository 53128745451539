import React from 'react';
import { useSelector } from 'react-redux';
import ItemDetails from '../pages/groyyo_plus/Order/create/itemDetail/ItemDetails';
import BillingDetails from '../pages/groyyo_plus/Order/create/billingDetail/BillingDetails';
import OrderDetails from '../pages/groyyo_plus/Order/create/OrderDeatils';
import CustomTab from './CustomTab';
import { CreateSalesOrderTabEnum } from '../constants/enums';
import { RootState } from '../redux/store';
import { setActiveSalesOrderTab } from '../redux/order/createSalesOrderSlice';
import { useTranslation } from 'react-i18next';

const MultiStepForm = () => {
    const activeTab = useSelector((state: RootState) => state.createSalesOrder.activeSalesOrderTab);
    const {t}: any = useTranslation();
    const tabs = [
        {
            label: t("sales_order.creation.steps.order_detail"),
            content: <OrderDetails />,
            tabEnum: CreateSalesOrderTabEnum.ORDER_DETAILS,
        },
        {
            label: t("sales_order.creation.steps.item_detail"),
            content: <ItemDetails />,
            tabEnum: CreateSalesOrderTabEnum.ITEM_DETAILS,
        },
        {
            label: t("sales_order.creation.steps.billing_detail"),
            content: <BillingDetails />,
            tabEnum: CreateSalesOrderTabEnum.BILLING_DETAILS,
        },
    ];

    return (
        <div className="w-full p-4">
            <CustomTab
                tabs={tabs}
                activeTab={activeTab}
                setActiveTabAction={(tab) => setActiveSalesOrderTab(tab)}
            />
        </div>
    );
};

export default MultiStepForm;
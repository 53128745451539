import { ORDER_SERVICE_API } from ".";
import { IMaterialIssuedRequestBody, IMaterialReceivableRequestBody } from "../../types/groyyo_plus/materialRecievable";
import { orderApi } from "./orderService"

const materialReceivableUrl = ORDER_SERVICE_API.MATERIAL_RECEIVABLE;
const materialIssuedUrl = ORDER_SERVICE_API.MATERIAL_ISSUED;

export const createMaterialRecievable = (payload: IMaterialReceivableRequestBody) => {
    return orderApi.post(`${materialReceivableUrl}`, payload)
}

export const createMaterialIssued = (payload: IMaterialIssuedRequestBody) => {
    return orderApi.post(`${materialIssuedUrl}`, payload)
}
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppContent from "./routes/AppContent";
import './internalization/i18n'; 
import { ConfigProvider } from "antd";
import i18n from "./internalization/i18n";
import { getCurrentLocale } from "./utilities/language";


const App: React.FC = () => {
  return (
    <Router>
      <ConfigProvider locale={getCurrentLocale(i18n.language)}>
        <AppContent />
      </ConfigProvider>
    </Router>
  );
};

export default App;
import { useEffect, useState } from "react";
import { message } from "antd";
import { generateFactoryToken } from "../services/userServiceApi/factoriesApi";
import { jwtDecode } from "jwt-decode";

const useFactorySelection = ({setIsOpen}:{  setIsOpen?: (val: boolean) => void}) => {
  const [selectedFactoryId, setSelectedFactoryId] = useState<number | null>(null);
  const [confirmFactoryLoading, setConfirmFactoryLoading] = useState<boolean>(false);

  const authToken = localStorage.getItem("authToken") as string;
  const decodedToken: any = (authToken === null) ? "" : jwtDecode(authToken);

  useEffect(() => {
    if(decodedToken){
        setSelectedFactoryId(decodedToken?.factory[0]?.id)
    }
  }, []);

  const handleFactorySelect = async (factoryId: number) => {
    setSelectedFactoryId(factoryId);
    setConfirmFactoryLoading(true);

    try {
      const response = await generateFactoryToken({ factoryId });
      const responseData = response?.data;

      if (responseData?.status) {
        localStorage.setItem("authToken", responseData?.result?.token);
        if(setIsOpen){
            setIsOpen(false)
        }
        window.location.reload();
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error generating factory token");
      console.error("Error generating factory token:", error);
    } finally {
      setConfirmFactoryLoading(false);
    }
  };

  return {
    selectedFactoryId,
    confirmFactoryLoading,
    handleFactorySelect,
  };
};

export default useFactorySelection;
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import createSalesOrderSlice from "./order/createSalesOrderSlice";
import singleSalesOrderSlice from "./order/singleSalesOrderSlice";
import bomPendingOrdersSlice from "./bom/bomPendingOrdersSlice";
import createBomSlice from "./bom/createBomSlice";
import dispatchSlice from "./dispatch/dispatchSlice";
import tnaTemplateSlice from "./tna/tnaTemplateSlice";
import userPermissionSlice from "./User/PermissionSlice";

export const store = configureStore({
  reducer: {
    createSalesOrder: createSalesOrderSlice,
    singleSalesOrder: singleSalesOrderSlice,
    bomPendingOrders: bomPendingOrdersSlice,
    createBom: createBomSlice,
    dispatch: dispatchSlice,
    tnaTemplate: tnaTemplateSlice,
    userPermissions: userPermissionSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

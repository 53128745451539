import React from 'react';

interface CustomFloatButtonProps {
  icon?: React.ReactNode;
  onClick?: () => void;
  backgroundColor?: string;
  color?: string;
  bottom?: number;
  right?: number;
  size?: number;
  shadow?: boolean;
}

const CustomFloatButton: React.FC<CustomFloatButtonProps> = ({
  icon,
  onClick,
  backgroundColor = '#CC6A4C',
  color = '#FFFFFF',
  bottom = 24,
  right = 24,
  size = 56,
  shadow = true,
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        position: 'fixed',
        bottom: `${bottom}px`,
        right: `${right}px`,
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor,
        color,
        border: 'none',
        borderRadius: '50%',
        boxShadow: shadow ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 1000,
      }}
      aria-label="Floating Button"
    >
      {icon}
    </button>
  );
};

export default CustomFloatButton;
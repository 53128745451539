import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Alert, message } from "antd";
import {
  generateOtp,
  getUserDetail,
  login,
} from "../services/userServiceApi/authApi";
import { v4 as uuidv4 } from "uuid";
import { restructurePermissions, setToken } from "../utilities/helper";
import { sourcingRoute } from "../routes";
import { setUserPermissions } from "../redux/User/PermissionSlice";
import { useDispatch } from "react-redux";
import OTPInput from "../components/OTPInput";
import palette from "../constants/pallete";

const Login = ({ headerHeight }: { headerHeight: string }) => {
  const [form] = Form.useForm();
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("".padEnd(6, ""));
  const [deviceId, setDeviceId] = useState<string>("");
  const [fcmToken, setFcmToken] = useState<string>("mock-fcm-token");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setDeviceId(uuidv4());
  }, []);

  const fetchUserDetails = useCallback(async () => {
    try {
      const response = await getUserDetail();
      if (response?.data?.result) {
        const permissionSet = restructurePermissions(response?.data?.result?.userVerticals);
        await dispatch(setUserPermissions(permissionSet));
      }
    } catch (error) {
      console.error("Failed to fetch user details.");
    }
  }, [dispatch]);

  const handleSendOtp = async (values: { phoneNumber: string }) => {
    setLoading(true);
    try {
      await generateOtp(values.phoneNumber, "GROYYO_PLUS");
      setIsOtpSent(true);
      message.success("OTP sent successfully.");
    } catch (error) {
      message.error("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (values: { phoneNumber: string }) => {
    if (otp.length !== 6) {
      message.error("Please enter a valid 6-digit OTP.");
      return;
    }
    setLoading(true);
    try {
      const response = await login({
        mobileNo: values.phoneNumber,
        otpCode: otp,
        fcmToken: fcmToken,
        deviceId: deviceId,
        platform: "GROYYO_PLUS",
      });

      setToken(response?.data?.result?.token);
      localStorage.setItem("userDetails", JSON.stringify(response?.data?.result));
      await fetchUserDetails();
      navigate(`${sourcingRoute}/dashboard`);
      window.location.reload()
    } catch (error) {
      message.error("Invalid OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex items-center justify-center bg-gray-100"
      style={{ marginTop: headerHeight }}
    >
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-4">Login</h2>

        <Form
          form={form}
          layout="vertical"
          onFinish={!isOtpSent ? handleSendOtp : handleLogin}
        >
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: true, message: "Phone number is required" },
              {
                pattern: /^[6-9]\d{9}$/,
                message: "Please enter a valid 10-digit phone number",
              },
            ]}
            validateTrigger="onSubmit" 
          >
            <Input
              placeholder="Enter phone number"
              disabled={isOtpSent}
            />
          </Form.Item>

          {isOtpSent && (
            <Form.Item label="Enter OTP">
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputStyle="focus:ring focus:ring-indigo-200"
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              loading={loading}
              style={{ backgroundColor: palette.secondary }}
            >
              {!isOtpSent ? "Send OTP" : "Login"}
            </Button>
          </Form.Item>
        </Form>

        {!isOtpSent && (
          <p className="text-sm text-gray-500 text-center mt-4">
            Enter your phone number to receive an OTP.
          </p>
        )}
      </div>
    </div>
  );
};

export default Login;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  userPermissions: any;
}

const initialState: InitialState = {
  userPermissions: [],
};

const userPermissionSlice = createSlice({
  name: "userPermissions",
  initialState,
  reducers: {
    setUserPermissions: (state, action: PayloadAction<any>) => {
      state.userPermissions = action.payload;
    },
  },
});

export const { setUserPermissions } = userPermissionSlice.actions;

export default userPermissionSlice.reducer;

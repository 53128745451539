import axios from "axios";

const USER_API_BASE_URL = process.env.REACT_APP_USER_API_BASE_URL;

export const userApi = axios.create({
  baseURL: USER_API_BASE_URL,
});

userApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

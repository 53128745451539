import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBomItem } from "../../../../services/orderServiceApi/bomApi";
import { IReceivableListItem } from "../../../../types/groyyo_plus/materialRecievable";
import { Skeleton } from "antd";
import RecievableListItem from "./RecievableListItem";
import { IoChevronBackOutline } from "react-icons/io5";
import { sourcingRoute } from "../../../../routes";
import { MaterialStatusType } from "../../../../constants/enums";
import { useTranslation } from "react-i18next";

const RecievableList: React.FC = () => {
  const { bomId, salesOrderItemId, salesOrderId } = useParams<{ bomId: string, salesOrderItemId: string, salesOrderId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [recievableList, setRecievableList] = useState<IReceivableListItem[]>([]);
  const navigate = useNavigate();
  const { t }: any = useTranslation(); 

  const fetchBomItem = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await getBomItem({ bomItemId: +bomId! });
      const responseData = response?.data;

      if (responseData?.status) {
        setRecievableList(responseData?.result);
      }
    } catch (error) {
      console.error(t("material.receivable.recievable_list.error_fetching_list"), error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bomId) {
      fetchBomItem();
    }
  }, [bomId]);


  return (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-bold flex flex-row gap-2 items-center">
        <span onClick={
          ()  => navigate(`${sourcingRoute}/material-receive/sales-order-item/${+salesOrderId!}?materialStatusType=${MaterialStatusType.RECIEVED}`)
        } className="cursor-pointer"><IoChevronBackOutline style={{fontWeight: "bold"}} /></span> 
        <p>{t("material.receivable.recievable_list.title")}</p>
      </div>
      {loading ? (
        Array.from({ length: 2 }).map((_, index) => (
          <Skeleton.Input
            key={index}
            active
            style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "50vh" }}
          />
        ))
      ) : (
        <>
          {recievableList?.map((item) => {
            return (
              <RecievableListItem
                key={item?.id}
                recievableListItem={item}
                salesOrderItemId={+salesOrderItemId!}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default RecievableList;
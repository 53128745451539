import React, { useEffect, useState } from 'react';
import { getWipProductionItems } from '../../../../services/orderServiceApi/salesOrderItemApi';
import { IProductionWIP } from '../../../../types/groyyo_plus/wip';
import WIPCard from './WIPCard';
import palette from '../../../../constants/pallete';
import ExcelJS from 'exceljs';
import moment from 'moment';
import { getDepartments } from '../../../../services/orderServiceApi/departmentsApi';
import { message } from 'antd';
import NoDataAvailable from '../../../../components/NoDataAvailable';
import { useTranslation } from 'react-i18next';

const WIPPage = () => {
    const { t }: any = useTranslation();
    const [productionWIPs, setProductionWIPs] = useState<IProductionWIP[]>([]);
    const [selectedCards, setSelectedCards] = useState<Set<number>>(new Set());
    const [departmentMap, setDepartmentMap] = React.useState<any>({});

    const fetchDepartments = async () => {
        try {
            const response = await getDepartments();
            const responseData = response?.data;

            if (responseData?.status) {
                setDepartmentMap(responseData?.result);
            }
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        fetchDepartments();
    }, []);

    const fetchProductionWIPs = async () => {
        try {
            const response = await getWipProductionItems();
            const responseData = response?.data;

            if (responseData?.status) {
                setProductionWIPs(responseData?.result);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchProductionWIPs();
    }, []);

    const handleCheckboxChange = (id: number) => {
        setSelectedCards((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(id)) {
                newSelected.delete(id);
            } else {
                newSelected.add(id);
            }
            return newSelected;
        });
    };


    const downloadSelected = async () => {
        const selectedIds = Array.from(selectedCards);

        if (selectedIds.length === 0) {
            message.warning(t('production.wip.no_cards_selected'));
            return;
        }

        const excelJSON: any[] = [];

        selectedIds.forEach((id) => {
            const wip = productionWIPs.find((item) => item.id === id);
            if (!wip) return;

            let fabricInHouseDate: any = null;

            wip.materialReceivable?.forEach((item: any) => {
                if (item.material?.type === 'Fabric') {
                    if (fabricInHouseDate === null) {
                        fabricInHouseDate = item.updatedAt;
                    } else if (moment(fabricInHouseDate).isBefore(item.updatedAt)) {
                        fabricInHouseDate = item.updatedAt;
                    }
                }
            });

            const productionData: any = {};

            wip?.orderFlows?.forEach((item: any) => {
                item.orderFlowQualityCheck?.forEach((check: any) => {
                    const department = departmentMap[check.departmentId]?.name || `Dept ${check.departmentId}`;

                    if (!productionData[department?.departmentType]) {
                        productionData[department?.departmentType] = {
                            maxDate: check?.dateOfCheck,
                            qty: check?.passedQuantity,
                        };
                    } else {
                        productionData[department?.departmentType].qty +=
                            check?.passedQuantity;
                        if (
                            moment(
                                productionData[department?.departmentType].maxDate,
                                'YYYY-MM-DD',
                            ).isBefore(
                                moment(check?.dateOfCheck, 'YYYY-MM-DD'),
                            )
                        ) {
                            productionData[department?.departmentType].maxDate =
                                check?.dateOfCheck;
                        }
                    }
                });
            });


            const dispatchData = {
                maxDate: null,
                qty: 0,
            };

            wip.dispatches?.forEach((dispatch) => {
                dispatch.dispatches?.forEach((dispatchDetail: any) => {
                    if (!dispatchData.qty) {
                        dispatchData.maxDate = dispatchDetail.dispatchedDate;
                    } else if (
                        moment(dispatchData.maxDate, 'YYYY-MM-DD').isBefore(
                            moment(dispatchDetail.dispatchedDate, 'YYYY-MM-DD')
                        )
                    ) {
                        dispatchData.maxDate = dispatchDetail.dispatchedDate;
                    }
                    dispatchData.qty += dispatchDetail.dispatchedQuantity;
                });
            });

            excelJSON.push({
                [t('production.wip.excel_headers.buyer')]: wip?.salesOrder?.buyer?.name,
                [t('production.wip.excel_headers.so_number')]: wip?.salesOrder?.soNo,
                [t('production.wip.excel_headers.style_number')]: wip?.item?.name,
                [t('production.wip.excel_headers.so_date')]: moment(wip?.salesOrder?.soDate).format('DD-MM-YYYY'),
                [t('production.wip.excel_headers.order_quantity')]: wip?.quantity,
                [t('production.wip.excel_headers.expected_delivery_date')]: moment(wip?.salesOrder?.expectedDate).format('DD-MM-YYYY'),
                [t('production.wip.excel_headers.fabric_in_house_date')]: fabricInHouseDate
                    ? moment(fabricInHouseDate).format('DD-MM-YYYY')
                    : '-',
                [t('production.wip.excel_headers.cutting_date')]: productionData.CUTTING?.maxDate
                    ? moment(productionData.CUTTING?.maxDate).format('DD-MM-YYYY')
                    : '-',
                [t('production.wip.excel_headers.cutting_quantity')]: productionData.CUTTING?.qty || '-',
                [t('production.wip.excel_headers.sewing_date')]: productionData.SEWING?.maxDate
                    ? moment(productionData.SEWING?.maxDate).format('DD-MM-YYYY')
                    : '-',
                [t('production.wip.excel_headers.sewing_quantity')]: productionData.SEWING?.qty || '-',
                [t('production.wip.excel_headers.finishing_date')]: productionData.FINISHING?.maxDate
                    ? moment(productionData.FINISHING?.maxDate).format('DD-MM-YYYY')
                    : '-',
                [t('production.wip.excel_headers.finishing_quantity')]: productionData.FINISHING?.qty || '-',
                [t('production.wip.excel_headers.dispatch_date')]: dispatchData.maxDate
                    ? moment(dispatchData.maxDate).format('DD-MM-YYYY')
                    : '-',
                [t('production.wip.excel_headers.dispatch_quantity')]: dispatchData.qty || '-',
            });
        });

        if (excelJSON.length === 0) {
            message.warning(t('production.wip.no_data_for_selected_cards'));
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(t('production.wip.excel_sheet_name'));

        const headers = Object.keys(excelJSON[0]);
        worksheet.addRow(headers);

        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CC6A4C' },
            };
            cell.font = {
                bold: true,
                color: { argb: 'FFFFFF' },
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });

        excelJSON.forEach((data) => {
            worksheet.addRow(Object.values(data));
        });

        worksheet.columns.forEach((column, index) => {
            if (!column) return;
            column.width = Math.max(
                headers[index]?.length + 5,
                ...(
                    worksheet.getColumn(index + 1)?.values || []
                )
                    .filter((val): val is string | number => val !== undefined && val !== null)
                    .map((val) => val.toString().length)
            );
        });

        worksheet.getRow(1).eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CC6A4C' },
            };
            cell.font = {
                bold: true,
                color: { argb: 'FFFFFF' },
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `WIP-${Date.now()}.xlsx`;
        a.click();

        message.success('File downloaded successfully!');
        setSelectedCards(new Set());
    };

    return (
        <div className=''>
            <div className="flex justify-between items-center mb-4">
                <div className="text-2xl font-bold flex flex-row gap-2 items-center justify-between w-full">
                <p>{t('production.wip.title')}</p>
                    {selectedCards.size > 0 && (
                        <button
                            className="text-sm font-medium text-red-500 underline ml-4"
                            onClick={() => setSelectedCards(new Set())}
                        >
                            {t('production.wip.clear_all')}
                        </button>
                    )}
                </div>
            </div>
            <div className="flex flex-col gap-6 ">
                {
                    productionWIPs?.length > 0 ? (
                        productionWIPs?.map((wip) => (
                            <WIPCard
                                key={wip.id}
                                wip={wip}
                                isSelected={selectedCards.has(wip.id)}
                                onCheckboxChange={() => handleCheckboxChange(wip.id)}
                                departmentMap={departmentMap}
                            />
                        ))
                    ) : (
                        <NoDataAvailable text={t('production.wip.no_data')} />
                    )
                }

                {selectedCards.size > 0 && (
                    <div
                        className="fixed bottom-0 left-0 w-full text-white text-center py-4 font-semibold cursor-pointer"
                        style={{ backgroundColor: palette.secondary }}
                        onClick={downloadSelected}
                    >
                        {t('production.wip.download_selected')}
                    </div>
                )}
            </div>
        </div>
    );
};

export default WIPPage;
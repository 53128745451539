import React, { useState } from "react";
import { Button, Modal, message } from "antd";
import palette from "../constants/pallete";
import CreateUser from "../pages/CreateUser";
import { useTranslation } from "react-i18next";

interface CreateUserControlProps {
  onUserCreated: () => void;
}

const CreateUserControl: React.FC<CreateUserControlProps> = ({ onUserCreated }) => {
  const { t }: any = useTranslation();
  const [isModalVisible, setModalVisible] = useState(false);

  const handleCreateUser = async () => {
    try {
      onUserCreated();
      message.success(t("createUserControl.user_created_success")); 
      setModalVisible(false);
    } catch (error) {
      console.error(error);
      message.error(t("createUserControl.user_creation_failed")); 
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => setModalVisible(true)}
        style={{ backgroundColor: palette.secondary }}
      >
        {t("createUserControl.create_user")} 
      </Button>

      <Modal
        title={t("createUserControl.create_user")} 
        open={isModalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <CreateUser onCreate={handleCreateUser} />
      </Modal>
    </>
  );
};

export default CreateUserControl;
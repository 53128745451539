import React, { useState, useEffect } from "react";
import { Select, Skeleton, Form } from "antd";
import { useTranslation } from "react-i18next";

interface Item {
  id: number;
  name: string;
  code?: string;
}

interface CustomDropdownProps {
  label: string;
  placeholder: string;
  fetchItems: () => Promise<any>;
  onSelect: (item: Item) => void;
  name: string;
  required?: boolean;
  value: any;
  validateStatus?: "error" | "success" | "warning" | "validating" | undefined;
  help?: string;
  disabled?: boolean;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  label,
  placeholder,
  fetchItems,
  onSelect,
  name,
  required = false,
  value,
  validateStatus,
  help,
  disabled = false,
}) => {
  const { t }: any = useTranslation();
  const [items, setItems] = useState<Item[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const fetchAndSetItems = async () => {
    setLoading(true);
    try {
      const response = await fetchItems();
      const responseData = response?.data;
      if (responseData?.status && responseData?.statusCode === 200) {
        const transformedData = responseData.result.map((i: any) => ({
          id: i.id,
          name: i.name,
          code: i?.code,
        }));
        setItems(transformedData);
      } else {
        setItems([]);
      }
    } catch (error) {
      console.error(t("components.custom_dropdown.errors.fetch_failed"), error);
      setItems([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndSetItems();
  }, []);

  const handleSelect = (value: number) => {
    const selectedItem = items.find((item) => item.id === value);
    if (selectedItem) {
      onSelect(selectedItem);
    }
  };

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required, message: t("components.custom_dropdown.errors.required", { label }) }]}
      className="w-full "
      validateStatus={validateStatus}
      help={help}
    >
      <Select
       className="!h-10"
        showSearch
        placeholder={placeholder}
        loading={loading}
        value={value}
        onSearch={setSearchValue}
        onSelect={handleSelect}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={items.map((item) => ({
          label: item.name,
          value: item.id,
          code: item?.code,
        }))}
        style={{ width: "100%" }}
        disabled={disabled}
      >
        {loading && (
          <div className="p-4">
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </div>
        )}
      </Select>
    </Form.Item>
  );
};

export default CustomDropdown;

import React, { useEffect, useState } from "react";
import { getTna, updateActualDate } from "../../../services/orderServiceApi/tnaApi";
import { useNavigate, useParams } from "react-router-dom";
import { Timeline, DatePicker, Button, message } from "antd";
import moment from "moment";
import { EditOutlined, CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { IoChevronBackOutline } from "react-icons/io5";
import palette from "../../../constants/pallete";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

const TNADetails = () => {
  const { t }: any = useTranslation();
  const { salesOrderItemId } = useParams();
  const navigate = useNavigate();
  const [tnaDetails, setTnaDetails] = useState<any>(null);
  const [editingProcessId, setEditingProcessId] = useState<number | null>(null);
  const [actualDate, setActualDate] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchTna = async () => {
    if (!salesOrderItemId) {
      navigate("/tna");
      return;
    }

    try {
      const response = await getTna({ salesOrderItemId: +salesOrderItemId! });
      const responseData = response?.data;

      if (responseData?.result) {
        setTnaDetails(responseData.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (processId: number) => {
    if (!actualDate) {
      message.error(t("tnaPage.tnaDetails.selectDateError"));
      return;
    }

    const date = actualDate ? moment(actualDate).format("DD/MM/YYYY") : "";

    try {
      const response = await updateActualDate(+salesOrderItemId!, processId, date);
      const responseData = response?.data;

      if (responseData?.status) {
        message.success(t("tnaPage.tnaDetails.updateSuccess"));

        setTnaDetails((prevDetails: any) => {
          const updatedProcesses = prevDetails.tnaProcessMapping.map((process: any) =>
            process.id === processId ? { ...process, actualDate } : process
          );
          return { ...prevDetails, tnaProcessMapping: updatedProcesses };
        });

        setEditingProcessId(null);
        setActualDate(null);
      } else {
        message.error(responseData?.message || t("tnaPage.tnaDetails.updateFailed"));
      }
    } catch (error) {
      console.error("Error updating actual date:", error);
      message.error(t("tnaPage.tnaDetails.updateError"));
    }
  };

  useEffect(() => {
    fetchTna();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">{t("tnaPage.tnaDetails.loading")}</div>
    );
  }

  if (!tnaDetails) {
    return (
      <div className="flex justify-center items-center h-96">{t("tnaPage.tnaDetails.noDetails")}</div>
    );
  }

  const { tnaProcessMapping } = tnaDetails;

  const handleActualDateChange = (date: Dayjs | null) => {
    const formattedDate = date ? date.format("DD/MM/YYYY") : "";
    setActualDate(formattedDate);
  };

  return (
    <>
      <div className="text-2xl font-bold flex flex-row gap-2 items-center w-full">
        <span onClick={() => navigate(`/tna`)} className="cursor-pointer">
          <IoChevronBackOutline style={{ fontWeight: "bold" }} />
        </span>
        <p>{t("tnaPage.tnaDetails.title")}</p>
      </div>
      <div className="max-w-screen-lg mx-auto px-4 py-8 overflow-y-scroll h-[90vh]">
        <Timeline mode="alternate" className="relative">
          {tnaProcessMapping.map((process: any, index: number) => {
            const isEditing = editingProcessId === process.id;
            const hasActualDate = !!process.actualDate;

            return (
              <Timeline.Item
                key={process.id}
                color={hasActualDate ? "green" : "gray"}
                dot={
                  hasActualDate ? (
                    <CheckCircleOutlined className="text-green-500 text-xl" />
                  ) : (
                    <ClockCircleOutlined className="text-gray-400 text-xl" />
                  )
                }
              >
                <div
                  className={`p-4 border rounded-lg shadow-md w-[90%] md:w-[70%] ${
                    index % 2 === 0 ? "text-left ml-auto" : "text-left mr-auto"
                  } ${hasActualDate ? "border-green-500" : "border-gray-400"}`}
                >
                  <h3 className="font-bold text-lg mb-2">{process.tnaProcess.name}</h3>
                  <p className="text-sm mb-1">
                    <span className="font-semibold">{t("tnaPage.tnaDetails.plannedDate")}:</span>{" "}
                    {moment(process.plannedDate).format("DD-MM-YYYY")}
                  </p>
                  {hasActualDate ? (
                    <p className="text-sm mb-1">
                      <span className="font-semibold">{t("tnaPage.tnaDetails.actualDate")}:</span>{" "}
                      {moment(process.actualDate).format("DD-MM-YYYY")}
                    </p>
                  ) : isEditing ? (
                    <div>
                      <DatePicker
                        className="w-full"
                        format="DD/MM/YYYY"
                        placeholder={t("tnaPage.tnaDetails.selectActualDate")}
                        onChange={handleActualDateChange}
                        disabledDate={(current) => current && current < dayjs().startOf("day")}
                      />
                      <Button
                        type="primary"
                        className="mt-2 w-full border-orange-500"
                        style={{ backgroundColor: palette.secondary }}
                        onClick={() => handleSave(process.id)}
                      >
                        {t("tnaPage.tnaDetails.save")}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      type="link"
                      className="text-orange-500 p-0 mt-2"
                      icon={<EditOutlined />}
                      onClick={() => setEditingProcessId(process.id)}
                    >
                      {t("tnaPage.tnaDetails.addActualDate")}
                    </Button>
                  )}
                  {process.dependentProcess && (
                    <p className="text-sm mt-2">
                      <span className="font-semibold">{t("tnaPage.tnaDetails.dependencyOn")}:</span>{" "}
                      {process.dependentProcess.name}
                    </p>
                  )}
                </div>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </div>
    </>
  );
};

export default TNADetails;
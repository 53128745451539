import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';
import { DatePicker, Button, Switch, message, Form, Modal, Skeleton } from 'antd';
import { IoChevronBackOutline, IoTrashOutline } from 'react-icons/io5';
import { addProcess, deleteProcess, updateProcess, TNAProcess, resetTemplateState } from '../../../redux/tna/tnaTemplateSlice';
import { createTnaProcess, getTnaProcess, getTnaTemplateDetails } from '../../../services/orderServiceApi/tnaApi';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { TnaTemplateProcessMapping } from '../../../types/groyyo_plus/tna';
import palette from '../../../constants/pallete';
import CustomDropdownWithAdd from '../../../components/CustomDropdownWithAdd';
import { createItemWrapper } from '../../../utilities/helper';
import RearrangeProcessesModal from './RearrangeProcessesModal';
import { useTranslation } from 'react-i18next';

const TNACreateScreen = () => {
    const { t }: any = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tnaTemplateId } = useParams();
    const { processes } = useSelector((state: RootState) => state.tnaTemplate);
    const [form] = Form.useForm();
    const [tnaTemplateLoading, setTnaTemplateLoading] = useState(false);

    useEffect(() => {
        if (tnaTemplateId && tnaTemplateId !=="custom") {
            fetchTnaTemplateDetails(+tnaTemplateId);
        }else{
            handleAddProcess()
        }
        return () => {
            dispatch(resetTemplateState());
        };
    }, [tnaTemplateId]);

    const fetchTnaTemplateDetails = async (tnaTemplateId: number) => {
        setTnaTemplateLoading(true)
        try {
            const response = await getTnaTemplateDetails(tnaTemplateId);
            const responseData = response?.data;

            if (responseData?.status) {
                const fetchedProcesses: TNAProcess[] = responseData?.result.tnaTemplateProcessMapping.map(
                    (item: TnaTemplateProcessMapping) => ({
                        id: item.id,
                        tnaProcessId: item.tnaProcessId,
                        dependentTnaProcessId: item.dependentTnaProcessId,
                        sequence: item.sequence,
                        isManualEntry: item.isManualEntry,
                        process: item.tnaProcess,
                        dependencyOn: item.dependentProcess,
                        planDate: '',
                    })
                );
                form.setFieldsValue(mapProcessesToFormFields(fetchedProcesses));
                fetchedProcesses.sort((a, b) => a.sequence - b.sequence).forEach((process) => {
                    dispatch(addProcess(process));
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setTnaTemplateLoading(false)
        }
    };

    const mapProcessesToFormFields = (processes: TNAProcess[]) => {
        return processes.reduce((acc: any, process) => {
            acc[`processName_${process.id}`] = process.process.id;
            acc[`dependencyOn_${process.id}`] = process.dependencyOn?.id;
            acc[`planDate_${process.id}`] = process.planDate ? dayjs(process.planDate, 'YYYY-MM-DD') : null;
            return acc;
        }, {});
    };

    const handleAddProcess = () => {
        const newProcess: TNAProcess = {
            id: Date.now(),
            tnaProcessId: 0,
            dependentTnaProcessId: null,
            sequence: processes.length + 1,
            isManualEntry: true,
            process: { id: -1, name: '', departmentId: null, factoryId: null, type: '', processPhase: '' },
            dependencyOn: null,
            planDate: '',
        };
        dispatch(addProcess(newProcess));
        form.setFieldsValue({
            ...form.getFieldsValue(),
            [`processName_${newProcess.id}`]: undefined,
            [`dependencyOn_${newProcess.id}`]: undefined,
            [`planDate_${newProcess.id}`]: undefined,
        });
    };

    const handleUpdateProcess = (id: number, updatedData: Partial<TNAProcess>) => {
        dispatch(updateProcess({ id, data: updatedData }));
    };

    const handleDeleteProcess = (processId: number) => {
        dispatch(deleteProcess(processId));
        const fields = form.getFieldsValue();
        delete fields[`processName_${processId}`];
        delete fields[`dependencyOn_${processId}`];
        delete fields[`planDate_${processId}`];
        form.setFieldsValue(fields);
    };

    const handleDateChange = (id: number, date: Dayjs | null) => {
        if (date && date.isValid()) {
            handleUpdateProcess(id, { planDate: date.format('YYYY-MM-DD') });
        }
    };


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dragProcesses, setDragProcesses] = useState<TNAProcess[]>([]);

    const handleSaveTemplate = async () => {
        try {
            await form.validateFields();
            form.getFieldsValue(true);
            setDragProcesses([...processes]);
            setIsModalOpen(true);
        } catch (error) {
            message.error('Please fix the errors before saving.');
        }
    };


    return (
        <div className="">
            <div className="flex items-center justify-between w-full mb-6">
                <div className="flex items-center gap-2">
                    <IoChevronBackOutline
                        className="text-2xl cursor-pointer"
                        onClick={() => navigate(-1)}
                    />
                    <h1 className="text-2xl font-bold">{t("tnaPage.create.title")}</h1>
                </div>
                <Button
                    type="primary"
                    onClick={handleAddProcess}
                    style={{
                        backgroundColor: palette.secondary,
                    }}
                >
                     {t("tnaPage.create.add_new_process")}
                </Button>
            </div>

            {tnaTemplateLoading ? (
                <div className="grid grid-cols-2 gap-4">
                    {Array.from({ length: 4 }).map((_, index) => (
                        <div key={index} className="h-[50vh] w-full bg-gray-200 rounded-lg shadow-sm">
                            <Skeleton.Node active style={{ height: '100%', width: '100%' }} />
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <Form form={form} layout="vertical" autoComplete="off">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {
                                processes?.map((process, index) => (
                                    <div
                                        key={process.id}
                                        className="p-4 border rounded-lg shadow-sm flex flex-col gap-3 bg-white"
                                    >
                                        <div className="flex justify-between items-start">
                                            <h3 className="font-bold text-lg">{`${t("tnaPage.create.process")} ${index + 1}`}</h3>
                                            <IoTrashOutline
                                                className="cursor-pointer text-xl"
                                                onClick={() => handleDeleteProcess(process.id)}
                                                style={{ color: palette.error }}
                                            />
                                        </div>
                                        <CustomDropdownWithAdd
                                            form={form}
                                            label={t("tnaPage.create.process_name")}
                                            placeholder={t("tnaPage.create.process_placeholder")}
                                            fetchItems={getTnaProcess}
                                            addItem={createItemWrapper(createTnaProcess)}
                                            onSelect={(item) =>
                                                handleUpdateProcess(process.id, {
                                                    process: {
                                                        id: item.id,
                                                        name: item.name,
                                                        departmentId: null,
                                                        factoryId: null,
                                                        type: '',
                                                        processPhase: '',
                                                    },
                                                })
                                            }
                                            modalFields={[
                                                { name: 'name', label: 'Name', required: true },
                                            ]}
                                            formItemName={`processName_${process.id}`}
                                            required
                                            value={process.process?.name}
                                        />

                                        <CustomDropdownWithAdd
                                            form={form}
                                            label={t("tnaPage.create.dependency_on")}
                                            placeholder={t("tnaPage.create.dependency_on_placeholder")}                                            
                                            fetchItems={getTnaProcess}
                                            addItem={createItemWrapper(createTnaProcess)}
                                            onSelect={(item) =>
                                                handleUpdateProcess(process.id, {
                                                    dependencyOn: {
                                                        id: item.id,
                                                        name: item.name,
                                                        departmentId: null,
                                                        factoryId: null,
                                                        type: '',
                                                        processPhase: '',
                                                    },
                                                })
                                            }
                                            modalFields={[
                                                { name: 'name', label: 'Name', required: true },
                                            ]}
                                            formItemName={`dependencyOn_${process.id}`}
                                            required={false}
                                            value={process.dependencyOn?.name}
                                        />
                                        <Form.Item
                                           name={`planDate_${process.id}`}
                                           label={t("tnaPage.create.plan_date")}
                                           rules={[{ required: true, message: t("tnaPage.create.errors.plan_date_required") }]}
                                            initialValue={process.planDate ? dayjs(process.planDate, 'YYYY-MM-DD') : null}
                                        >
                                            <DatePicker
                                                className="mb-2 w-full"
                                                placeholder={t("tnaPage.create.plan_date")}
                                                format="DD/MM/YYYY"
                                                onChange={(date) => handleDateChange(process.id, date)}
                                                disabledDate={(current) => current && current < dayjs().startOf('day')}
                                            />
                                        </Form.Item>
                                        <div className="flex items-center gap-2">
                                            <Switch
                                                checked={process.isManualEntry}
                                                onChange={(checked) =>
                                                    handleUpdateProcess(process.id, { isManualEntry: checked })
                                                }
                                                style={{
                                                    backgroundColor: process.isManualEntry ? palette.secondary : '#d9d9d9',
                                                }}
                                            />
                                            <span>{t("tnaPage.create.manual_entry")}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <Button
                            type="primary"
                            className="w-full mt-4"
                            onClick={handleSaveTemplate}
                            style={{ backgroundColor: palette.secondary }}
                        >
                            {t("tnaPage.create.save")}
                        </Button>
                    </Form>

                    <RearrangeProcessesModal
                        isOpen={isModalOpen}
                        processes={dragProcesses}
                        onClose={() => setIsModalOpen(false)}
                        dragProcesses={dragProcesses}
                        setDragProcesses={setDragProcesses}
                    />
                </>
            )}
        </div>
    );
};

export default TNACreateScreen;
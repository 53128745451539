import { useEffect } from "react";
import { IAvailableForDispatch } from "../../../types/groyyo_plus/dispatch";
import PaginatedTable from "../../../components/PaginatedTable";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchAvailableForDispatch, setSelectedDispatch } from "../../../redux/dispatch/dispatchSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ReadyForDispatch = () => {
  const { availableForDispatch, loading } = useSelector(
    (state: RootState) => state.dispatch
  );
  
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t }: any = useTranslation();

  useEffect(() => {
    dispatch(fetchAvailableForDispatch({ dispatchId: undefined }));
  }, [dispatch]);

  const columns: ColumnsType<IAvailableForDispatch> = [
    {
      title: t("readyForDispatch.columns.so_number"),
      dataIndex: ["salesOrderItem", "salesOrder", "soNo"],
      key: "soNo",
      render: (text, record) => (
        <a
          className="text-blue-400 font-bold underline"
          onClick={() => {
            dispatch(setSelectedDispatch(record));
            navigate(`/dispatch/available-dispatch/${record?.id}/${record?.salesOrderItem?.id}`);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: t("readyForDispatch.columns.buyer_name"),
      dataIndex: ["salesOrderItem", "salesOrder", "buyer", "name"],
      key: "buyerName",
    },
    {
      title: t("readyForDispatch.columns.merchandiser"),
      dataIndex: ["salesOrderItem", "salesOrder", "merchandiserName"],
      key: "merchandiserName",
    },
    {
      title: t("readyForDispatch.columns.so_date"),
      dataIndex: ["salesOrderItem", "salesOrder", "soDate"],
      key: "soDate",
    },
    {
      title: t("readyForDispatch.columns.expected_date"),
      dataIndex: ["salesOrderItem", "salesOrder", "expectedDate"],
      key: "expectedDate",
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">{t("readyForDispatch.title")}</h1>
      </div>
      <PaginatedTable<IAvailableForDispatch>
        columns={columns}
        dataSource={availableForDispatch}
        rowKey="id"
        loading={loading}
        
      />
    </div>
  );
};

export default ReadyForDispatch;
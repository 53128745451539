import React, { useEffect, useState } from "react";
import { Modal, Typography, message } from "antd";
import FactorySelect from "../components/FactorySelect";
import useFactorySelection from "../hooks/useFactorySelection";
import { jwtDecode } from "jwt-decode";

const Dashboard: React.FC = () => {
  const [userRole, setUserRole] = useState<{ id: number; name: string } | null>(null);
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { selectedFactoryId, handleFactorySelect } = useFactorySelection({setIsOpen: setIsModalOpen});

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");

    const authToken = localStorage.getItem("authToken") as string;
    const decodedToken: any = (authToken === null) ? "" : jwtDecode(authToken);
  

    if (userDetails.role?.name === "SUPER_ADMIN" && !Boolean(decodedToken?.factory[0]?.id)) {
      setUserRole({ id: userDetails?.role?.id, name: userDetails?.role?.name });
      setIsModalOpen(true);
    }
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <h2>Welcome to the Dashboard!</h2>

      <Modal
        title="Select a Factory"
        open={isModalOpen}
        onCancel={() => {}}
        closable={false}
      >
        <Typography.Text className="!mb-[1rem]">Please select a factory:</Typography.Text>
        <FactorySelect selectedFactoryId={selectedFactoryId} onFactorySelect={handleFactorySelect} />
      </Modal>
    </div>
  );
};

export default Dashboard;
import React, { useEffect, useState } from 'react';
import DashboardLayout from '../layouts/DashboardLayout';
import { Button, Table, Modal, Form, Input, Select, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { getSubCategories, createSubCategory, bulkUploadSubCategories } from '../services/orderServiceApi/subCategoriesApi';
import { ApiResponse } from '../types/response';
import { CreateSubCategoryDto } from '../types/subCategory';
import { getCategories } from '../services/orderServiceApi/categoriesApi';
import DownloadTemplateButton from '../components/DownloadTemplateButton';

const Subcategories = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [subCategories, setSubCategories] = useState<CreateSubCategoryDto[]>([]);
  const [categories, setCategories] = useState<{ id: number; name: string }[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
  }, [selectedCategory]);

  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      const responseData: ApiResponse<{ id: number; name: string }[]> = response?.data;
      if (responseData?.status && responseData?.statusCode === 200) {
        setCategories(responseData.result);
      }
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  const fetchSubCategories = async () => {
    try {
      const response = await getSubCategories(selectedCategory);
      const responseData: ApiResponse<CreateSubCategoryDto[]> = response?.data;
      if (responseData?.status && responseData?.statusCode === 200) {
        setSubCategories(responseData.result);
      }
    } catch (error) {
      console.error("Failed to fetch sub-categories:", error);
    }
  };

  const handleAddSubCategory = async (values: CreateSubCategoryDto) => {
    setLoading(true);
    try {
      const response = await createSubCategory(values);
      const responseData: ApiResponse<CreateSubCategoryDto> = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Sub-category added successfully!");
        setIsModalVisible(false);
        fetchSubCategories();
        form.resetFields();
      } else {
        message.error("Failed to add sub-category");
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error adding sub-category");
      console.error("Error adding sub-category:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBulkUpload = async (file: File) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: CreateSubCategoryDto[] = XLSX.utils.sheet_to_json(worksheet);

      if (jsonData?.length === 0) {
        message.error("Empty File!");
        return;
      }

      if (!jsonData.every(item => item.name && item.categoryId)) {
        message.error('Invalid file format. Ensure it contains "name" and "categoryId" columns.');
        return;
      }

      const response: any = await bulkUploadSubCategories(jsonData);
      const responseData = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Sub-categories uploaded successfully!");
        fetchSubCategories();
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error uploading sub-categories");
      console.error("Error uploading sub-categories:", error);
    }
  };

  const columns = [
    {
      title: 'Sub-Category Name',
      dataIndex: 'name',
      key: 'name',
    }
  ];

  return (
    <DashboardLayout>
      <DashboardLayout.Header title="Manage Sub-Categories">
        <DownloadTemplateButton templateName="bulk_subCategories_template.xlsx" buttonText="Download Sub Categories Template" />
        <Button type="primary" onClick={() => setIsModalVisible(true)}>Add Sub-Category</Button>
        <Upload
          beforeUpload={(file) => {
            handleBulkUpload(file);
            return false;
          }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Bulk Upload</Button>
        </Upload>
      </DashboardLayout.Header>
      <DashboardLayout.Body>
        <div className='flex flex-col gap-3'>
          <Select
            placeholder="Select Category"
            style={{ width: 200, marginRight: 16 }}
            onChange={value => setSelectedCategory(value)}
            allowClear
          >
            {categories.map(category => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
          <Table dataSource={subCategories} columns={columns} rowKey="id" />
        </div>

        <Modal
          title="Add New Sub-Category"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleAddSubCategory}
          >
            <Form.Item
              name="name"
              label="Sub-Category Name"
              rules={[{ required: true, message: 'Please enter the sub-category name' }]}
            >
              <Input placeholder="Enter sub-category name" />
            </Form.Item>

            <Form.Item
              name="categoryId"
              label="Category"
              rules={[{ required: true, message: 'Please select a category' }]}
            >
              <Select placeholder="Select category">
                {categories.map(category => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Add Sub-Category
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </DashboardLayout.Body>
    </DashboardLayout>
  );
}

export default Subcategories;
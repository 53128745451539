import React, { useEffect, useState } from 'react';
import DashboardLayout from '../layouts/DashboardLayout';
import { Button, Table, Modal, Form, Input, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { ApiResponse } from '../types/response';
import { CreateDeliveryTypeDto } from '../types/delieveryType';
import { bulkUploadDeliveryTypes, createDeliveryType, getDeliveryTypes } from '../services/orderServiceApi/deliveryTypesApi';
import DownloadTemplateButton from '../components/DownloadTemplateButton';

const Delivery = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [deliveryTypes, setDeliveryTypes] = useState<CreateDeliveryTypeDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchDeliveryTypes();
  }, []);

  const fetchDeliveryTypes = async () => {
    try {
      const response = await getDeliveryTypes();
      const responseData: ApiResponse<CreateDeliveryTypeDto[]> = response?.data;
      if (responseData?.status && responseData?.statusCode === 200) {
        setDeliveryTypes(responseData.result);
      }
    } catch (error) {
      console.error("Failed to fetch delivery types:", error);
    }
  };

  const handleAddDeliveryType = async (values: CreateDeliveryTypeDto) => {
    setLoading(true);
    try {
      const response = await createDeliveryType(values);
      const responseData: ApiResponse<CreateDeliveryTypeDto> = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Delivery type added successfully!");
        setIsModalVisible(false);
        fetchDeliveryTypes();
        form.resetFields();
      } else {
        message.error("Failed to add delivery type");
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error adding delivery type");
      console.error("Error adding delivery type:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBulkUpload = async (file: File) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: CreateDeliveryTypeDto[] = XLSX.utils.sheet_to_json(worksheet);

      if (jsonData?.length === 0) {
        message.error("Empty File!");
        return;
      }

      if (!jsonData.every(item => item.name)) {
        message.error('Invalid file format. Ensure it contains "name" column.');
        return;
      }

      const response: any = await bulkUploadDeliveryTypes(jsonData);
      const responseData = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Delivery types uploaded successfully!");
        fetchDeliveryTypes();
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error uploading delivery types");
      console.error("Error uploading delivery types:", error);
    }
  };

  const columns = [
    {
      title: 'Delivery Type Name',
      dataIndex: 'name',
      key: 'name',
    }
  ];

  return (
    <DashboardLayout>
      <DashboardLayout.Header title="Manage Delivery Types">
        <DownloadTemplateButton templateName="bulk_deliveryTypes_template.xlsx" buttonText="Download Delivery Types Template" />
        <Button type="primary" onClick={() => setIsModalVisible(true)}>Add Delivery Type</Button>
        <Upload
          beforeUpload={(file) => {
            handleBulkUpload(file);
            return false;
          }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Bulk Upload</Button>
        </Upload>
      </DashboardLayout.Header>
      <DashboardLayout.Body>
        <Table dataSource={deliveryTypes} columns={columns} rowKey="id" />

        <Modal
          title="Add New Delivery Type"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleAddDeliveryType}
          >
            <Form.Item
              name="name"
              label="Delivery Type Name"
              rules={[{ required: true, message: 'Please enter the delivery type name' }]}
            >
              <Input placeholder="Enter delivery type name" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Add Delivery Type
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </DashboardLayout.Body>
    </DashboardLayout>
  );
}

export default Delivery;
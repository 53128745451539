import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';

interface DownloadTemplateButtonProps {
  templateName: string; 
  buttonText: string;   
}

const DownloadTemplateButton: React.FC<DownloadTemplateButtonProps> = ({ templateName, buttonText }) => {
  const handleDownload = () => {
    saveAs(`/excelSheets/${templateName}`, templateName);
  };

  return (
    <Button icon={<DownloadOutlined />} onClick={handleDownload}>
      {buttonText}
    </Button>
  );
};

export default DownloadTemplateButton;
import React, { useEffect, useState } from 'react';
import DashboardLayout from '../layouts/DashboardLayout';
import { Button, Table, Modal, Form, Input, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { getCustomers, createCustomer, bulkUploadCustomers } from '../services/orderServiceApi/customersApi';
import { ApiResponse } from '../types/response';
import { CreateBuyerDto } from '../types/buyers';
import DownloadTemplateButton from '../components/DownloadTemplateButton';

const Customers = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [customers, setCustomers] = useState<CreateBuyerDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await getCustomers();
      const responseData: ApiResponse<CreateBuyerDto[]> = response?.data;
      if (responseData?.status && responseData?.statusCode === 200) {
        setCustomers(responseData.result);
      }
    } catch (error) {
      console.error("Failed to fetch customers:", error);
    }
  };

  const handleAddCustomer = async (values: CreateBuyerDto) => {
    setLoading(true);
    try {
      const response = await createCustomer(values);
      const responseData: ApiResponse<CreateBuyerDto> = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Customer added successfully!");
        setIsModalVisible(false);
        fetchCustomers();
        form.resetFields();
      } else {
        message.error("Failed to add customer");
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error adding customer");
      console.error("Error adding customer:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBulkUpload = async (file: File) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: CreateBuyerDto[] = XLSX.utils.sheet_to_json(worksheet);

      if (jsonData?.length === 0) {
        message.error("Empty File!");
        return;
      }

      if (!jsonData.every(item => item.name)) {
        message.error('Invalid file format. Ensure it contains "name" column.');
        return;
      }

      const response: any = await bulkUploadCustomers(jsonData);
      const responseData = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Customers uploaded successfully!");
        fetchCustomers();
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error uploading customers");
      console.error("Error uploading customers:", error);
    }
  };

  const columns = [
    {
      title: 'Customer Name',
      dataIndex: 'name',
      key: 'name',
    }
  ];

  return (
    <DashboardLayout>
      <DashboardLayout.Header title="Manage Customers">
        <DownloadTemplateButton templateName="bulk_customers_template.xlsx" buttonText="Download Customer Template" />
        <Button type="primary" onClick={() => setIsModalVisible(true)}>Add Customer</Button>
        <Upload
          beforeUpload={(file) => {
            handleBulkUpload(file);
            return false;
          }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Bulk Upload</Button>
        </Upload>
      </DashboardLayout.Header>
      <DashboardLayout.Body>
        <Table dataSource={customers} columns={columns} rowKey="id" />

        <Modal
          title="Add New Customer"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleAddCustomer}
          >
            <Form.Item
              name="name"
              label="Customer Name"
              rules={[{ required: true, message: 'Please enter the customer name' }]}
            >
              <Input placeholder="Enter customer name" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Add Customer
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </DashboardLayout.Body>
    </DashboardLayout>
  );
}

export default Customers;

import { ORDER_SERVICE_API } from ".";
import { orderApi } from "./orderService";

const salesOrderItem = ORDER_SERVICE_API.SALES_ORDER_ITEM;

export const createSalesOrderItems = async(payload: any) => {
    return orderApi.post(`${salesOrderItem}`, payload)
}

export const getSalesOrderItems = async({salesOrderId}:{salesOrderId: number}) => {
    return orderApi.get(`${salesOrderItem}?salesOrderId=${salesOrderId}`)
}

export const getWipMaterialItems = async() => {
    return orderApi.get(`${salesOrderItem}/wip/materials`);
}

export const getWipProductionItems = async() => {
    return orderApi.get(`${salesOrderItem}/wip/production`);
}

export const getTnaOrders = async () => {
    return orderApi.get(`${salesOrderItem}/tna-orders`)
}

export const checkMarkAsDelivered = async({salesOrderItemId} : {salesOrderItemId: number}) => {
    return orderApi.get(`${salesOrderItem}/can-mark-as-delivered?salesOrderItemId=${salesOrderItemId}`)
}

export const markAsCompleted = async({salesOrderItemId} : {salesOrderItemId: number}) => {
    return orderApi.put(`${salesOrderItem}/mark-as-delivered?salesOrderItemId=${salesOrderItemId}`)
}

export const updateOrderItem = async(payload: any) => {
    return orderApi.put(`${salesOrderItem}/update-details`, payload)
}
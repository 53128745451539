import React, { useState, useEffect } from 'react';
import { Drawer, Button, DatePicker, Tag, Typography, Collapse } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { getCustomers } from '../../../services/orderServiceApi/customersApi';
import { getItems, getSOListing } from '../../../services/orderServiceApi/salesOrderApi';
import { getProductTypes } from '../../../services/orderServiceApi/productTypesApi';
import dayjs from 'dayjs';
import palette from '../../../constants/pallete';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;
const { Panel } = Collapse;

interface DashboardFilterProps {
    onApply: (filters: {
        salesOrderId: number;
        salesOrderItemId: number;
        buyerId: number;
        productTypeId: number;
        startDate: string;
        endDate: string;
    }) => void;
    onClear: () => void;
}

const DashboardFilter: React.FC<DashboardFilterProps> = ({ onApply, onClear }) => {
    const { t }: any = useTranslation(); 
    const [visible, setVisible] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<{
        buyerId: number;
        salesOrderId: number;
        productTypeId: number;
        startDate: string;
        endDate: string;
        salesOrderItemId: number;
    }>({
        buyerId: 0,
        salesOrderId: 0,
        productTypeId: 0,
        salesOrderItemId: 0,
        startDate: dayjs().startOf('month').format('DD/MM/YYYY'),
        endDate: dayjs().endOf('month').format('DD/MM/YYYY'),
    });

    const [buyers, setBuyers] = useState<Array<{ id: number; name: string }>>([]);
    const [styles, setStyles] = useState<Array<{ id: number; name: string }>>([]);
    const [salesOrders, setSalesOrders] = useState<Array<{ id: number; name: string }>>([]);
    const [products, setProducts] = useState<Array<{ id: number; name: string }>>([]);

    const handleOpen = () => setVisible(true);
    const handleClose = () => setVisible(false);

    const handleFilterChange = (category: keyof typeof selectedFilters, value: number | string) => {
        setSelectedFilters((prev) => ({
            ...prev,
            [category]: value,
        }));
    };

    const handleApply = () => {
        onApply(selectedFilters);
        handleClose();
    };

    const handleClear = () => {
        setSelectedFilters({
            buyerId: 0,
            salesOrderId: 0,
            productTypeId: 0,
            salesOrderItemId: 0,
            startDate: dayjs().startOf('month').format('DD/MM/YYYY'),
            endDate: dayjs().endOf('month').format('DD/MM/YYYY'),
        });
        onClear();
        handleClose();
    };

    const fetchBuyers = async () => {
        try {
            const response = await getCustomers();
            if (response?.data?.status) {
                setBuyers(
                    response?.data?.result?.map((buyer: { name: string; id: number }) => ({
                        id: buyer.id,
                        name: buyer.name,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching buyers:', error);
        }
    };

    const fetchStyles = async () => {
        try {
            const response = await getItems();
            if (response?.data?.status) {
                setStyles(
                    response?.data?.result?.map((style: { name: string; id: number }) => ({
                        id: style.id,
                        name: style.name,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching styles:', error);
        }
    };

    const fetchSalesOrders = async () => {
        try {
            const response = await getSOListing();
            if (response?.data?.status) {
                setSalesOrders(
                    response?.data?.result?.map((order: { soNo: string; id: number }) => ({
                        id: order.id,
                        name: order.soNo,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching sales orders:', error);
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await getProductTypes();
            if (response?.data?.status) {
                setProducts(
                    response?.data?.result.map((product: { name: string; id: number }) => ({
                        id: product.id,
                        name: product.name,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching product types:', error);
        }
    };

    useEffect(() => {
        fetchBuyers();
        fetchStyles();
        fetchSalesOrders();
        fetchProducts();
    }, []);

    const renderTags = (category: keyof typeof selectedFilters, options: Array<{ id: number; name: string }>) => {
        return (
            <div className="flex flex-wrap gap-2">
                {options.map((option) => (
                    <Tag
                        key={option?.id}
                        className={`cursor-pointer text-sm font-semibold px-4 py-1 rounded-lg ${selectedFilters[category] === option.id
                                ? 'bg-[#CC6A4C] text-white'
                                : 'bg-[#FFF6F3] text-[#CC6A4C] border border-[#CC6A4C]'
                            }`}
                        onClick={() => handleFilterChange(category, option.id)}
                    >
                        {option.name}
                    </Tag>
                ))}
            </div>
        );
    };

    return (
        <>
            <Button
                icon={<FilterOutlined />}
                type="primary"
                onClick={handleOpen}
                style={{
                    marginBottom: '16px',
                    color: palette.secondary,
                    backgroundColor: palette.white,
                    borderColor: palette.secondary,
                }}
            >
                {t('home.filter.filter')}
            </Button>

            <Drawer
                title={<Title level={4}>{t('home.filter.title')}</Title>}
                placement="right"
                onClose={handleClose}
                open={visible}
                width="70vw"
                bodyStyle={{ padding: '16px', backgroundColor: '#fafafa' }}
                footer={
                    <div className="flex justify-between">
                        <Button onClick={handleClear} className="hover:text-orange-500">
                            {t('home.filter.clear')}
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleApply}
                            style={{ backgroundColor: palette.secondary, borderColor: palette.secondary }}
                        >
                            {t('home.filter.apply')}
                        </Button>
                    </div>
                }
            >
                <Collapse defaultActiveKey={['1', '2', '3', '4', '5']} expandIconPosition="right">
                    <Panel header={t('home.filter.buyer')} className="text-base font-bold" key="1">
                        {renderTags('buyerId', buyers)}
                    </Panel>
                    <Panel header={t('home.filter.style')} className="text-base font-bold" key="2">
                        {renderTags('salesOrderItemId', styles)}
                    </Panel>
                    <Panel header={t('home.filter.sales_order')} className="text-base font-bold" key="3">
                        {renderTags('salesOrderId', salesOrders)}
                    </Panel>
                    <Panel header={t('home.filter.product')} className="text-base font-bold" key="4">
                        {renderTags('productTypeId', products)}
                    </Panel>
                    <Panel header={t('home.filter.date')} key="5" className="text-base font-bold">
                        <div className="flex gap-4">
                            <DatePicker
                                format="DD/MM/YYYY"
                                value={dayjs(selectedFilters.startDate, 'DD/MM/YYYY')}
                                onChange={(date) =>
                                    handleFilterChange('startDate', date?.format('DD/MM/YYYY') || '')
                                }
                                style={{ width: '50%' }}
                            />
                            <DatePicker
                                format="DD/MM/YYYY"
                                value={dayjs(selectedFilters.endDate, 'DD/MM/YYYY')}
                                onChange={(date) =>
                                    handleFilterChange('endDate', date?.format('DD/MM/YYYY') || '')
                                }
                                style={{ width: '50%' }}
                            />
                        </div>
                    </Panel>
                </Collapse>
            </Drawer>
        </>
    );
};

export default DashboardFilter;
import { USER_SERVICE_API } from ".";
import { userApi } from "./userService";

const loginUrl = USER_SERVICE_API.LOGIN;
const userUrl = USER_SERVICE_API.USER;

export const generateOtp = async (mobileNo: string, platform: string) => {
  return userApi.get(`${loginUrl}/generate-otp`, {
    params: { mobileNo, platform },
  });
};

export const login = async (payload: {
  mobileNo: string;
  otpCode: string;
  fcmToken: string;
  deviceId: string;
  platform: string;
}) => {
  return userApi.post(`${loginUrl}/login`, payload);
};

export const signup = async (signupData: any) => {
  return userApi.post(`${loginUrl}/signup`, signupData);
};

export const logout = async (
  mobileNo: string,
  fcmToken: string,
  deviceId: string
) => {
  return userApi.post(`${loginUrl}/logout`, {
    mobileNo,
    fcmToken,
    deviceId,
  });
};

export const getUsers = async () => {
  return userApi.get(`${userUrl}`);
};

export const getSoftDeletedUsers = async () => {
  return userApi.get(`${userUrl}/soft-deleted`);
};

export const createUser = async (userData: any) => {
  return userApi.post(`${userUrl}/create-user`, userData);
};

export const getUserDetail = async () => {
  return userApi.get(`${userUrl}/detail`);
};

export const softDeleteUser = async (userId: number) => {
  return userApi.delete(`${userUrl}/soft-delete/${userId}`);
};

export const restoreSoftDeletedUsers = async (ids: number[]) => {
  return userApi.patch(`${userUrl}/restore`, { ids });
};

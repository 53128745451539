import React, { useEffect, useState } from 'react';
import { getWipMaterialItems } from '../../../../services/orderServiceApi/salesOrderItemApi';
import { IMaterialTracking } from '../../../../types/groyyo_plus/materialRecievable';
import MaterialTrackingCard from './MaterialTrackingCard';
import { formatDateToDDMMYYYY, formatToDDMMYYYY } from '../../../../utilities/helper';
import { message } from 'antd';
import ExcelJS from 'exceljs';
import NoDataAvailable from '../../../../components/NoDataAvailable';
import { useTranslation } from 'react-i18next';

const MaterialTracking = () => {
    const [wipMaterials, setWipMaterials] = useState<IMaterialTracking[]>([]);
    const [selectedCards, setSelectedCards] = useState<Set<number>>(new Set());
    const { t }: any = useTranslation();

    const fetchWipMaterials = async () => {
        try {
            const response = await getWipMaterialItems();
            const responseData = response?.data;

            if (responseData?.status) {
                setWipMaterials(responseData?.result);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleCheckboxChange = (id: number) => {
        setSelectedCards((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(id)) {
                newSelected.delete(id);
            } else {
                newSelected.add(id);
            }
            return newSelected;
        });
    };

    const downloadSelected = async () => {
        const selectedIds = Array.from(selectedCards);

        if (selectedIds.length === 0) {
            message.warning(t('material.tracking.messages.no_cards_selected'));
            return;
        }

        const excelJSON: any[] = [];

        selectedIds.forEach((id) => {
            const material = wipMaterials.find((item) => item.id === id);
            if (material) {
                material.bom?.bomItems?.forEach((bomItem) => {
                    excelJSON.push({
                        [t('material.tracking.excel_headers.buyer')]: material.salesOrder.buyer.name,
                        [t('material.tracking.excel_headers.so_number')]: material.salesOrder.soNo,
                        [t('material.tracking.excel_headers.style_number')]: material.item.name,
                        [t('material.tracking.excel_headers.so_date')]: formatDateToDDMMYYYY(material.salesOrder.soDate),
                        [t('material.tracking.excel_headers.order_quantity')]: material.quantity,
                        [t('material.tracking.excel_headers.material')]: 
                            bomItem.material?.type === 'Fabric'
                                ? bomItem.material?.fabric?.name
                                : bomItem.material?.trim?.name,
                        [t('material.tracking.excel_headers.unit')]: bomItem.unit?.name,
                        [t('material.tracking.excel_headers.type')]: bomItem.material?.type,
                        [t('material.tracking.excel_headers.average')]: bomItem.average,
                        [t('material.tracking.excel_headers.expected_quantity')]: 
                            bomItem?.materialReceivable?.quantity ||
                            bomItem.quantity + (bomItem.quantity * bomItem.average) / 100,
                        [t('material.tracking.excel_headers.received_quantity')]: bomItem?.materialReceivable?.receivedQuantity || 0,
                        [t('material.tracking.excel_headers.rejected_quantity')]: bomItem?.materialReceivable?.rejectedQuantity || 0,
                        [t('material.tracking.excel_headers.expected_in_house_date')]: formatDateToDDMMYYYY(bomItem.inHouseDate),
                        [t('material.tracking.excel_headers.actual_in_house_date')]: bomItem.materialReceivable?.updatedAt
                            ? formatToDDMMYYYY(bomItem.materialReceivable.updatedAt)
                            : '-',
                    });
                });
            } else {
                console.log(t('material.tracking.messages.material_not_found', { id }));
            }
        });

        if (excelJSON.length === 0) {
            message.warning(t('material.tracking.messages.no_data_found'));
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(t('material.tracking.excel_sheet_name'));

        const headers = [
            t('material.tracking.excel_headers.buyer'),
            t('material.tracking.excel_headers.so_number'),
            t('material.tracking.excel_headers.style_number'),
            t('material.tracking.excel_headers.so_date'),
            t('material.tracking.excel_headers.order_quantity'),
            t('material.tracking.excel_headers.material'),
            t('material.tracking.excel_headers.unit'),
            t('material.tracking.excel_headers.type'),
            t('material.tracking.excel_headers.average'),
            t('material.tracking.excel_headers.expected_quantity'),
            t('material.tracking.excel_headers.received_quantity'),
            t('material.tracking.excel_headers.rejected_quantity'),
            t('material.tracking.excel_headers.expected_in_house_date'),
            t('material.tracking.excel_headers.actual_in_house_date'),
        ];

        worksheet.columns = headers.map((header) => ({
            header,
            key: header,
            width: Math.max(header.length + 2, 15),
        }));

        worksheet.getRow(1).eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CC6A4C' },
            };
            cell.font = {
                bold: true,
                color: { argb: 'FFFFFF' },
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        excelJSON.forEach((data) => {
            worksheet.addRow(data);
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const fileName = `${t('material.tracking.file_name_prefix')}-${Date.now()}.xlsx`;

        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        message.success(t('material.tracking.messages.file_downloaded', { fileName }));
        setSelectedCards(new Set());
    };

    useEffect(() => {
        fetchWipMaterials();
    }, []);

    return (
        <>
            <div className="text-2xl font-bold flex flex-row gap-2 items-center justify-between w-full mb-4">
                <p>{t('material.tracking.title')}</p>
                {selectedCards.size > 0 && (
                    <button
                        className="text-sm font-medium text-red-500 underline ml-4"
                        onClick={() => setSelectedCards(new Set())}
                    >
                        {t('material.tracking.clear_all')}
                    </button>
                )}
            </div>
            <div className="flex flex-col gap-6">
                {wipMaterials?.length > 0 ? (
                    wipMaterials.map((material) => (
                        <MaterialTrackingCard
                            key={material?.id}
                            material={material}
                            isSelected={selectedCards.has(material?.id)}
                            onCheckboxChange={() => handleCheckboxChange(material?.id)}
                        />
                    ))
                ) : (
                    <NoDataAvailable text={t('material.tracking.no_data')} />
                )}

                {selectedCards.size > 0 && (
                    <div
                        className="fixed bottom-0 left-0 w-full text-white text-center py-4 font-semibold cursor-pointer"
                        style={{ backgroundColor: '#CC6A4C' }}
                        onClick={downloadSelected}
                    >
                        {t('material.tracking.download_selected')}
                    </div>
                )}
            </div>
        </>
    );
};

export default MaterialTracking;
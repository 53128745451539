import React, { useEffect, useState } from "react";
import { Form, Input, Button, DatePicker, Row, Col, message } from "antd";
import {
  formatDateToDDMMYYYY,
  roundNumber,
} from "../../../../utilities/helper";
import palette from "../../../../constants/pallete";
import HistoryModal from "../HistoryModal";
import CustomDropdown from "../../../../components/CustomDropdown";
import { getDepartments } from "../../../../services/orderServiceApi/departmentsApi";
import dayjs from "dayjs";
import {
  IMaterialIssuedRequestBody,
  IReceivableListItem,
} from "../../../../types/groyyo_plus/materialRecievable";
import { createMaterialIssued } from "../../../../services/orderServiceApi/materialRecievableApi";
import { useSearchParams } from "react-router-dom";
import { MaterialStatusType } from "../../../../constants/enums";
import RolePermission from "../../../../utilities/RolePermission/RolePermission";
import { useTranslation } from "react-i18next";

interface IIssuedListItemProps {
  issuedListItem: IReceivableListItem;
  salesOrderItemId: number;
}

const IssuedListItem: React.FC<IIssuedListItemProps> = ({
  issuedListItem,
  salesOrderItemId,
}) => {
  const [form] = Form.useForm();
  const { t }: any = useTranslation();

  const [searchParams] = useSearchParams();
  const materialStatusType = searchParams.get("materialStatusType");

  const [receivedQuantity, setReceivedQuantity] = useState(0);
  const [issuedQuantity, setIssuedQuantity] = useState(0);
  const [toBeIssued, setToBeIssued] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (issuedListItem) {
      const { materialReceivable } = issuedListItem;

      const calculatedReceivedQuantity =
        materialReceivable?.rejectedQuantity ||
        materialReceivable?.receivedQuantity
          ? materialReceivable?.receivedQuantity +
            materialReceivable?.rejectedQuantity
          : 0;

      setIssuedQuantity(materialReceivable?.materialIssued?.quantity || 0);
      setReceivedQuantity(calculatedReceivedQuantity);
      setToBeIssued(calculatedReceivedQuantity - issuedQuantity);
    }
  }, [issuedListItem]);

  const showModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const handleSubmit = async (values: any) => {
    try {
      const payload: IMaterialIssuedRequestBody = {
        bomItemId: issuedListItem?.id,
        departmentId: values.department,
        issuedDate: values.issueDate.format("DD/MM/YYYY"),
        materialId: issuedListItem?.material?.id!,
        materialReceivableId: issuedListItem?.materialReceivable?.id!,
        quantity: +values.issuedQuantity,
        salesOrderItemId,
      };

      const response = await createMaterialIssued(payload);
      const responseData = response?.data;

      if (responseData?.status) {
        message.success(t("material.issued.list_item.messages.issue_success"));
        setIssuedQuantity(issuedQuantity + payload?.quantity);
        setToBeIssued(toBeIssued - payload?.quantity);
        form.resetFields();
      } else {
        const errorMessage =
          responseData?.message ||
          t("material.issued.list_item.messages.issue_failed");
        message.error(errorMessage);
      }
    } catch (error: any) {
      const errorResponse = error?.response?.data;
      const errorMessage =
        errorResponse?.result?.message ||
        errorResponse?.message ||
        t("material.issued.list_item.messages.unexpected_error");
      message.error(errorMessage);
      console.error(
        t("material.issued.list_item.messages.error_log"),
        errorResponse || error
      );
    }
  };

  const handleError = (errorInfo: any): void => {
    console.error(
      t("material.issued.list_item.messages.validation_failed"),
      errorInfo
    );
    message.error(t("material.issued.list_item.messages.fill_required_fields"));
  };

  return (
    <div className="flex flex-col shadow-md rounded-lg bg-white p-3 gap-3">
      <div className="flex flex-col gap-3 p-2">
        <div className="flex flex-row gap-4">
          <img
            src={
              issuedListItem?.material?.type === "Fabric"
                ? issuedListItem?.material?.fabric?.imageUrl ||
                  "/svg/NoImage.svg"
                : issuedListItem?.material?.trim?.imageUrl || "/svg/NoImage.svg"
            }
            alt={
              issuedListItem?.material?.type === "Fabric"
                ? issuedListItem?.material?.fabric?.name
                : issuedListItem?.material?.trim?.name
            }
            className="!w-[3rem] !h-[3rem] rounded-md"
          />
          <div className="flex flex-col justify-start">
            <p className="font-semibold">
              {issuedListItem?.material?.type === "Fabric"
                ? issuedListItem?.material?.fabric?.name
                : issuedListItem?.material?.trim?.name}
            </p>
            <p className="text-xs">
              {issuedListItem?.material?.type === "Fabric"
                ? issuedListItem?.material?.fabric?.composition
                : issuedListItem?.material?.trim?.description}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-start gap-2 text-sm">
          <span>{t("material.issued.list_item.expected_delivery_date")}: </span>
          <span>{formatDateToDDMMYYYY(issuedListItem?.inHouseDate)}</span>
        </div>
      </div>

      <div className="flex justify-between items-center border border-gray-300 rounded-lg p-4 bg-gray-50">
        <div className="flex-1 text-center">
          <div className="font-semibold">
            {t("material.issued.list_item.total_ordered_quantity")}
          </div>
          <div>
            {receivedQuantity?.toFixed(2)} {issuedListItem?.unit?.name}
          </div>
        </div>
        <div className="flex-1 text-center">
          <div className="font-semibold">
            {t("material.issued.list_item.issued_quantity")}
          </div>
          <div>
            {issuedQuantity?.toFixed(2)} {issuedListItem?.unit?.name}
          </div>
        </div>
        <div className="flex-1 text-center">
          <div className="font-semibold">
            {t("material.issued.list_item.pending_quantity")}
          </div>
          <div>
            {toBeIssued?.toFixed(2)} {issuedListItem?.unit?.name}
          </div>
        </div>
      </div>

      <div
        className="flex flex-row-reverse font-semibold cursor-pointer"
        style={{ color: palette?.secondary }}
        onClick={showModal}
      >
        {t("material.issued.list_item.view_history")}
      </div>
      <RolePermission module="MATERIAL_ISSUE" action="EDIT">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleError}
        >
          <Row gutter={16}>
            <Col span={8}>
              <CustomDropdown
                label={t("material.issued.list_item.department")}
                placeholder={t("material.issued.list_item.select_department")}
                fetchItems={getDepartments}
                onSelect={(item) =>
                  console.log(
                    t("material.issued.list_item.selected_department"),
                    item
                  )
                }
                name="department"
                required={true}
                value={null}
              />
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("material.issued.list_item.issued_quantity")}
                name="issuedQuantity"
                rules={[
                  {
                    required: true,
                    message: t(
                      "material.issued.list_item.messages.quantity_required"
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "material.issued.list_item.issued_quantity_placeholder"
                  )}
                  addonAfter={issuedListItem?.unit?.name}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("material.issued.list_item.issue_date")}
                name="issueDate"
                rules={[
                  {
                    required: true,
                    message: t(
                      "material.issued.list_item.messages.date_required"
                    ),
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  placeholder={t(
                    "material.issued.list_item.issue_date_placeholder"
                  )}
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#d35d44",
                  borderColor: "#d35d44",
                  borderRadius: "1rem",
                  width: "100%",
                }}
              >
                {t("material.issued.list_item.save")}
              </Button>
            </Col>
          </Row>
        </Form>
      </RolePermission>
      {issuedListItem?.materialReceivable?.history && (
        <HistoryModal
          visible={isModalVisible}
          onClose={closeModal}
          history={
            issuedListItem?.materialReceivable?.materialIssued?.history! as any
          }
          type={materialStatusType as MaterialStatusType}
        />
      )}
    </div>
  );
};

export default IssuedListItem;

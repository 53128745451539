import React from 'react'
interface INoDataAvailableProps {
  text?: string;
};

const NoDataAvailable = ({text} :INoDataAvailableProps) => {
  return (
    <div className='flex justify-center items-center flex-col gap-4'>
        <img src={"/svg/Empty.svg"} className='w-[15rem] h-[15rem]' />
        <p className='font-semibold'>{text ?? "No Data Found"} </p>
    </div>
  )
}

export default NoDataAvailable
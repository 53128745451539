import React from "react";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";

const { Footer: AntFooter } = Layout;

const Footer: React.FC = () => {
  const { t }:any = useTranslation();

  return (
    <AntFooter>
      <div className="flex flex-col items-center space-y-4">
        <p>{t("components.footer.copy")}</p>
      </div>
    </AntFooter>
  );
};

export default Footer;
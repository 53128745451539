import { Button, Form, Input, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import NoDataAvailable from '../../../../components/NoDataAvailable';
import CustomDropdownWithAdd from '../../../../components/CustomDropdownWithAdd';
import { createTax, getTaxes } from '../../../../services/orderServiceApi/taxesApi';
import palette from '../../../../constants/pallete';
import { useTranslation } from 'react-i18next';
import { createItemWrapper, roundNumber } from '../../../../utilities/helper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { ISingleSalesOrderResponse } from '../../../../types/groyyo_plus/salesOrder';
import SelectItemFromModal from '../create/itemDetail/SelectItemFromModal';
import { createSalesOrderItems, updateOrderItem } from '../../../../services/orderServiceApi/salesOrderItemApi';
import { sourcingRoute } from '../../../../routes';
import { IoChevronBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const EditItemDetail = () => {
    const [itemDetails, setItemDetails] = useState<ISingleSalesOrderResponse['salesOrderItems']>([]);
    const { t }: any = useTranslation();
    const [existingForm] = Form.useForm();
    const [newItemsForm] = Form.useForm();
    const navigate = useNavigate();
    const singleSalesOrder = useSelector(
        (state: RootState) => state.singleSalesOrder as ISingleSalesOrderResponse | null
    );
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (singleSalesOrder?.salesOrderItems) {
            setItemDetails(
                singleSalesOrder.salesOrderItems.map((item) => ({
                    ...item,
                    isNew: false,
                }))
            );
        }
    }, [singleSalesOrder]);

    const navigateToSingleOrderPage = () => navigate(`${sourcingRoute}/sales-order/${+singleSalesOrder?.id!}`)

    const calculateAmountWithTax = (quantity: number, rate: number, taxPercentage: number) => {
        const baseTotal = quantity * rate;
        return baseTotal + (baseTotal * (taxPercentage / 100));
    };

    const handleInputChange = (id: number, field: string, value: any) => {
        setItemDetails((prevDetails) =>
            prevDetails.map((item) => {
                if (item.id === id) {
                    const updatedItem = {
                        ...item,
                        [field]: value,
                    };

                    const quantity = updatedItem.quantity || 0;
                    const rate = updatedItem.rate || 0;
                    const taxPercentage = updatedItem.tax?.percentage || 0;

                    updatedItem.amount = calculateAmountWithTax(quantity, rate, taxPercentage);

                    return updatedItem;
                }
                return item;
            })
        );
    };

    const handleTaxSelection = (id: number, tax: any, formInstance: any) => {
        setItemDetails((prevDetails) =>
            prevDetails.map((item) => {
                if (item.id === id) {
                    const updatedItem = {
                        ...item,
                        tax: {
                            id: tax.id,
                            name: tax.name,
                            percentage: tax.percentage,
                            desc: tax.desc || null,
                            factoryId: tax.factoryId || null,
                        },
                    };

                    const quantity = updatedItem.quantity || 0;
                    const rate = updatedItem.rate || 0;
                    const taxPercentage = updatedItem.tax.percentage || 0;

                    updatedItem.amount = calculateAmountWithTax(quantity, rate, taxPercentage);

                    return updatedItem;
                }
                return item;
            })
        );


        formInstance.setFieldsValue({
            [`tax_${id}`]: tax.id,
        });
    };

    const handleAddToItemList = (newItem: any) => {
        const isDuplicate = itemDetails.some((item) => item.id === newItem.id);

        if (isDuplicate) {
            message.warning(t('sales_order.updation.item_details.messages.duplicate_item_warning'));
            return;
        }

        setItemDetails((prevDetails) => [
            ...prevDetails,
            {
                ...newItem,
                quantity: 0,
                rate: 0,
                amount: 0,
                tax: undefined,
                item: { imageUrl: newItem?.imageUrl },
                isNew: true,
            },
        ]);
    };

    const handleUpdateItem = async (id: number) => {
        const updatedItem = itemDetails.find((item) => item.id === id);
        if (updatedItem) {
            const requestBody = {
                id: updatedItem.id,
                itemId: updatedItem.item.id,
                quantity: updatedItem.quantity,
                rate: updatedItem.rate,
                taxId: updatedItem.tax?.id,
                amount: updatedItem.amount,
            };

            try {
                const response = await updateOrderItem(requestBody);
                const responseData = response?.data;

                if (responseData?.status) {
                    message.success(t('sales_order.updation.item_details.messages.update_success'));
                } else {
                    message.error(t('sales_order.updation.item_details.messages.update_fail'));
                }
            } catch (error) {
                console.error(`Failed to update item with ID: ${id}`, error);
                message.error(t('sales_order.updation.item_details.messages.update_error'));
            }
        }
    };

    const handleSaveNewItems = async () => {
        try {
            await newItemsForm.validateFields();

            const newItems = itemDetails.filter((item) => item.isNew);

            const requestBody = newItems.map((item) => ({
                itemId: item.id,
                quantity: item.quantity,
                rate: item.rate,
                taxId: item.tax?.id,
                amount: item.amount,
                salesOrderId: singleSalesOrder?.id,
            }));

            try {

                const response = await createSalesOrderItems(requestBody);
                const responseData = response?.data;

                if (responseData?.status) {
                    message.success(t('sales_order.updation.item_details.messages.save_success'));

                    newItemsForm.resetFields();
                    setItemDetails((prevDetails) =>
                        prevDetails.filter((item) => !item.isNew)
                    );

                    navigateToSingleOrderPage()
                } else {
                    message.error(t('sales_order.updation.item_details.messages.save_fail'));
                }
            } catch (error) {
                console.error('Failed to save new items:', error);
                message.error(t('sales_order.updation.item_details.messages.save_error'));
            }
        } catch (validationError) {
            console.error('Validation failed:', validationError);
            message.error(t('sales_order.updation.item_details.messages.validation_error'));
        }
    };

    return (
        <div className='flex flex-col gap-4'>
            <div className="text-lg font-bold flex flex-row gap-2 items-center">
                <span onClick={navigateToSingleOrderPage} className="cursor-pointer"><IoChevronBackOutline style={{ fontWeight: "bold" }} /></span>
                <p>{t('sales_order.updation.item_details.title')}</p>
            </div>
            <div className='bg-white p-2 rounded-lg'>

                {
                    itemDetails && itemDetails.length > 0 ? (
                        <>
                            <div
                                className="rounded-md m-2 flex gap-4 flex-col"
                            >
                                {itemDetails
                                    .filter((item) => !item.isNew)
                                    .map((item) => (
                                        <Form layout="vertical" form={existingForm} key={item.id} className="border rounded-md p-2">
                                            <div className="flex flex-row items-center justify-between p-2">
                                                <div className="flex flex-row gap-4">
                                                    <img
                                                        src={item?.item?.imageUrl || '/svg/NoImage.svg'}
                                                        alt={item?.item?.name}
                                                        className="!w-[3rem] !h-[3rem] rounded-md"
                                                    />
                                                    <div className="flex flex-col justify-start">
                                                        <p className="font-semibold text-lg">{item?.item?.name}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="font-bold">{t('sales_order.updation.item_details.fields.total')}</p>
                                                    <span className="flex flex-row gap-2">
                                                        <p className="italic">{roundNumber(item.amount ?? 0)}</p>
                                                        {singleSalesOrder?.currency?.code}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="flex flex-row space-x-4 items-center p-2">
                                                <Form.Item
                                                    label={t('sales_order.updation.item_details.fields.quantity')}
                                                    className="flex-1"
                                                >
                                                    <Input
                                                        autoComplete="off"
                                                        type="number"
                                                        placeholder={t('sales_order.updation.item_details.placeholders.quantity')}
                                                        value={item.quantity}
                                                        onChange={(e) =>
                                                            handleInputChange(item.id, 'quantity', parseFloat(e.target.value))
                                                        }
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label={t('sales_order.updation.item_details.fields.rate')}
                                                    className="flex-1"
                                                >
                                                    <Input
                                                        autoComplete="off"
                                                        type="number"
                                                        placeholder={t('sales_order.updation.item_details.placeholders.rate')}
                                                        value={item.rate || ''}
                                                        onChange={(e) =>
                                                            handleInputChange(item.id, 'rate', parseFloat(e.target.value))
                                                        }
                                                    />
                                                </Form.Item>

                                                <div className="flex-1">
                                                    <CustomDropdownWithAdd
                                                        form={existingForm}
                                                        label={t('sales_order.updation.item_details.fields.tax')}
                                                        placeholder={t('sales_order.updation.item_details.placeholders.tax')}
                                                        fetchItems={getTaxes}
                                                        addItem={createItemWrapper(createTax)}
                                                        onSelect={(tax) => handleTaxSelection(item.id, tax, existingForm)}
                                                        modalFields={[
                                                            {
                                                                name: 'name',
                                                                label: t('sales_order.updation.item_details.fields.tax_name'),
                                                                required: true,
                                                            },
                                                            {
                                                                name: 'desc',
                                                                label: t('sales_order.updation.item_details.fields.tax_desc'),
                                                                required: true,
                                                            },
                                                            {
                                                                name: 'percentage',
                                                                label: t('sales_order.updation.item_details.fields.tax_percentage'),
                                                                required: true,
                                                                type: 'number',
                                                                endIcon: '%',
                                                            },
                                                        ]}
                                                        formItemName={`tax_${item.id}`}
                                                        required={true}
                                                        value={existingForm.getFieldValue(`tax_${item.id}`) || item?.tax?.id}
                                                    />
                                                </div>
                                            </div>
                                            <Button
                                                type="primary"
                                                onClick={() => handleUpdateItem(item.id)}
                                                style={{ backgroundColor: palette.secondary, marginTop: '8px', width: "100%" }}
                                            >
                                                {t('sales_order.updation.item_details.buttons.update')}
                                            </Button>
                                        </Form>
                                    ))}

                                {itemDetails.filter((item) => item.isNew).length > 0 && (
                                    <h3 style={{ marginTop: '16px', marginBottom: '8px', color: palette.secondary }}>
                                        {t('sales_order.updation.item_details.headers.new_items')}
                                    </h3>
                                )}

                                {itemDetails
                                    .filter((item) => item.isNew)
                                    .map((item) => (
                                        <Form layout="vertical" form={newItemsForm} key={item.id} className="border rounded-md p-2">
                                            <div className="flex flex-row items-center justify-between p-2">
                                                <div className="flex flex-row gap-4">
                                                    <img
                                                        src={item?.item?.imageUrl || '/svg/NoImage.svg'}
                                                        alt={item?.item?.name}
                                                        className="!w-[3rem] !h-[3rem] rounded-md"
                                                    />
                                                    <div className="flex flex-col justify-start">
                                                        <p className="font-semibold text-lg">{item?.item?.name}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="font-bold">{t('sales_order.updation.item_details.fields.total')}</p>
                                                    <span className="flex flex-row gap-2">
                                                        <p className="italic">{roundNumber(item.amount ?? 0)}</p>
                                                        {singleSalesOrder?.currency?.code}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="flex flex-row space-x-4 items-center p-2">
                                                <Form.Item
                                                    label={t('sales_order.updation.item_details.fields.quantity')}
                                                    className="flex-1"
                                                    rules={[
                                                        { required: true, message: t('sales_order.updation.item_details.errors.quantity_required') },
                                                        { type: 'number', min: 1, message: t('sales_order.updation.item_details.errors.quantity_min') },
                                                    ]}
                                                >
                                                    <Input
                                                        autoComplete="off"
                                                        type="number"
                                                        placeholder={t('sales_order.updation.item_details.placeholders.quantity')}
                                                        value={item.quantity}
                                                        onChange={(e) =>
                                                            handleInputChange(item.id, 'quantity', parseFloat(e.target.value))
                                                        }
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label={t('sales_order.updation.item_details.fields.rate')}
                                                    className="flex-1"
                                                    rules={[
                                                        { required: true, message: t('sales_order.updation.item_details.errors.rate_required') },
                                                        { type: 'number', min: 0, message: t('sales_order.updation.item_details.errors.rate_min') },
                                                    ]}
                                                >
                                                    <Input
                                                        autoComplete="off"
                                                        type="number"
                                                        placeholder={t('sales_order.updation.item_details.placeholders.rate')}
                                                        value={item.rate}
                                                        onChange={(e) =>
                                                            handleInputChange(item.id, 'rate', parseFloat(e.target.value))
                                                        }
                                                    />
                                                </Form.Item>

                                                <div className="flex-1">
                                                    <CustomDropdownWithAdd
                                                        form={newItemsForm}
                                                        label={t('sales_order.updation.item_details.fields.tax')}
                                                        placeholder={t('sales_order.updation.item_details.placeholders.tax')}
                                                        fetchItems={getTaxes}
                                                        addItem={createItemWrapper(createTax)}
                                                        onSelect={(tax) => handleTaxSelection(item.id, tax, newItemsForm)}
                                                        modalFields={[
                                                            {
                                                                name: 'name',
                                                                label: t('sales_order.updation.item_details.fields.tax_name'),
                                                                required: true,
                                                            },
                                                            {
                                                                name: 'desc',
                                                                label: t('sales_order.updation.item_details.fields.tax_desc'),
                                                                required: true,
                                                            },
                                                            {
                                                                name: 'percentage',
                                                                label: t('sales_order.updation.item_details.fields.tax_percentage'),
                                                                required: true,
                                                                type: 'number',
                                                                endIcon: '%',
                                                            },
                                                        ]}
                                                        formItemName={`tax_${item.id}`}
                                                        required={true}
                                                        value={newItemsForm.getFieldValue(`tax_${item.id}`) || item?.tax?.id}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    ))}
                                {itemDetails.filter((item) => item.isNew).length > 0 && (
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            onClick={handleSaveNewItems}
                                            style={{ backgroundColor: palette.secondary, width: '100%' }}
                                        >
                                            {t('sales_order.updation.item_details.buttons.save')}
                                        </Button>
                                    </Form.Item>
                                )}
                            </div>
                        </>
                    ) : (
                        <NoDataAvailable />
                    )}
                <SelectItemFromModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    addToItemList={handleAddToItemList}
                />
            </div>
        </div>
    );
};

export default EditItemDetail;
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import { en, hi } from './Languages';

declare module 'react-i18next' {
  interface Resources {
    en: typeof en;
    hi: typeof hi;
  }
}

const userLanguage = localStorage.getItem('language') || 'en';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: userLanguage,
  interpolation: {
    escapeValue: false,
  },
  react: {useSuspense: false},
  resources: {
    en: {
      translation: en,
    },
    hi: {
      translation: hi,
    },
  },
});

export default i18n;

export const LANG: {
  title: string;
  code: string;
}[] = [
  {
    title: 'English',
    code: 'en',
  },
  {
    title: 'हिंदी (Hindi)',
    code: 'hi',
  },
];
import React, { useEffect, useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import palette from "../../../constants/pallete";
import { formatDateToDDMMYYYY } from "../../../utilities/helper";
import DispatchHistory from "./DispatchHistory";
import { Button, message, Modal } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  checkMarkAsDelivered,
  markAsCompleted,
} from "../../../services/orderServiceApi/salesOrderItemApi";
import { useTranslation } from "react-i18next";
import RolePermission from "../../../utilities/RolePermission/RolePermission";

const Dispatch = () => {
  const { t }: any = useTranslation();
  const navigate = useNavigate();
  const [isHistoryModalVisible, setIsHistoryModalVisible] =
    useState<boolean>(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    useState<boolean>(false);
  const [remainingOrders, setRemainingOrders] = useState<number | null>(null);
  const { dispatchId, salesOrderItemId } = useParams();
  const selectedDispatch = useSelector(
    (state: RootState) => state.dispatch.selectedDispatch
  );

  useEffect(() => {
    if (!selectedDispatch?.id) {
      navigate(`/dispatch`);
    }
  }, [selectedDispatch, navigate]);

  if (!selectedDispatch) {
    return (
      <div className="flex justify-center items-center flex-col gap-4">
        <img
          src="/svg/Empty.svg"
          alt="Empty"
          className="w-60 h-60 opacity-50"
        />
        <p className="text-lg font-semibold text-gray-500">
          {t("dispatch.no_data")}
        </p>
      </div>
    );
  }

  const { salesOrderItem } = selectedDispatch;
  const { salesOrder } = salesOrderItem;

  const showHistoryModal = () => setIsHistoryModalVisible(true);
  const closeHistoryModal = () => setIsHistoryModalVisible(false);

  const handleStartInspection = () => {
    navigate(`/dispatch/start-inspection/${dispatchId}/${+salesOrderItemId!}`);
  };

  const handleStartDispatch = () => {
    navigate(`/dispatch/start-dispatch/${dispatchId}/${+salesOrderItemId!}`);
  };

  const markDelivery = async () => {
    try {
      const response = await markAsCompleted({
        salesOrderItemId: +salesOrderItemId!,
      });
      const responseData = response?.data;

      if (responseData?.status) {
        message.success(t("dispatch.messages.marked_success"));
        navigate("/dispatch");
      } else {
        message.error(t("dispatch.messages.marked_failed"));
      }
    } catch (error) {
      console.error("Error marking order as delivered:", error);
      message.error(t("dispatch.messages.delivery_error"));
    }
  };

  const handleMarkAsDelivered = async () => {
    try {
      const response = await checkMarkAsDelivered({
        salesOrderItemId: +salesOrderItemId!,
      });
      const responseData = response?.data;

      if (responseData?.status) {
        if (responseData?.canBeDelivered) {
          await markDelivery();
        } else {
          setRemainingOrders(responseData.remainingOrders || 0);
          setIsConfirmModalVisible(true);
        }
      } else {
        message.error(t("dispatch.messages.fetch_status_failed"));
      }
    } catch (error) {
      console.error("Error checking delivery status:", error);
      message.error(t("dispatch.messages.status_error"));
    }
  };

  const handleMarkAsDeliveredCancel = () => {
    setIsConfirmModalVisible(false);
  };

  const handleMarkAsDeliveredConfirm = async () => {
    await markDelivery();
    setIsConfirmModalVisible(false);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="text-lg font-bold flex flex-row gap-2 items-center">
        <span onClick={() => navigate(`/dispatch`)} className="cursor-pointer">
          <IoChevronBackOutline style={{ fontWeight: "bold" }} />
        </span>
        <p>{t("dispatch.title")}</p>
      </div>
      <div className="p-6 bg-white rounded-lg shadow-md">
        <div
          className="flex justify-between items-center px-6 py-4 border rounded-lg shadow-sm mb-6"
          style={{ backgroundColor: palette.secondary }}
        >
          <div className="flex flex-col">
            <span className="font-bold text-lg text-white">
              {t("dispatch.so_no")}
            </span>
            <span className="text-white font-semibold">{salesOrder.soNo}</span>
          </div>
          <div className="flex flex-col">
            <span className="font-bold text-lg text-white">
              {t("dispatch.buyer")}
            </span>
            <span className="text-white font-semibold">
              {salesOrder.buyer.name}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="font-bold text-lg text-white">
              {t("dispatch.so_date")}
            </span>
            <span className="text-white font-semibold">
              {formatDateToDDMMYYYY(salesOrder.soDate)}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="font-bold text-lg text-white">
              {t("dispatch.expected_delivery")}
            </span>
            <span className="text-white font-semibold">
              {formatDateToDDMMYYYY(salesOrder.expectedDate)}
            </span>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className="bg-white rounded-lg shadow-sm p-4 text-center border">
            <div className="font-semibold text-gray-800">
              {t("dispatch.ready_for_dispatch")}
            </div>
            <div className="text-lg">{selectedDispatch?.availableQuantity}</div>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-4 text-center border">
            <div className="font-semibold text-gray-800">
              {t("dispatch.dispatched")}
            </div>
            <div className="text-lg">
              {selectedDispatch?.dispatchedQuantity}
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-4 text-center border">
            <div className="font-semibold text-gray-800">
              {t("dispatch.overage")}
            </div>
            <div className="text-lg">0</div>
          </div>
        </div>

        <div
          className="flex flex-row-reverse font-semibold py-6"
          style={{ color: palette?.secondary }}
        >
          <p className="cursor-pointer" onClick={showHistoryModal}>
            {t("dispatch.view_history")}
          </p>
        </div>
        <RolePermission module="DISPATCH" action="CREATE">
          <div className="flex flex-row gap-4">
            <Button
              type="primary"
              style={{
                backgroundColor: palette.secondary,
                width: "100%",
                padding: "1.5rem",
                fontWeight: "bold",
              }}
              onClick={handleStartInspection}
            >
              {t("dispatch.start_inspection")}
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: palette.secondary,
                width: "100%",
                padding: "1.5rem",
                fontWeight: "bold",
              }}
              onClick={handleStartDispatch}
            >
              {t("dispatch.dispatch")}
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: palette.secondary,
                width: "100%",
                padding: "1.5rem",
                fontWeight: "bold",
              }}
              onClick={handleMarkAsDelivered}
            >
              {t("dispatch.mark_as_delivered")}
            </Button>
          </div>
        </RolePermission>
        <DispatchHistory
          visible={isHistoryModalVisible}
          salesOrderItemId={salesOrderItem?.id}
          onClose={closeHistoryModal}
        />
      </div>

      <Modal
        title={t("dispatch.modal.title")}
        open={isConfirmModalVisible}
        onCancel={handleMarkAsDeliveredCancel}
        footer={[
          <Button key="cancel" onClick={handleMarkAsDeliveredCancel}>
            {t("dispatch.modal.cancel")}
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={handleMarkAsDeliveredConfirm}
            style={{ backgroundColor: "teal", borderColor: "teal" }}
          >
            {t("dispatch.modal.confirm")}
          </Button>,
        ]}
      >
        <p>{t("dispatch.modal.message", { remainingOrders })}</p>
      </Modal>
    </div>
  );
};

export default Dispatch;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Switch, Form, message, Input, Modal } from 'antd';
import { IoTrashOutline, IoChevronBackOutline } from 'react-icons/io5';
import { PlusOutlined } from '@ant-design/icons';
import palette from '../../../constants/pallete';
import { createTnaTemplates } from '../../../services/orderServiceApi/tnaApi';
import CustomDropdownWithAdd from '../../../components/CustomDropdownWithAdd';
import { createItemWrapper } from '../../../utilities/helper';
import { getTnaProcess, createTnaProcess } from '../../../services/orderServiceApi/tnaApi';
import {
    DndContext,
    closestCenter,
    useSensor,
    useSensors,
    PointerSensor,
    KeyboardSensor,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from '../../../components/SortableItem';
import { useTranslation } from 'react-i18next';

interface Process {
    id: number;
    isManualEntry: boolean;
    process?: { id: number; name: string };
    dependencyOn?: { id: number; name: string };
}

const TNATemplateCreate: React.FC = () => {
    const { t }: any = useTranslation();
    const [processes, setProcesses] = useState<Process[]>([{ id: Date.now(), isManualEntry: true }]);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dragProcesses, setDragProcesses] = useState<Process[]>([]);

    const handleAddProcess = () => {
        setProcesses((prev) => [...prev, { id: Date.now(), isManualEntry: true }]);
    };

    const handleRemoveProcess = (processId: number) => {
        if (processes.length === 1) {
            message.warning(t('tnaPage.create_tna_template.errors.at_least_one_process'));
            return;
        }
        setProcesses((prev) => prev.filter((process) => process.id !== processId));
    };

    const handleManualEntryChange = (processId: number, checked: boolean) => {
        setProcesses((prev) =>
            prev.map((process) =>
                process.id === processId ? { ...process, isManualEntry: checked } : process
            )
        );
    };

    const handleUpdateProcess = (processId: number, updatedData: Partial<Process>) => {
        setProcesses((prev) =>
            prev.map((process) =>
                process.id === processId ? { ...process, ...updatedData } : process
            )
        );
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = ({ active, over }: any) => {
        if (active.id !== over.id) {
            const oldIndex = dragProcesses.findIndex((process) => String(process.id) === active.id);
            const newIndex = dragProcesses.findIndex((process) => String(process.id) === over.id);

            const updatedProcesses = arrayMove(dragProcesses, oldIndex, newIndex).map(
                (process, index) => ({
                    ...process,
                    sequence: index + 1,
                })
            );
            setDragProcesses(updatedProcesses);
        }
    };

    const openRearrangeProcess = async () => {
        try {
            await form.validateFields();

            setDragProcesses([...processes]);
            setIsModalOpen(true);
        } catch (error) {
            console.error('Validation errors:', error);
            message.error(t('tnaPage.create_tna_template.errors.validation_errors'));
        }
    };

    const handleSaveTemplate = async () => {
        try {
            const values = form.getFieldsValue();
            const templateName = values.templateName;
            const tnaProcess = dragProcesses.map((process, index) => ({
                processId: process.process?.id,
                dependentProcessId: process.dependencyOn?.id || null,
                sequence: index + 1,
                isManualEntry: process.isManualEntry,
            }));

            const payload = {
                name: templateName,
                tnaProcess,
            };

            const response = await createTnaTemplates(payload);
            const responseData = response?.data;

            if (responseData?.status) {
                message.success(t('tnaPage.create_tna_template.errors.save_success'));
                navigate('/tna');
                form.resetFields();
            }
        } catch (error: any) {
            console.error('Error saving template:', error);
            message.error(t('tnaPage.create_tna_template.errors.save_failed'));
        }
    };

    return (
        <div className="">
            <div className="text-lg font-bold flex flex-row gap-2 items-center mb-4">
                <span onClick={() => navigate(`/tna`)} className="cursor-pointer">
                    <IoChevronBackOutline style={{ fontWeight: 'bold' }} />
                </span>
                <p>{t('tnaPage.create_tna_template.title')}</p>
            </div>

            <Form form={form} layout="vertical" autoComplete="off">
                <Form.Item
                    name="templateName"
                    label={t('tnaPage.create_tna_template.template_name')}
                    rules={[{ required: true, message: t('tnaPage.create_tna_template.errors.template_name_required') }]}
                >
                    <Input placeholder={t('tnaPage.create_tna_template.template_name_placeholder')} />
                </Form.Item>

                <div className="">
                    {processes.map((process, index) => (
                        <div
                            key={process.id}
                            className="p-4 border rounded-lg mb-4 bg-white shadow-sm"
                        >
                            <div className="flex justify-between items-center mb-4">
                                <h3 className="font-bold">
                                    {t('tnaPage.create_tna_template.process')} {index + 1}
                                </h3>
                                <IoTrashOutline
                                    className="text-xl text-red-500 cursor-pointer"
                                    onClick={() => handleRemoveProcess(process.id)}
                                />
                            </div>

                            <CustomDropdownWithAdd
                                form={form}
                                label={t('tnaPage.create_tna_template.process_name')}
                                placeholder={t('tnaPage.create_tna_template.process_name_placeholder')}
                                fetchItems={getTnaProcess}
                                addItem={createItemWrapper(createTnaProcess)}
                                onSelect={(item) =>
                                    handleUpdateProcess(process.id, {
                                        process: {
                                            id: item.id,
                                            name: item.name,
                                        },
                                    })
                                }
                                modalFields={[
                                    { name: 'name', label: 'Name', required: true },
                                ]}
                                formItemName={`processName_${process.id}`}
                                required
                                value={process.process?.name}
                            />

                            <CustomDropdownWithAdd
                                form={form}
                                label={t('tnaPage.create_tna_template.dependency_on')}
                                placeholder={t('tnaPage.create_tna_template.dependency_on_placeholder')}
                                fetchItems={getTnaProcess}
                                addItem={createItemWrapper(createTnaProcess)}
                                onSelect={(item) =>
                                    handleUpdateProcess(process.id, {
                                        dependencyOn: {
                                            id: item.id,
                                            name: item.name,
                                        },
                                    })
                                }
                                modalFields={[
                                    { name: 'name', label: 'Name', required: true },
                                ]}
                                formItemName={`dependencyOn_${process.id}`}
                                required={false}
                                value={process.dependencyOn?.name}
                            />

                            <div className="flex items-center gap-2 mt-4">
                                <span>{t('tnaPage.create_tna_template.manual_entry')}</span>
                                <Switch
                                    checked={process.isManualEntry}
                                    onChange={(checked) => handleManualEntryChange(process.id, checked)}
                                    style={{
                                        backgroundColor: process.isManualEntry ? palette.secondary : '#d9d9d9',
                                    }}
                                />
                            </div>
                        </div>
                    ))}

                    <Button
                        type="dashed"
                        block
                        icon={<PlusOutlined />}
                        onClick={handleAddProcess}
                        style={{
                            color: palette.secondary,
                        }}
                    >
                        {t('tnaPage.create_tna_template.add_process')}
                    </Button>

                    <Button
                        type="primary"
                        block
                        className="mt-4"
                        onClick={openRearrangeProcess}
                        style={{
                            backgroundColor: palette.secondary,
                        }}
                    >
                        {t('tnaPage.create_tna_template.save')}
                    </Button>
                </div>
            </Form>

            <Modal
                title={t('tnaPage.create_tna_template.rearrange_processes')}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onOk={handleSaveTemplate}
                okText={t('tnaPage.create_tna_template.save')}
            >
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={dragProcesses.map((process) => String(process.id))}
                        strategy={verticalListSortingStrategy}
                    >
                        <div>
                            {dragProcesses.map((process, index) => (
                                <SortableItem
                                    key={process.id}
                                    process={process}
                                    index={index}
                                    getProcessName={(proc) => proc.process?.name || t('tnaPage.create_tna_template.process')}
                                    getDependencyName={(proc) => proc.dependencyOn?.name || t('tnaPage.create_tna_template.dependency_on')}
                                />
                            ))}
                        </div>
                    </SortableContext>
                </DndContext>
            </Modal>
        </div>
    );
};

export default TNATemplateCreate;
import { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Card,
    Statistic,
    Typography,
    Progress,
    List,
    Skeleton,
} from 'antd';
import {
    ShoppingOutlined,
    SkinOutlined
} from '@ant-design/icons';
import {
    getDasboardOrderDetail,
    getDashboardDelayedMaterials,
    getDashboardDelayedOrders
} from '../../../services/orderServiceApi/dashboardApi';
import { IDelayedMaterial, IDelayedOrder } from '../../../types/groyyo_plus/dashboard';
import SVGComponent from '../../../components/SVGComponent';
import DashboardFilter from './DashboardFilter';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { convertNumber, roundNumber } from '../../../utilities/helper';

const { Title } = Typography;

const Home = () => {
    const {t}: any = useTranslation();
    const [dashboardData, setDashboardData] = useState({
        totalStyles: 0,
        totalQuantity: 0,
        completedStyles: 0,
        dispatchedQuantity: 0,
        averageDeliveryDays: 0,
        totalValues: 0,
        completionRate: 0,
        deliveryRate: 0,
    });
    const [delayedMaterials, setDelayedMaterials] = useState<IDelayedMaterial[]>([]);
    const [delayedOrders, setDelayedOrders] = useState<IDelayedOrder[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState({});


    const fetchDashboardDetails = async (flts: any) => {
        try {
            setIsLoading(true);

            const [dashboardResponse, delayedMaterialsResponse, delayedOrdersResponse] = await Promise.all([
                getDasboardOrderDetail(flts),
                getDashboardDelayedMaterials(),
                getDashboardDelayedOrders(),
            ]);

            if (dashboardResponse?.data?.status) {
                const result = dashboardResponse?.data?.result || {};
                setDashboardData({
                    totalStyles: result.totalStyles || 0,
                    totalQuantity: result.totalQuantity || 0,
                    completedStyles: result.completedStyles || 0,
                    dispatchedQuantity: result.dispatchedQuantity || 0,
                    averageDeliveryDays: result.averageDeliveryDays || 0,
                    totalValues: result.totalValues || 0,
                    completionRate: result.completionRate || 0,
                    deliveryRate: result.deliveryRate || 0,
                });
            }

            if (delayedMaterialsResponse?.data?.status) {
                setDelayedMaterials(delayedMaterialsResponse?.data?.result || []);
            }

            if (delayedOrdersResponse?.data?.status) {
                setDelayedOrders(delayedOrdersResponse?.data?.result || []);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const initialFilter = {
            startDate: dayjs().startOf('month').format('DD/MM/YYYY'),
            endDate: dayjs().endOf('month').format('DD/MM/YYYY'),
        }
        fetchDashboardDetails(initialFilter);
    }, []);

    const handleApplyFilters = async (appliedFilters: {
        salesOrderId?: number;
        salesOrderItemId?: number;
        buyerId?: number;
        productTypeId?: number;
        startDate?: string;
        endDate?: string;
      }) => {
        setFilters(appliedFilters);

        fetchDashboardDetails(appliedFilters);
      };

    const handleClearFilters = () => {
        setFilters({
            startDate: dayjs().startOf('month').format('DD/MM/YYYY'),
            endDate: dayjs().endOf('month').format('DD/MM/YYYY'),
        });
    };

    return (
        <div style={{ padding: '24px' }}>
            <div className='flex flex-row justify-between'>
                <Title level={2} style={{ marginBottom: '24px' }}>
                    {t('home.dashboard_title')}
                </Title>

                <DashboardFilter onApply={handleApplyFilters} onClear={handleClearFilters}  />

            </div>
            <Row gutter={[16, 16]}>
                {isLoading ? (
                    Array.from({ length: 4 }).map((_, index) => (
                        <Col xs={24} sm={12} md={8} lg={6} key={index}>
                            <Card>
                                <Skeleton active />
                            </Card>
                        </Col>
                    ))
                ) : (
                    <>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Card>
                                <Statistic
                                    title={t('home.total_styles')}
                                    value={roundNumber(dashboardData.totalStyles)}
                                    prefix={<SkinOutlined />}
                                    valueStyle={{ color: '#3f8600' }}
                                />
                            </Card>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Card>
                                <Statistic
                                    title={t('home.total_quantity')}
                                    value={convertNumber(dashboardData.totalQuantity)}
                                    prefix={<ShoppingOutlined />}
                                    valueStyle={{ color: '#1890ff' }}
                                />
                            </Card>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Card>
                                <Statistic
                                    title={t('home.avg_delivery_days')}
                                    value={roundNumber(dashboardData.averageDeliveryDays)}
                                    suffix="days"
                                    valueStyle={{ color: '#cf1322' }}
                                />
                            </Card>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Card>
                                <Statistic
                                    title={t('home.total_values')}
                                    value={convertNumber(dashboardData.totalValues)}
                                    prefix="₹"
                                    valueStyle={{ color: '#9254de' }}
                                />
                            </Card>
                        </Col>
                    </>
                )}

                {isLoading ? (
                    Array.from({ length: 2 }).map((_, index) => (
                        <Col xs={24} sm={12} md={12} key={index}>
                            <Card>
                                <Skeleton active paragraph={{ rows: 2 }} />
                            </Card>
                        </Col>
                    ))
                ) : (
                    <>
                        <Col xs={24} sm={12} md={12}>
                            <Card title={t('home.completion_progress')}>
                                <Progress
                                    percent={dashboardData.completionRate}
                                    status="active"
                                    strokeColor={{
                                        '0%': '#108ee9',
                                        '100%': '#87d068',
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: '8px',
                                    }}
                                >
                                    <span>{t('home.completed_styles')}: {dashboardData.completedStyles}</span>
                                    <span>{t('home.total_styles')}: {dashboardData.totalStyles}</span>
                                </div>
                            </Card>
                        </Col>

                        <Col xs={24} sm={12} md={12}>
                            <Card title={t('home.dispatch_progress')}>
                                <Progress
                                    percent={dashboardData.deliveryRate}
                                    status="active"
                                    strokeColor={{
                                        '0%': '#fa8c16',
                                        '100%': '#52c41a',
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: '8px',
                                    }}
                                >
                                    <span>{t('home.dispatched_quantity')}: {dashboardData.dispatchedQuantity}</span>
                                    <span>{t('home.total_quantity')}: {dashboardData.totalQuantity}</span>
                                </div>
                            </Card>
                        </Col>
                    </>
                )}
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
                <Col xs={24} md={12}>
                    <Card title={t('home.delayed_materials')}>
                        {isLoading ? (
                            <Skeleton active paragraph={{ rows: 6 }} />
                        ) : delayedMaterials.length > 0 ? (
                            <List
                                style={{ overflowY: 'scroll', height: '50vh' }}
                                dataSource={delayedMaterials}
                                renderItem={(item) => (
                                    <List.Item>
                                        <div className="flex w-full gap-2 items-center">
                                            <SVGComponent
                                                src="/svg/Package.svg"
                                                fillColor="#6A4CCC"
                                                width="2rem"
                                                height="2rem"
                                            />
                                            <List.Item.Meta
                                                title={
                                                    <div className="flex flex-row gap-2 font-semibold">
                                                        <span>{item?.material?.fabric?.name}</span>
                                                        <span>({item?.salesOrderItem?.salesOrder?.soNo})</span>
                                                    </div>
                                                }
                                                description={t('delayed_message', { days: Math.abs(item?.delayInDays) })}
                                            />
                                        </div>
                                    </List.Item>
                                )}
                            />
                        ) : (
                            <span>
                                {t('home.no_delayed_materials')}
                            </span>
                        )}
                    </Card>
                </Col>

                <Col xs={24} md={12}>
                    <Card title={t('home.delayed_orders')}>
                        {isLoading ? (
                            <Skeleton active paragraph={{ rows: 6 }} />
                        ) : delayedOrders.length > 0 ? (
                            <List
                                dataSource={delayedOrders}
                                style={{ overflowY: 'scroll', height: '50vh' }}
                                renderItem={(item) => (
                                    <List.Item>
                                        <div className="flex w-full gap-2 items-center">
                                            <SVGComponent
                                                src="/svg/ClockAlterOutline.svg"
                                                fillColor="#CC6A4C"
                                                width="2rem"
                                                height="2rem"
                                            />
                                            <List.Item.Meta
                                                title={<span className="font-semibold">{item.soNo}</span>}
                                                description={t('delayed_message', { days: Math.abs(item.delayInDays) })}
                                            />
                                        </div>
                                    </List.Item>
                                )}
                            />
                        ) : (
                            <span>{t('home.no_delayed_orders')}</span>
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Home;
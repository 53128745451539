import React, { useEffect, useState } from "react";
import { Table, Space, message, Button } from "antd";
import { getUsers } from "../services/userServiceApi/authApi";
import CreateUserControl from "../components/CreateUserControl";

const UserList: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response: any = await getUsers();

      if (response?.data?.status && Array.isArray(response?.data?.result)) {
        setUsers(response.data.result);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to fetch users.");
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Mobile No", dataIndex: "mobileNo", key: "mobileNo" },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              message.info(`Details of ${record.name}`);
            }}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="p-4">
      <h2 className="text-lg font-bold mb-4">User Management</h2>
      <CreateUserControl onUserCreated={fetchUsers} />
      <Table
        columns={columns}
        dataSource={users}
        rowKey="id"
        style={{ marginTop: 20 }}
        bordered
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
};

export default UserList;
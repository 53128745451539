import { CreateDeliveryTypeDto } from "../../types/delieveryType";
import { orderApi } from "./orderService";
import { ORDER_SERVICE_API } from ".";
;

const deliveryUrl = ORDER_SERVICE_API.DELIVERY_TYPE;

export const getDeliveryTypes = async () => {
    return orderApi.get(`${deliveryUrl}`);
}

export const createDeliveryType = async (payload: CreateDeliveryTypeDto) => {
    return orderApi.post(`${deliveryUrl}`, payload);
}

export const bulkUploadDeliveryTypes = async (deliveryTypes: CreateDeliveryTypeDto[]) => {
    return orderApi.post(`${deliveryUrl}/bulk`, deliveryTypes);
};
import dayjs from "dayjs";
import { ORDER_SERVICE_API } from ".";
import { orderApi } from "./orderService";

const dashboardUrl = ORDER_SERVICE_API.DASHBOARD;

export const getDasboardOrderDetail = async (params: {
    salesOrderId?: number;
    salesOrderItemId?: number;
    buyerId?: number;
    productTypeId?: number;
    startDate?: string;
    endDate?: string;
  } = {}) => {
    const queryParams = new URLSearchParams();
  
    if (params.salesOrderId) queryParams.append('salesOrderId', String(params.salesOrderId));
    if (params.salesOrderItemId) queryParams.append('salesOrderItemId', String(params.salesOrderItemId));
    if (params.buyerId) queryParams.append('buyerId', String(params.buyerId));
    if (params.productTypeId) queryParams.append('productTypeId', String(params.productTypeId));
      
    const startDate = params.startDate || dayjs().startOf('month').format('DD/MM/YYYY');
    const endDate = params.endDate || dayjs().endOf('month').format('DD/MM/YYYY');
    queryParams.append('startDate', startDate);
    queryParams.append('endDate', endDate);
  
    const url = queryParams.toString()
      ? `${dashboardUrl}/order-details?${queryParams.toString()}`
      : `${dashboardUrl}/order-details`;
  
    return orderApi.get(url);
  };


export const getDashboardDelayedMaterials = async () => {
    return orderApi.get(`${dashboardUrl}/delayed-materials`);
};

export const getDashboardDelayedOrders = async () => {
    return orderApi.get(`${dashboardUrl}/delayed-orders`);
};
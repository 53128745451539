import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getSalesOrderItems } from '../../../services/orderServiceApi/salesOrderItemApi';
import { Skeleton } from 'antd';
import { IMaterialSalesOrderItem } from '../../../types/groyyo_plus/materialRecievable';
import { sourcingRoute } from '../../../routes';
import { IoChevronBackOutline } from "react-icons/io5"
import { MaterialStatusType } from '../../../constants/enums';
import { useTranslation } from 'react-i18next';


const MaterialSalesOrderItem = () => {
    const {t}: any = useTranslation();
    const { salesOrderId } = useParams();

    const [searchParams] = useSearchParams();
    const materialStatusType = searchParams.get("materialStatusType");

    const [loading, setLoading] = useState(false);

    const [materialSalesOrderItems, setMaterialSalesOrderItems] = useState<IMaterialSalesOrderItem[]>([]);

    const navigate = useNavigate();

    const fetchSalesOrderItem = async () => {
        setLoading(true);
        try {
            const response = await getSalesOrderItems({
                salesOrderId: +salesOrderId!,
            });
            const responseData = response?.data;

            if (responseData?.status) {
                setMaterialSalesOrderItems(responseData?.result)
            }
        } catch (error) {
            console.error(t("material.receivable.material_sales_order_item.error_fetching_items"), error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (salesOrderId) {
            fetchSalesOrderItem();
        }
    }, [salesOrderId]);

    const handleSelectItems = (bomId: number, salesOrderItemId: number) => {
        if(materialStatusType === MaterialStatusType.RECIEVED){
            navigate(`${sourcingRoute}/material-receive/bom-items/${bomId}/${salesOrderItemId}/${salesOrderId}?materialStatusType=${materialStatusType}`)
        }else if(materialStatusType === MaterialStatusType.ISSUED){
            navigate(`${sourcingRoute}/material-issued/bom-items/${bomId}/${salesOrderItemId}/${salesOrderId}?materialStatusType=${materialStatusType}`)
        }
    }

    const backToList = ()  => {
        if(materialStatusType === MaterialStatusType.RECIEVED){
            navigate(`${sourcingRoute}/material-receive`)
        }else if(materialStatusType === MaterialStatusType.ISSUED){
            navigate(`${sourcingRoute}/material-issued`)
        }
        
    }

    return (
        <div className='flex flex-col gap-2'>
            <div className="text-lg font-bold flex flex-row gap-2 items-center">
                <span onClick={backToList} className="cursor-pointer"><IoChevronBackOutline style={{fontWeight: "bold"}} /></span> 
                <p>{t("material.receivable.material_sales_order_item.page_title")}</p>
            </div>

            <div>
                {loading ? (
                    Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton.Input key={index} active style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: 80 }} />
                    ))
                ) : (
                    <>
                        {materialSalesOrderItems?.length > 0 ? (
                            materialSalesOrderItems.map((item, index) => (
                                <div 
                                    key={item.id} 
                                    className="flex flex-row items-center justify-between shadow-md p-2 rounded-lg bg-white cursor-pointer"
                                    onClick={() => handleSelectItems(item?.bom?.id, item?.id)}
                                >
                                    <div className="flex flex-row gap-4">
                                        <img
                                            src={item?.item?.imageUrl || "/svg/NoImage.svg"}
                                            alt={item?.item?.name}
                                            className="!w-[3rem] !h-[3rem] rounded-md"
                                        />
                                        <div className="flex flex-col justify-start">
                                            <p className="font-semibold">
                                                {item?.item?.name}
                                            </p>
                                            <p className="text-xs text-gray-600">
                                                {item?.item?.category?.name}/
                                                {item?.item?.subCategory?.name}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mr-5 flex gap-4 items-center">
                                        <span className="italic text-gray-700">
                                            {item?.amount}
                                        </span>
                                        <span className="italic text-gray-500">
                                            {item?.item?.unit?.name}
                                        </span>
                                       
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div>{t("material.receivable.material_sales_order_item.no_items_available")}</div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default MaterialSalesOrderItem
export enum ROUTES {
    ONBOARDING = '/onBoarding',
    SOURCING = '/sourcing',
}


const onBoardingRoute = ROUTES.ONBOARDING;
const sourcingRoute = ROUTES.SOURCING;

export {
    onBoardingRoute,
    sourcingRoute
}
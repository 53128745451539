import React, { useState } from 'react';
import { Table, TablePaginationConfig } from 'antd';
import { ColumnsType } from 'antd/es/table';

interface PaginatedTableProps<T> {
    columns: ColumnsType<T>;
    dataSource: T[];
    rowKey?: string;
    loading?: boolean;
    onRow?: (record: T) => React.HTMLProps<HTMLTableRowElement>;
}

const PaginatedTable = <T extends object>({ columns, dataSource, rowKey = 'id', loading, onRow }: PaginatedTableProps<T>) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setCurrentPage(pagination.current || 1);
        setPageSize(pagination.pageSize || 10);
    };

    return (
        <Table<T>
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            pagination={{
                current: currentPage,
                pageSize,
                total: dataSource.length,
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '50'],
                onChange: (page, size) => {
                    setCurrentPage(page);
                    setPageSize(size);
                },
            }}
            onChange={handleTableChange}
            rowKey={rowKey}
            onRow={onRow}
        />
    );
};

export default PaginatedTable;

import React, { useEffect, useState } from "react";
import { Layout, Avatar, Dropdown, Menu, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import FactorySelect from "./FactorySelect";
import useFactorySelection from "../hooks/useFactorySelection";
import { isAuthenticated, removeToken } from "../utilities/helper";
import palette from "../constants/pallete";
import SVGComponent from "./SVGComponent";
import i18n, { LANG } from "../internalization/i18n";
import { useTranslation } from "react-i18next";

const { Header: AntHeader } = Layout;

interface IHeaderProps {
  selectedModule: number;
  setSelectedModule: (val: number) => void;
  headerHeight: string;
}

const Header: React.FC<IHeaderProps> = ({
  setSelectedModule,
  selectedModule,
  headerHeight,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t }: any = useTranslation();
  const isLoginPage = location.pathname === "/login";
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language || "en");
  const [user, setUser] = useState<any>(null);
  const { selectedFactoryId, handleFactorySelect } = useFactorySelection({});
  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      try {
        const parsedUserDetails = JSON.parse(userDetails);
        const finalUserDetails =
          typeof parsedUserDetails === "string"
            ? JSON.parse(parsedUserDetails)
            : parsedUserDetails;
        setUser(finalUserDetails);
      } catch (error) {
        console.error(t("components.header.errors.parse_user_details"), error);
      }
    }
  }, []);

  const handleLogout = () => {
    removeToken();

    localStorage.clear();

    navigate("/login");
    window.location.reload();
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang).then(() => {
      localStorage.setItem("language", lang);
      setCurrentLanguage(lang);
      message.success(t("components.header.language.switch_success", { lang: LANG.find((l) => l.code === lang)?.title }));
    });
  };

  const languageMenu = (
    <Menu>
      {LANG.map((lang) => (
        <Menu.Item
          key={lang.code}
          onClick={() => handleLanguageChange(lang.code)}
          style={{
            fontWeight: currentLanguage === lang.code ? "bold" : "normal",
            backgroundColor:
              currentLanguage === lang.code ? "#f0f0f0" : "transparent",
          }}
        >
          {lang.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  const userMenu = (
    <Menu>
      <Menu.Item onClick={() => navigate(`/profile`)}>
        {t("components.header.menu.profile")}
      </Menu.Item>
      <Menu.Divider />
      <Menu.ItemGroup title={t("components.header.menu.factories")}>
        {user?.factory?.map((factory: any) => (
          <Menu.Item key={factory.id}>{factory.factoryName}</Menu.Item>
        ))}
      </Menu.ItemGroup>
      <Menu.Divider />
      {/* {!isLoginPage && (
        <>
          <Menu.Item key="3">
            <ModuleButtons
              onModuleChange={(module) => setSelectedModule(module)}
            />
          </Menu.Item>
          <Menu.Divider />
        </>
      )} */}
      <Menu.Item key="3" onClick={handleLogout}>
        {t("components.header.menu.logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <AntHeader
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 1.25rem",
        height: headerHeight,
        backgroundColor: palette.secondary,
        color: "#fff",
        boxShadow: "0 0.125rem 0.5rem rgba(0, 0, 0, 0.15)",
      }}
    >
      <div className="flex flex-row gap-20 items-center">
        <div
          className="text-white font-bold text-xl"
          style={{ padding: "1rem", color: "#fff", textAlign: "center" }}
        >
          {t("components.header.title")}
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        {isAuthenticated() && (
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            {userDetails.role?.name === "SUPER_ADMIN" && (
              <div style={{ minWidth: "20rem" }}>
                <FactorySelect
                  selectedFactoryId={selectedFactoryId}
                  onFactorySelect={handleFactorySelect}
                />
              </div>
            )}
            <Dropdown overlay={languageMenu} placement="bottomRight">
              <div className="cursor-pointer">
                <SVGComponent
                  src="/svg/Language.svg"
                  fillColor="#fff"
                  width="1.2rem"
                  height="1.2rem"
                />
              </div>
            </Dropdown>
            <Dropdown overlay={userMenu} placement="bottomRight">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Avatar
                  style={{ backgroundColor: "#87d068" }}
                  icon={<UserOutlined />}
                />
                <span style={{ color: "#fff", marginLeft: "0.5rem" }}>
                  {user?.name || t("components.header.menu.default_user")}
                </span>
              </div>
            </Dropdown>
          </div>
        )}
      </div>
    </AntHeader>
  );
};

export default Header;

import { Popover } from "antd";
import React from "react";

const TableCell = ({
  content,
  isHeader = false,
  rowSpan,
  className = "",
}: {
  content: any;
  isHeader?: boolean;
  rowSpan?: number;
  className?: string;
}) => {
  if (isHeader) {
    return (
      <th
        className={`p-2 border text-center min-w-24 ${className}`}
        rowSpan={rowSpan}
      >
        {content}
      </th>
    );
  }
  return (
    <td
      className={`${
        content?.error ? "" : "p-2"
      }  border text-center min-w-24  ${className}`}
      rowSpan={rowSpan}
    >
      {content?.error ? (
        <Popover
          content={<div className="p-4">{content?.error}</div>}
          title="Error"
        >
          <div className="border border-red-500 p-2 text-center ">
            {content?.value}
          </div>
        </Popover>
      ) : (
        content?.value
      )}
    </td>
  );
};

const BulkTable = ({ data }: any) => {
  return (
    <div className="container overflow-auto">
      <table className="w-full border-collapse table ">
        <thead>
          <tr className="bg-gray-100">
            {[
              "Customer Name",
              "Branch",
              "Buyer PO Number",
              "Reference",
              "Expected Margin %",
              "Merchandiser",
              "Approver",
              "SO Date",
              "Expected Date",
              "Payment Terms",
              "Delivery Type",
              "Product Type",
              "Currency",
              "Discount %",
              "Shipment Charges",
              "Customer Note",
              "TnCs",
              "Name",
              "Unit",
              "HSN Code",
              "Category",
              "Sub Category",
              "Quantity",
              "Rate",
              "Tax",
              "Tax %",
            ].map((header) => (
              <TableCell key={header} content={header} isHeader />
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((salesOrder: any, orderIndex: number) => {
            const orderRows = [];

            const firstRow = (
              <tr className="table-row" key={`order-${orderIndex}-first`}>
                {[
                  salesOrder?.customerName,
                  salesOrder?.branch,
                  salesOrder?.buyerPoNumber,
                  salesOrder?.reference,
                  salesOrder?.expectedMargin,
                  salesOrder?.merchandiser,
                  salesOrder?.approver,
                  salesOrder?.soDate,
                  salesOrder?.expectedDate,
                  salesOrder?.paymentTerms,
                  salesOrder?.deliveryType,
                  salesOrder?.productType,
                  salesOrder?.currency,
                  salesOrder?.discount,
                  salesOrder?.shipmentCharges,
                  salesOrder?.customerNote,
                  salesOrder?.tnc,
                ].map((value, i) => (
                  <TableCell
                    key={`order-${orderIndex}-cell-${i}`}
                    content={value}
                    rowSpan={salesOrder.salesOrderItems.length}
                  />
                ))}
                {[
                  salesOrder.salesOrderItems[0]?.name,
                  salesOrder.salesOrderItems[0]?.unit,
                  salesOrder.salesOrderItems[0]?.hsnCode,
                  salesOrder.salesOrderItems[0]?.category,
                  salesOrder.salesOrderItems[0]?.subCategory,
                  salesOrder.salesOrderItems[0]?.quantity,
                  salesOrder.salesOrderItems[0]?.rate,
                  salesOrder.salesOrderItems[0]?.tax,
                  salesOrder.salesOrderItems[0]?.taxPercentage,
                ].map((value, i) => (
                  <TableCell
                    key={`item-${orderIndex}-cell-${i}`}
                    content={value}
                  />
                ))}
              </tr>
            );
            orderRows.push(firstRow);

            salesOrder.salesOrderItems
              .slice(1)
              .forEach((item: any, index: number) => {
                const additionalRow = (
                  <tr key={`order-${orderIndex}-item-${index}`}>
                    {[
                      item?.name,
                      item?.unit,
                      item?.hsnCode,
                      item?.category,
                      item?.subCategory,
                      item?.quantity,
                      item?.rate,
                      item?.tax,
                      item?.taxPercentage,
                    ].map((value, i) => (
                      <TableCell
                        key={`item-${orderIndex}-${index}-cell-${i}`}
                        content={value}
                      />
                    ))}
                  </tr>
                );
                orderRows.push(additionalRow);
              });

            return orderRows;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BulkTable;

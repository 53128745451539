import React from "react";
import { Modal, Timeline } from "antd";
import { formatDateToDDMMYYYY } from "../../../utilities/helper";
import palette from "../../../constants/pallete";
import { MaterialStatusType } from "../../../constants/enums";
import {
  IHistoryItem,
  IMaterialIssuedHistory,
} from "../../../types/groyyo_plus/materialRecievable";
import { useTranslation } from "react-i18next";
import NoDataAvailable from "../../../components/NoDataAvailable";

type HistoryModalProps = {
  visible: boolean;
  onClose: () => void;
  history: (IHistoryItem | IMaterialIssuedHistory)[];
  type: MaterialStatusType;
};

const HistoryModal: React.FC<HistoryModalProps> = ({
  visible,
  onClose,
  history,
  type,
}) => {
  const { t }: any = useTranslation();

  const renderChildren = (item: IHistoryItem | IMaterialIssuedHistory) => {
    if (type === MaterialStatusType.RECIEVED) {
      const receivedItem = item as IHistoryItem;
      return (
        <div
          className="flex flex-col p-4 rounded-md"
          style={{ backgroundColor: palette.secondary3 }}
        >
          <div className="flex justify-between">
            <span className="font-semibold">
              {t("material.history_modal.labels.received_quantity")}:
            </span>
            <span>{receivedItem.receivedQuantity}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">
              {t("material.history_modal.labels.rejected_quantity")}:
            </span>
            <span>{receivedItem.rejectedQuantity}</span>
          </div>
        </div>
      );
    }

    const issuedItem = item as IMaterialIssuedHistory;
    return (
      <div
        className="flex flex-col p-4 rounded-md"
        style={{ backgroundColor: palette.secondary3 }}
      >
        <div className="flex justify-between">
          <span className="font-semibold">
            {t("material.history_modal.labels.issued_quantity")}:
          </span>
          <span>{issuedItem.quantity}</span>
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={visible}
      title={t("material.history_modal.title")}
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{
        height: "50vh",
        overflow: "hidden",
      }}
      maskClosable={false}
    >
      <div
        className="overflow-y-scroll overflow-x-hidden pl-3 pr-6 pt-3"
        style={{
          height: "100%",
        }}
      >
        {
          history?.length > 0 ? (
            <Timeline
              mode="left"
              items={history?.map((item) => ({
                label: (
                  <div
                    style={{ backgroundColor: palette.secondary }}
                    className="flex items-center text-white p-2 rounded-md"
                  >
                    {formatDateToDDMMYYYY(
                      type === MaterialStatusType.ISSUED
                        ? (item as IMaterialIssuedHistory)?.issuedDate
                        : (item as IHistoryItem)?.receivedDate
                    )}
                  </div>
                ),
                color: palette.secondary,
                children: renderChildren(item),
              }))}
            />
          ) : (
            <NoDataAvailable text={t('material.history_modal.no_data')} />
          )
        }

      </div>
    </Modal>
  );
};

export default HistoryModal;
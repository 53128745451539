import React, { useEffect, useState } from "react";
import { Select, Spin, message } from "antd";
import { factoryList } from "../services/userServiceApi/factoriesApi";
import { useTranslation } from "react-i18next";

interface Factory {
  id: number;
  name: string;
  registrationNumber: string;
  gstNumber: string;
}

interface FactorySelectProps {
  selectedFactoryId: number | null;
  onFactorySelect: (value: number) => void;
}

const FactorySelect: React.FC<FactorySelectProps> = ({
  selectedFactoryId,
  onFactorySelect,
}) => {
  const [factories, setFactories] = useState<Factory[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { t }: any = useTranslation();

  useEffect(() => {
    const fetchFactories = async () => {
      setLoading(true);
      try {
        const response = await factoryList();
        const responseData = response?.data;

        if (responseData?.status) {
          setFactories(responseData?.result);
        } else {
          message.error(t("components.factory_select.errors.load_failed"));
        }
      } catch (error) {
        message.error(t("components.factory_select.errors.fetch_error"));
        console.error(t("components.factory_select.errors.fetch_error"), error);
      } finally {
        setLoading(false);
      }
    };

    fetchFactories();
  }, []);

  return (
    <Select
      placeholder="Select a factory"
      style={{ width: "100%" }}
      onChange={onFactorySelect}
      value={selectedFactoryId || undefined}
      loading={loading}
      notFoundContent={loading ? <Spin size="small" /> : t("components.factory_select.no_factories")}
      showSearch
      filterOption={(input, option) =>
        (option?.children as any).toLowerCase().includes(input.toLowerCase())
      }
    >
      {factories.map((factory) => (
        <Select.Option key={factory.id} value={factory.id}>
          {factory.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FactorySelect;
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import UserList from "../pages/UserList";
import CreateUser from "../pages/CreateUser";
import UserDetails from "../pages/UserDetails";
import Categories from "../pages/Categories";
import Subcategories from "../pages/Subcategories";
import Branches from "../pages/Branches";
import Products from "../pages/Products";
import Delivery from "../pages/Delivery";
import Payments from "../pages/Payments";
import Customers from "../pages/Customers";
import Jobwork from "../pages/JobWorkSuppliers";
import Taxes from "../pages/Taxes";
import SalesOrder from "../pages/groyyo_plus/Order/SalesOrder";
import SingleOrderDetail from "../pages/groyyo_plus/Order/detail/SingleOrderDetail";
import SalesOrderCreation from "../pages/groyyo_plus/Order/create/SalesOrderCreation";
import BOM from "../pages/groyyo_plus/Order/bom/BOM";
import CreateBOM from "../pages/groyyo_plus/Order/bom/CreateBOM";
import CreateBomItem from "../pages/groyyo_plus/Order/bom/CreateBomItem";
import SalesOrderBulkUpload from "../pages/groyyo_plus/Order/bulk/SalesOrderBulkUpload";
import MaterialRecievable from "../pages/groyyo_plus/Material/recievable";
import MaterialSalesOrderItem from "../pages/groyyo_plus/Material/MaterialSalesOrderItem";
import RecievableList from "../pages/groyyo_plus/Material/recievable/RecievableList";
import MaterialIssued from "../pages/groyyo_plus/Material/issue";
import IssuedList from "../pages/groyyo_plus/Material/issue/IssuedList";
import ReadyForDispatch from "../pages/groyyo_plus/Dispatch/ReadyForDispatch";
import DispatchPage from "../pages/groyyo_plus/Dispatch/Dispatch";
import MaterialTracking from "../pages/groyyo_plus/Material/marketTracking";
import WIPPage from "../pages/groyyo_plus/Production/WIP";
import StartInspectionScreen from "../pages/groyyo_plus/Dispatch/StartInspectionScreen";
import StartDispatch from "../pages/groyyo_plus/Dispatch/StartDispatch";
import TNAPage from "../pages/groyyo_plus/TNA";
import TNADetails from "../pages/groyyo_plus/TNA/TNADetails";
import TNACreateScreen from "../pages/groyyo_plus/TNA/TNACreateScreen";
import TNATemplateCreate from "../pages/groyyo_plus/TNA/TNATemplateCreate";
import Home from "../pages/groyyo_plus/Dashboard/Home";
import Profile from "../pages/groyyo_plus/Profile";
import CurrentFactoryUser from "../pages/groyyo_plus/CurrentFactoryUser";
import RoleProtection from "./RoleProtection";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import EditItemDetail from "../pages/groyyo_plus/Order/detail/EditItemDetail";

interface ModuleRoutesProps {
  selectedModule: number;
}

const ModuleRoutes: React.FC<ModuleRoutesProps> = ({ selectedModule }) => {
  // const { userPermissions } = useSelector(
  //   (state: RootState) => state.userPermissions
  // );
  

  const storedPermissionString = localStorage.getItem("userPermissionState");

  const userPermissions = JSON.parse(storedPermissionString as string);

  switch (selectedModule) {
    case 1:
      return (
        <Routes>
          <Route path="/onBoarding">
            <Route index element={<Dashboard />} />
            <Route path="users" element={<UserList />} />
            <Route
              path="user/create"
              element={<CreateUser onCreate={() => {}} />}
            />
            <Route path="user/:id/detail" element={<UserDetails />} />
            <Route path="categories" element={<Categories />} />
            <Route path="sub-categories" element={<Subcategories />} />
            <Route path="branches" element={<Branches />} />
            <Route path="product" element={<Products />} />
            <Route path="delivery" element={<Delivery />} />
            <Route path="payment" element={<Payments />} />
            <Route path="customers" element={<Customers />} />
            <Route path="job-work" element={<Jobwork />} />
            <Route path="taxes" element={<Taxes />} />
          </Route>
        </Routes>
      );
    case 2:
      return (
        <div className="p-4">
          <Routes>
            <Route
              path="/sourcing"
              element={
                <RoleProtection
                  permissionKey="DASHBOARD"
                  permissionCategory="VIEW"
                  permissionMap={userPermissions}
                >
                  <Outlet />
                </RoleProtection>
              }
            >
              <Route
                path="users"
                element={
                  <RoleProtection
                    permissionKey="USER_MANAGEMENT"
                    permissionCategory="VIEW"
                    permissionMap={userPermissions}
                  >
                    <CurrentFactoryUser />
                  </RoleProtection>
                }
              />

              <Route path="sales-order">
                <Route
                  index
                  element={
                    <RoleProtection
                      permissionKey="SALES_ORDERS"
                      permissionCategory="VIEW"
                      permissionMap={userPermissions}
                    >
                      <SalesOrder />
                    </RoleProtection>
                  }
                />
                <Route
                  path=":orderId"
                  element={
                    <RoleProtection
                      permissionKey="SALES_ORDERS"
                      permissionCategory="VIEW"
                      permissionMap={userPermissions}
                    >
                      <SingleOrderDetail />
                    </RoleProtection>
                  }
                />
                <Route
                  path="create"
                  element={
                    <RoleProtection
                      permissionKey="SALES_ORDERS"
                      permissionCategory="CREATE"
                      permissionMap={userPermissions}
                    >
                      <SalesOrderCreation />
                    </RoleProtection>
                  }
                />
                <Route
                  path="bulk"
                  element={
                    <RoleProtection
                      permissionKey="SALES_ORDERS"
                      permissionCategory="CREATE"
                      permissionMap={userPermissions}
                    >
                      <SalesOrderBulkUpload />
                    </RoleProtection>
                  }
                />
                <Route
                  path="edit"
                  element={
                    <RoleProtection
                      permissionKey="SALES_ORDERS"
                      permissionCategory="EDIT"
                      permissionMap={userPermissions}
                    >
                      <EditItemDetail />
                    </RoleProtection>
                  }
                />
              </Route>

              <Route path="bom">
                <Route
                  index
                  element={
                    <RoleProtection
                      permissionKey="BOM"
                      permissionCategory="VIEW"
                      permissionMap={userPermissions}
                    >
                      <BOM />
                    </RoleProtection>
                  }
                />
                <Route
                  path="create-bom/:salesOrderId"
                  element={
                    <RoleProtection
                      permissionKey="BOM"
                      permissionCategory="CREATE"
                      permissionMap={userPermissions}
                    >
                      <CreateBOM />
                    </RoleProtection>
                  }
                />
                <Route
                  path="create-bom-item/:salesOrderId"
                  element={
                    <RoleProtection
                      permissionKey="BOM"
                      permissionCategory="CREATE"
                      permissionMap={userPermissions}
                    >
                      <CreateBomItem />
                    </RoleProtection>
                  }
                />
              </Route>

              <Route path="material-receive">
                <Route
                  index
                  element={
                    <RoleProtection
                      permissionKey="MATERIAL_RECEIVABLE"
                      permissionCategory="VIEW"
                      permissionMap={userPermissions}
                    >
                      <MaterialRecievable />
                    </RoleProtection>
                  }
                />
                <Route
                  path="sales-order-item/:salesOrderId"
                  element={
                    <RoleProtection
                      permissionKey="MATERIAL_RECEIVABLE"
                      permissionCategory="VIEW"
                      permissionMap={userPermissions}
                    >
                      <MaterialSalesOrderItem />
                    </RoleProtection>
                  }
                />
                <Route
                  path="bom-items/:bomId/:salesOrderItemId/:salesOrderId"
                  element={
                    <RoleProtection
                      permissionKey="MATERIAL_RECEIVABLE"
                      permissionCategory="VIEW"
                      permissionMap={userPermissions}
                    >
                      <RecievableList />
                    </RoleProtection>
                  }
                />
              </Route>

              <Route path="material-issued">
                <Route
                  index
                  element={
                    <RoleProtection
                      permissionKey="MATERIAL_ISSUE"
                      permissionCategory="VIEW"
                      permissionMap={userPermissions}
                    >
                      <MaterialIssued />
                    </RoleProtection>
                  }
                />
                <Route
                  path="sales-order-item/:salesOrderId"
                  element={
                    <RoleProtection
                      permissionKey="MATERIAL_ISSUE"
                      permissionCategory="VIEW"
                      permissionMap={userPermissions}
                    >
                      <MaterialSalesOrderItem />
                    </RoleProtection>
                  }
                />
                <Route
                  path="bom-items/:bomId/:salesOrderItemId/:salesOrderId"
                  element={
                    <RoleProtection
                      permissionKey="MATERIAL_ISSUE"
                      permissionCategory="VIEW"
                      permissionMap={userPermissions}
                    >
                      <IssuedList />
                    </RoleProtection>
                  }
                />
              </Route>

              <Route
                path="material-tracking"
                element={
                  <RoleProtection
                    permissionKey="MATERIAL_TRACKING"
                    permissionCategory="VIEW"
                    permissionMap={userPermissions}
                  >
                    <MaterialTracking />
                  </RoleProtection>
                }
              />

              <Route path="production">
                <Route
                  path="wip"
                  element={
                    <RoleProtection
                      permissionKey="WIP"
                      permissionCategory="VIEW"
                      permissionMap={userPermissions}
                    >
                      <WIPPage />
                    </RoleProtection>
                  }
                />
              </Route>

              <Route
                path="dashboard"
                element={
                  <RoleProtection
                    permissionKey="DASHBOARD"
                    permissionCategory="VIEW"
                    permissionMap={userPermissions}
                  >
                    <Home />
                  </RoleProtection>
                }
              />
            </Route>

            <Route
              path="dispatch"
              element={
                <RoleProtection
                  permissionKey="DISPATCH"
                  permissionCategory="VIEW"
                  permissionMap={userPermissions}
                >
                  <Outlet />
                </RoleProtection>
              }
            >
              <Route
                index
                element={
                  <RoleProtection
                    permissionKey="DISPATCH"
                    permissionCategory="VIEW"
                    permissionMap={userPermissions}
                  >
                    <ReadyForDispatch />
                  </RoleProtection>
                }
              />
              <Route
                path="available-dispatch/:dispatchId/:salesOrderItemId"
                element={
                  <RoleProtection
                    permissionKey="DISPATCH"
                    permissionCategory="VIEW"
                    permissionMap={userPermissions}
                  >
                    <DispatchPage />
                  </RoleProtection>
                }
              />
              <Route
                path="start-inspection/:dispatchId/:salesOrderItemId"
                element={
                  <RoleProtection
                    permissionKey="DISPATCH"
                    permissionCategory="CREATE"
                    permissionMap={userPermissions}
                  >
                    <StartInspectionScreen />
                  </RoleProtection>
                }
              />
              <Route
                path="start-dispatch/:dispatchId/:salesOrderItemId"
                element={
                  <RoleProtection
                    permissionKey="DISPATCH"
                    permissionCategory="CREATE"
                    permissionMap={userPermissions}
                  >
                    <StartDispatch />
                  </RoleProtection>
                }
              />
            </Route>

            <Route path="tna">
              <Route
                index
                element={
                  <RoleProtection
                    permissionKey="TNA"
                    permissionCategory="VIEW"
                    permissionMap={userPermissions}
                  >
                    <TNAPage />
                  </RoleProtection>
                }
              />
              <Route
                path="details/:salesOrderItemId"
                element={
                  <RoleProtection
                    permissionKey="TNA"
                    permissionCategory="VIEW"
                    permissionMap={userPermissions}
                  >
                    <TNADetails />
                  </RoleProtection>
                }
              />
              <Route
                path="create/:salesOrderItemId/:tnaTemplateId"
                element={
                  <RoleProtection
                    permissionKey="TNA"
                    permissionCategory="CREATE"
                    permissionMap={userPermissions}
                  >
                    <TNACreateScreen />
                  </RoleProtection>
                }
              />
              <Route
                path="create-template"
                element={
                  <RoleProtection
                    permissionKey="TNA"
                    permissionCategory="CREATE"
                    permissionMap={userPermissions}
                  >
                    <TNATemplateCreate />
                  </RoleProtection>
                }
              />
            </Route>
          </Routes>
        </div>
      );

    case 3:
      return <div>Module 3 will be implemented soon!</div>;

    default:
      return <div>Invalid module selected</div>;
  }
};

export default ModuleRoutes;

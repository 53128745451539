import { Modal, Skeleton, Timeline, message } from "antd";
import React, { useEffect, useState } from "react";
import { getHistoryDispatch } from "../../../services/orderServiceApi/dispatchApi";
import palette from "../../../constants/pallete";
import NoDataAvailable from "../../../components/NoDataAvailable";
import { useTranslation } from "react-i18next";

interface IDispatchItem {
  id: number;
  salesOrderItemId: number;
  dispatchedQuantity: number;
  dispatchedDate: string;
  factoryId: number;
}

interface IDispatchHistory {
  visible: boolean;
  salesOrderItemId: number;
  onClose: () => void;
}

const DispatchHistory: React.FC<IDispatchHistory> = ({ visible, salesOrderItemId, onClose }) => {
  const { t }: any = useTranslation();
  const [dispatchHistory, setDispatchHistory] = useState<IDispatchItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchHistoryDispatch = async () => {
    setLoading(true);
    try {
      const response = await getHistoryDispatch(salesOrderItemId);
      const responseData = response?.data;

      if (responseData?.status) {
        setDispatchHistory(responseData?.result?.dispatches || []);
      } else {
        setDispatchHistory([]);
      }
    } catch (error) {
      console.error(t("dispatch.dispatchHistory.error_fetching"), error);
      message.error(t("dispatch.dispatchHistory.error_fetching"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible && salesOrderItemId) {
      fetchHistoryDispatch();
    }
  }, [visible, salesOrderItemId]);

  return (
    <Modal
      open={visible}
      title={t("dispatch.dispatchHistory.title")}
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{
        maxHeight: "60vh",
        overflowY: "auto",
        padding: "16px",
      }}
      maskClosable={false}
    >
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="flex flex-col gap-2">
            <Skeleton.Image active style={{ width: 200, height: 200 }} />
            <Skeleton.Node active style={{ width: 200, height: 30 }} />
          </div>
        </div>
      ) : dispatchHistory.length === 0 ? (
        <div className="text-center text-gray-500">
          <NoDataAvailable text={t("dispatch.dispatchHistory.no_data") as string} />
        </div>
      ) : (
        <Timeline
          mode="left"
          items={dispatchHistory.map((dispatch) => ({
            label: (
              <div className="p-2 text-white rounded-md" style={{ backgroundColor: palette.secondary }}>
                {dispatch.dispatchedDate}
              </div>
            ),
            color: "gray",
            children: (
              <div className="p-3 rounded-md shadow-md" style={{ backgroundColor: palette.secondary }}>
                <div className="flex justify-between mb-2">
                  <span className="font-semibold text-white">{t("dispatch.dispatchHistory.dispatched_quantity")}</span>
                  <span className="text-white">{dispatch.dispatchedQuantity}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-white">{t("dispatch.dispatchHistory.received_quantity")}</span>
                  <span className="text-white">0</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-semibold text-white">{t("dispatch.dispatchHistory.rejected_quantity")}</span>
                  <span className="text-white">0</span>
                </div>
              </div>
            ),
          }))}
        />
      )}
    </Modal>
  );
};

export default DispatchHistory;
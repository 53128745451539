import React, { useEffect, useState } from 'react';
import DashboardLayout from '../layouts/DashboardLayout';
import { Button, Table, Modal, Form, Input, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { getJobWorkSuppliers, createJobWorkSupplier, bulkUploadJobWorkSuppliers } from '../services/orderServiceApi/jobWorkSuppliersApi';
import { ApiResponse } from '../types/response';
import { CreateJobWorkSupplierDto } from '../types/jobWorkSupplier';
import DownloadTemplateButton from '../components/DownloadTemplateButton';

const JobWorkSuppliers = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [jobWorkSuppliers, setJobWorkSuppliers] = useState<CreateJobWorkSupplierDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchJobWorkSuppliers();
  }, []);

  const fetchJobWorkSuppliers = async () => {
    try {
      const response = await getJobWorkSuppliers();
      const responseData: ApiResponse<CreateJobWorkSupplierDto[]> = response?.data;
      if (responseData?.status && responseData?.statusCode === 200) {
        setJobWorkSuppliers(responseData.result);
      }
    } catch (error) {
      console.error("Failed to fetch job work suppliers:", error);
    }
  };

  const handleAddJobWorkSupplier = async (values: CreateJobWorkSupplierDto) => {
    setLoading(true);
    try {
      const response = await createJobWorkSupplier(values);
      const responseData: ApiResponse<CreateJobWorkSupplierDto> = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Job work supplier added successfully!");
        setIsModalVisible(false);
        fetchJobWorkSuppliers();
        form.resetFields();
      } else {
        message.error("Failed to add job work supplier");
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error adding job work supplier");
      console.error("Error adding job work supplier:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBulkUpload = async (file: File) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: CreateJobWorkSupplierDto[] = XLSX.utils.sheet_to_json(worksheet);

      if (jsonData?.length === 0) {
        message.error("Empty File!");
        return;
      }

      if (!jsonData.every(item => item.name && item.address)) {
        message.error('Invalid file format. Ensure it contains "name" and "address" columns.');
        return;
      }

      const response: any = await bulkUploadJobWorkSuppliers(jsonData);
      const responseData = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Job work suppliers uploaded successfully!");
        fetchJobWorkSuppliers();
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error uploading job work suppliers");
      console.error("Error uploading job work suppliers:", error);
    }
  };

  const columns = [
    {
      title: 'Supplier Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    }
  ];

  return (
    <DashboardLayout>
      <DashboardLayout.Header title="Manage Job Work Suppliers">
        <DownloadTemplateButton templateName="bulk_jobWorkSuppliers_template.xlsx" buttonText="Download Job Work Suppliers Template" />
        <Button type="primary" onClick={() => setIsModalVisible(true)}>Add Job Work Supplier</Button>
        <Upload
          beforeUpload={(file) => {
            handleBulkUpload(file);
            return false;
          }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Bulk Upload</Button>
        </Upload>
      </DashboardLayout.Header>
      <DashboardLayout.Body>
        <Table dataSource={jobWorkSuppliers} columns={columns} rowKey="id" />

        <Modal
          title="Add New Job Work Supplier"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleAddJobWorkSupplier}
          >
            <Form.Item
              name="name"
              label="Supplier Name"
              rules={[{ required: true, message: 'Please enter the supplier name' }]}
            >
              <Input placeholder="Enter supplier name" />
            </Form.Item>

            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: 'Please enter the address' }]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Add Job Work Supplier
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </DashboardLayout.Body>
    </DashboardLayout>
  );
}

export default JobWorkSuppliers;
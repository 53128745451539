import { orderApi } from "./orderService";
import { ORDER_SERVICE_API } from ".";
const bulkUrl = ORDER_SERVICE_API.BULK;

export const getSoBulkUploadTemplateData = async () => {
  return orderApi.get(`${bulkUrl}/sales-order-template`);
};

export const createBulkSaleOrder = async (body: any) => {
  return orderApi.post(`${bulkUrl}/sales-order`, body);
};

import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';
import palette from '../constants/pallete';

interface Tab<T> {
    label: string;
    content: ReactNode;
    tabEnum: T;
}

interface CustomTabProps<T> {
    tabs: Tab<T>[];
    activeTab: T;
    setActiveTabAction: (tab: T) => PayloadAction<T>;
}

const CustomTab = <T extends unknown>({ tabs, activeTab, setActiveTabAction }: CustomTabProps<T>) => {
    const dispatch = useDispatch();

    return (
        <div className="w-full">
            
            <div className='flex justify-center items-center'>
                <div className="inline-flex justify-center space-x-4 bg-gray-200 rounded-full p-2 mx-auto">
                    {tabs.map((tab) => (
                        <button
                            key={String(tab.tabEnum)} 
                            // onClick={() => dispatch(setActiveTabAction(tab.tabEnum))}
                            className={`px-4 py-2 rounded-full text-base font-medium transition-all cursor-default ${
                                activeTab === tab.tabEnum
                                    ? 'text-white'
                                    : 'bg-gray-200 text-black'
                            }`}
                            style={{
                                backgroundColor: activeTab === tab.tabEnum ? palette.secondary : ''
                            }}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
            </div>

            
            <div className="w-full mt-4 bg-white min-h-[60vh]">
                {tabs.find((tab) => tab.tabEnum === activeTab)?.content}
            </div>
        </div>
    );
};

export default CustomTab;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAvailabeForDispatch } from '../../services/orderServiceApi/dispatchApi';
import { IAvailableForDispatch } from '../../types/groyyo_plus/dispatch';

export const fetchAvailableForDispatch = createAsyncThunk(
    'dispatch/fetchAvailableForDispatch',
    async (
      options: { dispatchId?: number } = {}, 
      { rejectWithValue }
    ) => {
      try {
        const response = await getAvailabeForDispatch();
        const responseData = response?.data;
        if (responseData?.status) {
          const results = responseData?.result || [];
          
          const selectedDispatch = options.dispatchId
            ? results.find((item: any) => item.id === options.dispatchId) || null
            : null;
  
          
          return { availableForDispatch: results, selectedDispatch };
        } else {
          return rejectWithValue(responseData?.message || 'Failed to fetch data');
        }
      } catch (error: any) {
        return rejectWithValue(error.message || 'An error occurred');
      }
    }
  );

interface DispatchState {
  availableForDispatch: IAvailableForDispatch[];
  selectedDispatch: IAvailableForDispatch | null;
  loading: boolean;
  error: string | null;
}

const initialState: DispatchState = {
  availableForDispatch: [],
  loading: false,
  error: null,
  selectedDispatch: null,
};

const dispatchSlice = createSlice({
  name: 'dispatch',
  initialState,
  reducers: {
    setSelectedDispatch: (state, action) => {
      state.selectedDispatch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableForDispatch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAvailableForDispatch.fulfilled, (state, action) => {
        state.loading = false;
      
        const { availableForDispatch, selectedDispatch } = action.payload;
      
        
        state.availableForDispatch = availableForDispatch;
      
        
        if (selectedDispatch) {
          state.selectedDispatch = selectedDispatch;
        } else {
          state.selectedDispatch = null; 
        }
      })
      .addCase(fetchAvailableForDispatch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string || 'Failed to fetch data';
      });
  },
});

export const { setSelectedDispatch } = dispatchSlice.actions;

export default dispatchSlice.reducer;
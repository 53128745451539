import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBOMPendingOrder } from "../../types/groyyo_plus/bomPendingOrders";

interface InitialState {
    bomPendingOrders: IBOMPendingOrder[];
}

const initialState: InitialState = {
    bomPendingOrders: []
}

const bomPendingOrdersSlice = createSlice({
    name: "bomPendingOrders",
    initialState,
    reducers: {
        setBomPendingOrders: (state, action:PayloadAction<IBOMPendingOrder[]>) => {
            state.bomPendingOrders = action.payload;
        },
    }
});

export const {
    setBomPendingOrders
} = bomPendingOrdersSlice.actions;

export default bomPendingOrdersSlice.reducer;
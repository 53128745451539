import { CreateSubCategoryDto } from "../../types/subCategory";
import { orderApi } from "./orderService";
import { ORDER_SERVICE_API } from ".";
;

const subCategoryUrl = ORDER_SERVICE_API.SUB_CATEGORY;

export const getSubCategories = async (categoryId?: number) => {
    return orderApi.get(`${subCategoryUrl}`, {
        params: categoryId ? { categoryId } : {},
    });
}

export const createSubCategory = async (payload: CreateSubCategoryDto) => {
    return orderApi.post(`${subCategoryUrl}`, payload);
}

export const bulkUploadSubCategories = async (subCategories: CreateSubCategoryDto[]) => {
    return orderApi.post(`${subCategoryUrl}/bulk`, subCategories);
};
import React, { useState } from 'react';
import { BellOutlined, GlobalOutlined, PoweroffOutlined, DeleteOutlined } from '@ant-design/icons';
import { IoChevronBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import SVGComponent from '../../components/SVGComponent';
import palette from '../../constants/pallete';
import { Modal, Button, message } from 'antd';
import { softDeleteUser } from '../../services/userServiceApi/authApi';
import { removeToken } from '../../utilities/helper';

const Profile = () => {
    const navigate = useNavigate();
    const userDetails: any = JSON.parse(localStorage.getItem("userDetails") as string);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleLogout = () => {
        removeToken();
        localStorage.removeItem("userDetails");
        navigate("/login");
        window.location.reload();
      };
    
    const deleteUser = async () => {
        try {            
            const response = await softDeleteUser(userDetails?.id);
            const responseData = response?.data;

            if(responseData?.status){
                message.success('Account deleted successfully!');
                setIsModalVisible(false);            
                localStorage.clear();
                navigate('/login');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            message.error('Failed to delete account. Please try again later.');
        }
    };
    
    const showModal = () => setIsModalVisible(true);
    
    const handleCancel = () => setIsModalVisible(false);

    return (
        <div className="w-full bg-white shadow-lg rounded-lg overflow-hidden my-[1rem]">
           
            <div className="text-lg font-bold flex flex-row gap-2 items-center  p-4">
                <span onClick={() => navigate(-1)} className="cursor-pointer">
                    <IoChevronBackOutline style={{ fontWeight: 'bold' }} />
                </span>
                <p>Profile</p>
            </div>

           
            <div className="p-6 text-center">
                <div className="flex justify-center mb-4">
                    <div className="w-24 h-24 bg-white border-4 rounded-full overflow-hidden">
                        <img
                            src="/svg/UserProfile.svg"
                            alt="Avatar"
                            className="object-cover w-full h-full"
                        />
                    </div>
                </div>
                <h3 className="text-lg font-semibold">{userDetails?.name}</h3>
                <p>{userDetails?.email}</p>
                <p>{userDetails?.mobileNo}</p>
            </div>

           
            <div className="p-4">
                <div className="flex items-center py-3 border-b hover:bg-gray-100 cursor-pointer">
                    <BellOutlined className="text-lg mr-4" style={{ color: palette.secondary }} />
                    <span className="text-gray-700">Notification</span>
                </div>
                <div className="flex items-center gap-4 py-3 border-b hover:bg-gray-100 cursor-pointer">
                    <SVGComponent
                        src="/svg/Language.svg"
                        fillColor="#CC6A4C"
                        width="1.2rem"
                        height="1.2rem"
                    />
                    <span className="text-gray-700">Change Language</span>
                </div>
                <div className="flex items-center py-3 border-b hover:bg-gray-100 cursor-pointer">
                    <PoweroffOutlined className="text-lg mr-4" style={{ color: palette.secondary }} />
                    <span className="text-gray-700" onClick={handleLogout}>Logout</span>
                </div>
                <div
                    className="flex items-center py-3 hover:bg-gray-100 cursor-pointer"
                    onClick={showModal}
                >
                    <DeleteOutlined className="text-lg mr-4" style={{ color: palette.secondary }} />
                    <span className="text-gray-700">Delete Account</span>
                </div>
            </div>

           
            <Modal
                title="Delete Account"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="confirm" type="primary" danger onClick={deleteUser}>
                        Yes, Delete
                    </Button>,
                ]}
            >
                <p>Are you sure you want to delete your account? This action cannot be undone.</p>
            </Modal>
        </div>
    );
};

export default Profile;
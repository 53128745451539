import { CreateCategoryDto } from "../../types/category";
import { orderApi } from "./orderService";
import { ORDER_SERVICE_API } from ".";


const categoryUrl = ORDER_SERVICE_API.CATEGORY;
export const getCategories = async () => {
    return orderApi.get(`${categoryUrl}`);
}
export const createCategory = async (payload: CreateCategoryDto) => {
    return orderApi.post(`${categoryUrl}`, payload);
}
export const bulkUploadCategories = async (categories: CreateCategoryDto[]) => {
    return orderApi.post(`${categoryUrl}/bulk`, categories);
};
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TNAProcess {
    id: number;
    tnaProcessId: number;
    dependentTnaProcessId: number | null;
    sequence: number;
    isManualEntry: boolean;
    process: {
        id: number;
        name: string;
        departmentId: string | number | null;
        factoryId: number | null;
        type: string;
        processPhase: string;
    };
    dependencyOn: {
        id: number;
        name: string;
        departmentId: string | number | null;
        factoryId: number | null;
        type: string;
        processPhase: string;
    } | null;
    planDate: string;
}

interface TNATemplateState {
  selectedTemplate: {
    id: number;
    name: string;
  } | null;
  processes: TNAProcess[];
}

const initialState: TNATemplateState = {
  selectedTemplate: null,
  processes: [],
};

const tnaTemplateSlice = createSlice({
  name: 'tnaTemplate',
  initialState,
  reducers: {
    setSelectedTemplate(state, action: PayloadAction<{ id: number; name: string } | null>) {
      state.selectedTemplate = action.payload;
      state.processes = [];
    },
    addProcess(state, action: PayloadAction<TNAProcess>) {
      
      const exists = state.processes.some((process) => process.id === action.payload.id);
      if (!exists) {
        state.processes.push(action.payload); 
      }
    },
    updateProcess(state, action: PayloadAction<{ id: number; data: Partial<TNAProcess> }>) {
      const processIndex = state.processes.findIndex((process) => process.id === action.payload.id);
      if (processIndex !== -1) {
        state.processes[processIndex] = { ...state.processes[processIndex], ...action.payload.data };
      }
    },
    deleteProcess(state, action: PayloadAction<number>) {
      state.processes = state.processes.filter((process) => process.id !== action.payload);
    },
    resetTemplateState(state) {
      state.selectedTemplate = null;
      state.processes = [];
    },
  },
});

export const { setSelectedTemplate, addProcess, updateProcess, deleteProcess, resetTemplateState } =
  tnaTemplateSlice.actions;

export default tnaTemplateSlice.reducer;
import React, { useEffect, useState } from "react";
import { Descriptions, message } from "antd";
import { getUserDetail } from "../services/userServiceApi/authApi";
import { useParams } from "react-router-dom";

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    fetchUserDetails();
  }, [id]);

  const fetchUserDetails = async () => {
    try {
      const response = await getUserDetail();
      setUser(response.data);
    } catch (error) {
      message.error("Failed to fetch user details.");
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Descriptions column={1}>
      <Descriptions.Item label="Name">{user.name}</Descriptions.Item>
      <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
      <Descriptions.Item label="Mobile No">{user.mobileNo}</Descriptions.Item>
      <Descriptions.Item label="Role">{user.role}</Descriptions.Item>
    </Descriptions>
  );
};

export default UserDetails;

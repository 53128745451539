import React, { useEffect, useState } from 'react'
import { getBomPendingOrders } from '../../../../services/orderServiceApi/salesOrderApi'
import PaginatedTable from '../../../../components/PaginatedTable';
import { GetBomPendingOrders } from '../../../../types/groyyo_plus/bomPendingOrders';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { sourcingRoute } from '../../../../routes';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import i18n from '../../../../internalization/i18n';
import { getCurrentLocale } from '../../../../utilities/language';

const BOM = () => {
  const { t }: any = useTranslation();
  const [bomPendingOrders, setBomPendingOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchBomPendingOrder = async () => {
    setLoading(true)
    try {
      const response = await getBomPendingOrders();
      const responseData = response?.data;


      if (responseData?.status) {
        setBomPendingOrders(responseData?.result);
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchBomPendingOrder();
  }, []);

  const columns: ColumnsType<GetBomPendingOrders> = [
    {
      title: t('sales_order_bom.bom.so_number'),
      dataIndex: 'soNo',
      key: 'soNo',
      render: (text, record) => (
        <a
          className="text-blue-400 font-bold"
          onClick={(e) => {
            navigate(`${sourcingRoute}/bom/create-bom/${record?.id}`)
          }}
        >
          {text}
        </a>
      ),
    },
    { title: t('sales_order_bom.bom.buyer_name'), dataIndex: ['buyer', 'name'], key: 'buyerName' },
    { title: t('sales_order_bom.bom.merchandiser'), dataIndex: 'merchandiserName', key: 'merchandiserName' },
    { title: t('sales_order_bom.bom.reference'), dataIndex: 'reference', key: 'reference' },
    { title: t('sales_order_bom.bom.so_date'), dataIndex: 'soDate', key: 'soDate' },
    { title: t('sales_order_bom.bom.expected_date'), dataIndex: 'expectedDate', key: 'expectedDate' },
  ]

  return (
    <ConfigProvider locale={getCurrentLocale(i18n.language)}>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">{t('sales_order_bom.bom.title')}</h1>
      </div>
      <PaginatedTable<GetBomPendingOrders>
        columns={columns}
        dataSource={bomPendingOrders}
        rowKey="id"
        loading={loading}
      />
    </ConfigProvider>
  )
}

export default BOM
import { ORDER_SERVICE_API } from ".";
import { ICreateBomRequestBody } from "../../types/groyyo_plus/bomItem";
import { orderApi } from "./orderService";

const bomUrl = ORDER_SERVICE_API.BOM;

export const getBomItem = async ({bomItemId}: {bomItemId: number})=> {
    return orderApi.get(`${bomUrl}/bom-items/${bomItemId}`)
}

export const createBom = async (payload: ICreateBomRequestBody) => {
    return orderApi.post(`${bomUrl}`, payload)
}

export const getBomDetail = async(bomId: number) => {
    return orderApi.get(`${bomUrl}/bom-po-details?id=${bomId}`)
}
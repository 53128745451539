import MultiStepForm from '../../../../components/MultiStepForm'
import { LeftOutlined } from '@ant-design/icons'
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { CreateSalesOrderTabEnum, SalesOrderCreationStatus } from '../../../../constants/enums';
import { setActiveSalesOrderTab } from '../../../../redux/order/createSalesOrderSlice';
import { useDispatch } from 'react-redux';
import useFetchSalesOrderDetail from '../../../../hooks/useFetchSalesOrderDetail';
import { sourcingRoute } from '../../../../routes';
import { useTranslation } from 'react-i18next';

const SalesOrderCreation = () => {
  const {t}:any = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const status = location.state?.status;
  const id = location.state?.id;

  useEffect(() => {
    if(status === SalesOrderCreationStatus.SALES_ORDER_ITEM_PENDING){
      dispatch(setActiveSalesOrderTab(CreateSalesOrderTabEnum.ITEM_DETAILS))
    }
    else if(status === SalesOrderCreationStatus.SALES_ORDER_BILLING_PENDING){
        dispatch(setActiveSalesOrderTab(CreateSalesOrderTabEnum.BILLING_DETAILS))
    }
    else{
      dispatch(setActiveSalesOrderTab(CreateSalesOrderTabEnum.ORDER_DETAILS))
    }
  }, [status]);

  useFetchSalesOrderDetail(Number(id));

  const navigateToSalesOrder = () => {
    navigate(`${sourcingRoute}/sales-order`)
  };

  return (
    <div>
      <div className="flex gap-2 items-center">
        <LeftOutlined style={{ fontSize: '1rem', fontWeight: 'bold' }} onClick={navigateToSalesOrder} />
        <h1 className="text-2xl font-bold">{t('sales_order.creation.title')}</h1>
      </div>
      <MultiStepForm />
    </div>
  )
}

export default SalesOrderCreation
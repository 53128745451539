import React, { useState } from "react";
import { IMaterialTracking } from "../../../../types/groyyo_plus/materialRecievable";
import palette from "../../../../constants/pallete";
import moment from "moment";
import { formatDateToDDMMYYYY } from "../../../../utilities/helper";
import { useTranslation } from "react-i18next";

interface IMaterialTrackingCardProps {
  material: IMaterialTracking;
  isSelected: boolean;
  onCheckboxChange: () => void;
}

const MaterialTrackingCard = ({
  material,
  isSelected,
  onCheckboxChange,
}: IMaterialTrackingCardProps) => {
  const [showMore, setShowMore] = useState(false);
  const { t }: any = useTranslation();

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const getCounts = () => {
    let pending = 0;
    let delayed = 0;
    let received = 0;

    material?.bom?.bomItems?.forEach((bomItem: any) => {
      if (
        moment(bomItem?.inHouseDate, "YYYY-MM-DD").isAfter(moment()) &&
        bomItem?.materialReceivable?.isCompleted === false
      ) {
        delayed++;
      } else if (
        moment(bomItem?.inHouseDate, "YYYY-MM-DD").isAfter(
          moment(bomItem?.materialReceivable?.updatedAt)
        ) &&
        bomItem?.materialReceivable?.isCompleted
      ) {
        delayed++;
      }
      if (bomItem?.materialReceivable?.isCompleted === false) {
        pending++;
      } else {
        received++;
      }
    });
    return { pending, delayed, received };
  };

  const { pending, delayed, received } = getCounts();

  return (
    <>
      <div
        key={material?.id}
        className="bg-white shadow-md rounded-lg p-4 border relative flex flex-col gap-4"
        style={{ borderColor: palette.secondary }}
      >
        <div
          className="flex justify-between items-start px-6 text-black rounded-t-lg cursor-pointer"
          style={{
            backgroundColor: palette.white,
          }}
          onClick={onCheckboxChange}
        >
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-3">
              <img
                src="/svg/OrderDress.svg"
                alt="icon"
                className="!w-[3.5rem] !h-[3.5rem] rounded-md"
              />
              <div className="flex flex-col">
                <span className="text-sm font-bold text-gray-600">
                  {material?.salesOrder.buyer.name}
                </span>
                <span className="text-lg font-semibold text-gray-800">
                  {material?.item.name}
                </span>
              </div>
            </div>
            <span className="text-base font-bold text-gray-700">
              {material?.salesOrder.soNo}
            </span>
          </div>
          <div className="flex flex-row gap-6 items-center">
            <div className="flex flex-col gap-1">
              <div className="flex items-center gap-2 text-gray-700 text-sm">
                <span className="font-semibold">
                  {t("material.tracking.card.so_date")}:
                </span>
                <span className="text-gray-600">
                  {formatDateToDDMMYYYY(material?.salesOrder.soDate ?? "")}
                </span>
              </div>
              <div className="flex items-center gap-2 text-gray-700 text-sm">
                <span className="font-semibold">
                  {t("material.tracking.card.expected_delivery")}:
                </span>
                <span className="text-gray-600">
                  {formatDateToDDMMYYYY(
                    material?.bom.bomItems[0]?.inHouseDate || ""
                  )}
                </span>
              </div>
            </div>

            <div>
              <input
                type="checkbox"
                className="w-5 h-5 cursor-pointer"
                style={{
                  accentColor: palette.secondary,
                }}
                checked={isSelected}
                onClick={(e) => e.stopPropagation()}
                onChange={onCheckboxChange}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className="bg-white rounded-lg shadow-sm p-4 text-center border">
            <div className="font-semibold text-gray-800">
              {t("material.tracking.card.material_delayed")}
            </div>
            <div className="text-lg">{delayed}</div>
          </div>
          <div className="bg-white rounded-lg shadow-sm p-4 text-center border">
            <div className="font-semibold text-gray-800">
              {t("material.tracking.card.material_pending")}
            </div>
            <div className="text-lg">{pending}</div>
          </div>
          <div className="bg-white rounded-lg shadow-sm p-4 text-center border">
            <div className="font-semibold text-gray-800">
              {t("material.tracking.card.material_completed")}
            </div>
            <div className="text-lg">{received}</div>
          </div>
        </div>

        <div className={`grid gap-3 ${showMore ? "grid-cols-2" : ""}`}>
          {showMore &&
            material?.bom?.bomItems?.map((item) => (
              <div
                key={item.id}
                className="bg-white shadow-md rounded-lg p-4 mb-4 border"
              >
                <div className="flex justify-between items-center mb-4">
                  <span className="font-bold">
                    {t("material.tracking.card.material_name")}:
                  </span>
                  <span>
                    {item.material?.fabric?.name || item.material?.trim?.name}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <span className="font-bold">
                    {t("material.tracking.card.type")}:
                  </span>
                  <span>{item.material?.type}</span>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <span className="font-bold">
                    {t("material.tracking.card.in_house_date")}:
                  </span>
                  <span>
                    {moment(item?.inHouseDate, "YYYY-MM-DD").format(
                      "DD-MM-YYYY"
                    )}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <span className="font-bold">
                    {t("material.tracking.card.actual_date")}:
                  </span>
                  <span>
                    {item?.materialReceivable?.updatedAt
                      ? moment(item?.materialReceivable?.updatedAt).format(
                          "DD-MM-YYYY"
                        )
                      : "-"}
                  </span>
                </div>

                <div className="grid grid-cols-3 gap-6 text-center bg-blue-50 p-2 rounded-md">
                  <div>
                    <span className="font-bold">
                      {t("material.tracking.card.received")}
                    </span>
                    <div>{item.materialReceivable?.receivedQuantity || 0}</div>
                  </div>
                  <div>
                    <span className="font-bold">
                      {t("material.tracking.card.rejected")}
                    </span>
                    <div>{item.materialReceivable?.rejectedQuantity || 0}</div>
                  </div>
                  <div>
                    <span className="font-bold">
                      {t("material.tracking.card.pending")}
                    </span>
                    <div>
                      {(item?.materialReceivable?.quantity ??
                        item.quantity +
                          ((item.extra ?? 0) * item.quantity) / 100) -
                        ((item?.materialReceivable?.receivedQuantity ?? 0) +
                          (item?.materialReceivable?.rejectedQuantity ?? 0))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="flex justify-end px-6">
          <span
            className="font-bold text-sm cursor-pointer"
            style={{ color: palette.secondary }}
            onClick={toggleShowMore}
          >
            {showMore
              ? t("material.tracking.card.see_less_info")
              : t("material.tracking.card.see_more_info")}
          </span>
        </div>
      </div>
    </>
  );
};

export default MaterialTrackingCard;

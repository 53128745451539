const palette = {
    //primary
    primary: '#2874F9',
    primary1: '#EEF4FF',
    primary2: '#D4E3FE',
    primary3: '#A9C7FD',
    primary4: '#7EACFB',
    primary5: '#2874F9',
    primary6: '#205DC7',
    primary7: '#184695',
    primary8: '#0C234B',
    primary9: '#081732',
    //secondary
    secondary: '#CC6A4C',
    secondary1: '#FFF6F3',
    secondary2: '#FFCEBF',
    secondary3: '#FFC2AF',
    secondary4: '#FF9D7F',
    secondary5: '#FF845F',
    secondary6: '#CC6A4C',
    secondary7: '#994F39',
    secondary8: '#663526',
    secondary9: '#331A13',
    //stateColor
    success: '#13B466',
    error: '#D12E2E',
    warn: '#0054F8',
    //Text
    text: '#040C19',
    text1: '#FFFFFF',
    text2: '#CDCED1',
    text3: '#686D75',
    text4: '#363D47',
    text5: '#040C19',
    //background
    background: '#F8FBFF',
    backgroundCeramic: '#FFFFFF',
    backgroundGhostWhite: '#FAFAFA',
    backgroundPaleGrey: '#FAFAFA',
    backgroundAquaHaze: '#EEF4FF',
    backgroundZircon: '#F6F7F9',
    backgroundOrange: '#FFF4EE',
    backgroundPurple: '#E5E6FD',
    backgroundLightGreen: '#DDEBE3',
    backgroundLightBlue: '#D6E0F3',
    // Neutral Color
    black1: '#F7F7F7',
    black2: '#EEEEEE',
    black3: '#DDDDDD',
    black4: '#CBCBCB',
    black5: '#BABABA',
    black6: '#989898',
    black7: '#757575',
    black8: '#646464',
    black9: '#424242',
    black10: '#323232',
    // Separator Color
    satinLinen: '#E5E5E6',
    charcoal: '#2E3338',
    osloGrey: '#CACACA',
    // hover
    hover: '#EEF4FF',
  
    transparent: '#00000000',
    green: '#2A8E52',
    backgroundLightRed: '#EBDDDD',
    red: '#8E2A2A',
    lightRed: '#df6d6d',
    lightWaring: '#FFE57F',
    lightInfo: '#A9C7FD',
    white: "#fff"
  };
  
  export default palette;
  
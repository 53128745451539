import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { PERMISSION_MODULE, PERMISSION_ACTION } from "../helper";

export default function RolePermission({
  module,
  action,
  actions,
  children,
}: {
  module: keyof typeof PERMISSION_MODULE;
  actions?: (keyof typeof PERMISSION_ACTION)[];
  action?: keyof typeof PERMISSION_ACTION;
  children: any;
}) {
  // const { userPermissions } = useSelector(
  //   (rootState: RootState) => rootState.userPermissions
  // );

  const storedPermissionString = localStorage.getItem("userPermissionState");

  const userPermissions = JSON.parse(storedPermissionString as string);

  if (action) {
    return userPermissions[action]?.includes(module) ? children : null;
  } else {
    return actions?.find((action) => userPermissions[action]?.includes(module))
      ? children
      : null;
  }
}

import * as ExcelJS from "exceljs";

const sanitizeRangeName = (name: string) => {
  return name
    .replace(/[^A-Za-z0-9]/g, "_") // Replace invalid characters with underscores
    .replace(/^[^A-Za-z_]/, "_"); // Ensure the name starts with a valid character
};

const sanitizeNameForTax = (name: string) => {
  return name
    .replace(/[^A-Za-z0-9]/g, "_") // Replace invalid characters with underscores
    .replace(/_+/g, "_") // Collapse multiple underscores
    .replace(/^_|_$/g, ""); // Remove leading/trailing underscores
};

export const generateTemplate = async (data: any) => {
  console.log(data);
  try {
    const workbook = new ExcelJS.Workbook();
    const ws: any = workbook.addWorksheet("Template");

    const headers = [
      "Customer Name",
      "Branch",
      "Buyer PO Number",
      "Reference",
      "Expected Margin %",
      "Merchandiser",
      "Approver",
      "SO Date",
      "Expected Date",
      "Payment terms",
      "Delivery Type",
      "Product Type",
      "Currency",
      "Name",
      "Unit",
      "HSN Code",
      "Category",
      "Sub Category",
      "Quantity",
      "Rate",
      "Tax",
      "Tax %",
      "Discount %",
      "Shipment Charges",
      "Customer Note",
      "TnCs",
      "Attachments",
    ];
    //a-l
    //M-U
    //V-z
    // Insert a row at the start (row 1)
    ws.addRow(1);

    // Merge cells for SALES ORDER DETAILS
    ws.mergeCells("A1:M1");
    ws.getCell("A1").value = "SALES ORDER DETAILS";
    ws.getCell("A1").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("A1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFCC6A4C" },
    }; // Background color
    ws.getCell("A1").font = { color: { argb: "FFFFFF" }, bold: true, size: 16 };
    ws.getCell("A1").border = {
      top: { style: "thin", color: { argb: "FF000000" } }, // Top border
      left: { style: "thin", color: { argb: "FF000000" } }, // Left border
      bottom: { style: "thin", color: { argb: "FF000000" } }, // Bottom border
      right: { style: "thin", color: { argb: "FF000000" } }, // Right border
    }; // Text color (white)

    // Merge cells for STYLE DETAILS
    ws.mergeCells("N1:V1");
    ws.getCell("N1").value = "STYLE DETAILS";
    ws.getCell("N1").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("N1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFCC6A4C" },
    }; // Background color
    ws.getCell("N1").font = { color: { argb: "FFFFFF" }, bold: true, size: 16 }; // Text color (white)
    ws.getCell("N1").border = {
      top: { style: "thin", color: { argb: "FF000000" } }, // Top border
      left: { style: "thin", color: { argb: "FF000000" } }, // Left border
      bottom: { style: "thin", color: { argb: "FF000000" } }, // Bottom border
      right: { style: "thin", color: { argb: "FF000000" } }, // Right border
    };
    // Merge cells for BILLING DETAILS
    ws.mergeCells("W1:AB1");
    ws.getCell("W1").value = "BILLING DETAILS";
    ws.getCell("W1").alignment = { horizontal: "center", vertical: "middle" };
    ws.getCell("W1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFCC6A4C" },
    }; // Background color
    ws.getCell("W1").font = { color: { argb: "FFFFFF" }, bold: true, size: 16 }; // Text color (white)
    ws.getCell("W1").border = {
      top: { style: "thin", color: { argb: "FF000000" } }, // Top border
      left: { style: "thin", color: { argb: "FF000000" } }, // Left border
      bottom: { style: "thin", color: { argb: "FF000000" } }, // Bottom border
      right: { style: "thin", color: { argb: "FF000000" } }, // Right border
    };
    ws.addRow(headers);
    ws.columns.forEach((col: any) => (col.width = 18));

    const lookupSheet = workbook.addWorksheet("_Lookup");
    lookupSheet.state = "hidden";

    let currentRow = 1;

    // Add categories and subcategories to lookup
    const categoryCells: any[] = [];
    data.categories.forEach((category: any) => {
      const sanitizedCategoryName = sanitizeRangeName(category.name);
      lookupSheet.getCell(`A${currentRow}`).value = category.name;
      categoryCells.push(`_Lookup!$A$${currentRow}`);

      category.subCategories.forEach((sub: any, index: number) => {
        lookupSheet.getCell(`B${currentRow + index}`).value = sub.name;
      });

      const lastSubRow = currentRow + category.subCategories.length - 1;
      workbook.definedNames.add(
        `_Lookup!$B$${currentRow}:$B$${lastSubRow}`,
        `Sub_${sanitizedCategoryName}`
      );
      currentRow += Math.max(category.subCategories.length, 1);
    });

    // Add other dropdown options to the lookup sheet
    const addLookupColumn = (
      columnLetter: string,
      dataList: any[],
      rangeName: string
    ) => {
      console.log(columnLetter, dataList);
      const startRow = currentRow;
      dataList.forEach((item: any, index: number) => {
        lookupSheet.getCell(`${columnLetter}${currentRow + index}`).value =
          item.name;
      });
      const endRow = currentRow + dataList.length - 1;
      workbook.definedNames.add(
        `_Lookup!$${columnLetter}$${startRow}:$${columnLetter}$${endRow}`,
        rangeName
      );
      currentRow += dataList.length;
    };

    const indirectFormula = (
      col: any,
      row: any,
      suffix: string,
      elseValue: string | null
    ) => {
      return `=IF(${col}${row}<>"", IF(ISERROR(INDIRECT("${suffix}"&SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(${col}${row}," ","_"),"-","_"),"%",""),"@","_"),"&","_"))), ${
        elseValue === null ? "''" : elseValue
      }, INDIRECT("${suffix}"&SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(${col}${row}," ","_"),"-","_"),"%",""),"@","_"),"&","_"))), ${
        elseValue === null ? "''" : elseValue
      })`;
    };

    addLookupColumn("C", data.paymentTerms, "PaymentTerms");
    addLookupColumn("D", data.deliveryTypes, "DeliveryTypes");
    addLookupColumn("E", data.productTypes, "ProductTypes");
    addLookupColumn("F", data.currencies, "Currencies");
    addLookupColumn("G", data.items, "Items");
    addLookupColumn("H", data.taxes, "Taxes");
    addLookupColumn("I", data.units, "Units");
    addLookupColumn("J", data.buyers, "CustomerName");
    addLookupColumn("K", data.branches, "Branch");
    addLookupColumn("P", data.categories, "Categories");
    addLookupColumn("S", data.user, "Users");

    data.items.forEach((item: any) => {
      // Define names for item attributes
      lookupSheet.getCell(`L${currentRow}`).value = item.unit.name; // Unit
      lookupSheet.getCell(`M${currentRow}`).value = item.hsnCode; // HSN Code
      lookupSheet.getCell(`N${currentRow}`).value = item.category.name; // Category
      lookupSheet.getCell(`O${currentRow}`).value = item.subCategory.name; // Subcategory

      // Define names for this item
      const sanitizedItemName = sanitizeRangeName(item.name);
      workbook.definedNames.add(
        `_Lookup!$L$${currentRow}`,
        `ITEM_UNIT_${sanitizedItemName}`
      );
      workbook.definedNames.add(
        `_Lookup!$M$${currentRow}`,
        `ITEM_HSNCODE_${sanitizedItemName}`
      );
      workbook.definedNames.add(
        `_Lookup!$N$${currentRow}`,
        `ITEM_CATEGORY_${sanitizedItemName}`
      );
      workbook.definedNames.add(
        `_Lookup!$O$${currentRow}`,
        `ITEM_SUBCATEGORY_${sanitizedItemName}`
      );

      currentRow++;
    });

    let currentTaxRow = 1; // Starting row for tax data in _Lookup sheet
    data.taxes.forEach((tax: any) => {
      const sanitizedTaxName = sanitizeNameForTax(tax.name);
      lookupSheet.getCell(`Q${currentTaxRow}`).value = sanitizedTaxName;
      lookupSheet.getCell(`R${currentTaxRow}`).value = tax.percentage;

      // Create a defined name for this tax percentage
      workbook.definedNames.add(
        `_Lookup!$R$${currentTaxRow}`,
        `TAX_${sanitizedTaxName}`
      );

      currentTaxRow++;
    });

    const indirectFormulaForValue = (
      col: any,
      row: any,
      suffix: string,
      elseValue: string
    ) => {
      return `=IF(${col}${row}<>"", 
            IF(ISERROR(INDIRECT("${suffix}"&SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(${col}${row}," ","_"),"-","_"),"%",""),"@","_"),"&","_"))), 
               "${elseValue}", 
               INDIRECT("${suffix}"&SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(${col}${row}," ","_"),"-","_"),"%",""),"@","_"),"&","_"))), 
            "${elseValue}"
          )`;
    };

    for (let row = 3; row <= 200; row++) {
      ws.getCell(`V${row}`).value = {
        formula: indirectFormulaForValue("U", row, "TAX_", ""),
      };
      ws.dataValidations.add(`O${row}`, {
        type: "list",
        formulae: [indirectFormula("N", row, "ITEM_UNIT_", "Units")],
        allowBlank: false,
      });
      ws.getCell(`O${row}`).value = {
        formula: indirectFormulaForValue("N", row, "ITEM_UNIT_", ""),
      };

      // Add dropdowns for categories and subcategories
      ws.dataValidations.add(`Q${row}`, {
        type: "list",
        formulae: [indirectFormula("N", row, "ITEM_CATEGORY_", "Categories")],
        allowBlank: false,
      });
      ws.getCell(`Q${row}`).value = {
        formula: indirectFormulaForValue("N", row, "ITEM_CATEGORY_", ""),
      };

      ws.getCell(`P${row}`).value = {
        formula: indirectFormulaForValue("N", row, "ITEM_HSNCODE_", ""),
      };

      ws.dataValidations.add(`R${row}`, {
        type: "list",
        formulae: [
          // `=IF(M${row}<>"", INDIRECT("ITEM_SUBCATEGORY_"&SUBSTITUTE(SUBSTITUTE(M${row}," ","_"),"-","_")), INDIRECT("Sub_"&SUBSTITUTE(SUBSTITUTE(P${row}," ","_"),"-","_")))`,
          `=IF(N${row}<>"", 
    IF(ISERROR(INDIRECT("ITEM_SUBCATEGORY_"&SUBSTITUTE(SUBSTITUTE(N${row}," ","_"),"-","_"))), 
        "", INDIRECT("ITEM_SUBCATEGORY_"&SUBSTITUTE(SUBSTITUTE(N${row}," ","_"),"-","_"))),
    INDIRECT("Sub_"&SUBSTITUTE(SUBSTITUTE(Q${row}," ","_"),"-","_"))
)`,
        ],
        allowBlank: false,
      });
      ws.getCell(`R${row}`).value = {
        formula: indirectFormulaForValue("N", row, "ITEM_SUBCATEGORY_", ""),
      };
    }

    // Add dropdowns for other columns
    const columnToRangeMapping = {
      A: "CustomerName",
      B: "Branch",
      F: "Users",
      G: "Users",
      J: "PaymentTerms",
      K: "DeliveryTypes",
      L: "ProductTypes",
      M: "Currencies",
      N: "Items",
      U: "Taxes",
    };

    Object.entries(columnToRangeMapping).forEach(([col, rangeName]) => {
      ws.dataValidations.add(`${col}3:${col}200`, {
        type: "list",
        formulae: [`=${rangeName}`],
        allowBlank: false,
      });
    });

    // Style the header row
    ws.getRow(2).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFCC6A4C" },
      border: {
        top: { style: "thin", color: { argb: "FF000000" } }, // Top border
        left: { style: "thin", color: { argb: "FF000000" } }, // Left border
        bottom: { style: "thin", color: { argb: "FF000000" } }, // Bottom border
        right: { style: "thin", color: { argb: "FF000000" } }, // Right border
      },
    };
    ws.getRow(2).font = {
      bold: true,
      name: "Arial",
      size: 14,
      color: { argb: "FFFFFFFF" },
    };

    ws.eachRow((row: any) => {
      if (row < 3) return;
      row.eachCell((cell: any) => {
        cell.font = { name: "Arial", size: 10 };
        cell.alignment = { horizontal: "center" };
        cell.border = {
          top: { style: "thin", color: { argb: "FF000000" } }, // Top border
          left: { style: "thin", color: { argb: "FF000000" } }, // Left border
          bottom: { style: "thin", color: { argb: "FF000000" } }, // Bottom border
          right: { style: "thin", color: { argb: "FF000000" } }, // Right border
        };
      });
    });

    for (let row = 3; row <= 200; row++) {
      ws.getCell(`E${row}`).numFmt = "0"; // Format as integer (no decimal places)
      ws.getCell(`S${row}`).numFmt = "0"; // Format as integer (no decimal places)
    }

    // Apply decimal format for Rate (Column S)
    for (let row = 3; row <= 200; row++) {
      ws.getCell(`T${row}`).numFmt = "0.00"; // Format as decimal with 2 decimal places
      ws.getCell(`U${row}`).numFmt = "0.00"; // Format as decimal with 2 decimal places
      ws.getCell(`V${row}`).numFmt = "0.00"; // Format as decimal with 2 decimal places
    }

    // Apply date format for SO Date (Column H)
    for (let row = 3; row <= 200; row++) {
      ws.getCell(`H${row}`).numFmt = "DD/MM/YYYY"; // Format as date (DD/MM/YYYY)
      ws.getCell(`I${row}`).numFmt = "DD/MM/YYYY"; // Format as date (DD/MM/YYYY)
    }

    // Generate the Excel file
    const excelBuffer = await workbook.xlsx.writeBuffer();
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const excelUrl = URL.createObjectURL(excelBlob);
    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "Bulk_Upload_Template.xlsx";
    document.body.appendChild(link);
    link.click();

    URL.revokeObjectURL(excelUrl);
    document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  }
};

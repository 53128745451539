import { CreateJobWorkSupplierDto } from "../../types/jobWorkSupplier";
import { orderApi } from "./orderService";
import { ORDER_SERVICE_API } from ".";
;

const jobWorkSupplierUrl = ORDER_SERVICE_API.JOB_WORK_SUPPLIER;

export const getJobWorkSuppliers = async () => {
    return orderApi.get(`${jobWorkSupplierUrl}/all`);
}

export const createJobWorkSupplier = async (payload: CreateJobWorkSupplierDto) => {
    return orderApi.post(`${jobWorkSupplierUrl}/create`, payload);
}

export const bulkUploadJobWorkSuppliers = async (jobWorkSuppliers: CreateJobWorkSupplierDto[]) => {
    return orderApi.post(`${jobWorkSupplierUrl}/bulk`, jobWorkSuppliers);
};
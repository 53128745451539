import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IReceivableListItem } from '../../../../types/groyyo_plus/materialRecievable';
import { getBomItem } from '../../../../services/orderServiceApi/bomApi';
import { sourcingRoute } from '../../../../routes';
import { IoChevronBackOutline } from "react-icons/io5";
import { Skeleton } from 'antd';
import IssuedListItem from './IssuedListItem';
import { MaterialStatusType } from '../../../../constants/enums';
import { useTranslation } from 'react-i18next';

const IssuedList = () => {
  const { bomId, salesOrderItemId, salesOrderId } = useParams<{ bomId: string, salesOrderItemId: string, salesOrderId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [issuedList, setIssuedList] = useState<IReceivableListItem[]>([]);
  const navigate = useNavigate();
  const { t }: any = useTranslation();

  const fetchBomItem = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await getBomItem({ bomItemId: +bomId! });
      const responseData = response?.data;

      if (responseData?.status) {
        setIssuedList(responseData?.result);
      }
    } catch (error) {
      console.error(t('material.issued.list.errors.fetch_failed'), error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (bomId) {
      fetchBomItem();
    }
  }, [bomId]);

  return (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-bold flex flex-row gap-2 items-center">
        <span
          onClick={() =>
            navigate(`${sourcingRoute}/material-issued/sales-order-item/${+salesOrderId!}?materialStatusType=${MaterialStatusType.ISSUED}`)
          }
          className="cursor-pointer"
        >
          <IoChevronBackOutline style={{ fontWeight: "bold" }} />
        </span>
        <p>{t('material.issued.list.title')}</p>
      </div>
      {loading ? (
        Array.from({ length: 2 }).map((_, index) => (
          <Skeleton.Input
            key={index}
            active
            style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "50vh" }}
          />
        ))
      ) : (
        <>
          {issuedList?.length > 0 ? (
            issuedList.map((item) => (
              <IssuedListItem
                key={item?.id}
                issuedListItem={item}
                salesOrderItemId={+salesOrderItemId!}
              />
            ))
          ) : (
            <p>{t('material.issued.list.no_data')}</p>
          )}
        </>
      )}
    </div>
  );
};

export default IssuedList;
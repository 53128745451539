import { Button, Form, Input, message, Modal, Upload } from 'antd'
import React, { useState } from 'react'
import CustomDropdownWithAdd from '../../../../../components/CustomDropdownWithAdd'
import CustomDropdownWithAddById from '../../../../../components/CustomDropdownWithAddById'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux/store'
import { resetItem, setItem, setItemWithId } from '../../../../../redux/order/createSalesOrderSlice'
import { createUnit, getUnits } from '../../../../../services/orderServiceApi/unitApi'
import { createCategory, getCategories } from '../../../../../services/orderServiceApi/categoriesApi'
import { createSubCategory, getSubCategories } from '../../../../../services/orderServiceApi/subCategoriesApi'
import { createItem, uploadFile } from '../../../../../services/orderServiceApi/salesOrderApi'
import { UploadOutlined } from '@ant-design/icons';
import palette from '../../../../../constants/pallete'

interface ICreateNewItemModal {
    isCreateModalOpen: boolean;
    setIsCreateModalOpen: (val: boolean) => void;
    fetchUpdatedList: () => void;
}

const CreateNewItemModal = ({ isCreateModalOpen, setIsCreateModalOpen, fetchUpdatedList }:ICreateNewItemModal) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    
    const formData = useSelector((state: RootState) => state.createSalesOrder.itemDetail.item);

    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const handleCreateCancel = () => {
        setIsCreateModalOpen(false);
        dispatch(resetItem());
        form.resetFields();
      };

      const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(setItem({ name: value, field: name as "name" | "hsnCode" | "imageUrl" }));
      };

      const createItemWrapper = (createFunction: (data: any) => Promise<any>) => {
        return async (data: any) => {
          await createFunction(data);
        };
      };

      const beforeUpload = (file: any) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          message.error('Image must be smaller than 5MB!');
        }
        return isLt5M;
      };
    
      const handleImageUpload = async (info: any) => {
        const file = info.file;
        if (!file || !beforeUpload(file)) return;
    
        const formData = new FormData();
        formData.append('file', file);
    
        try {
          const response = await uploadFile(formData);
          const responseData = response?.data;
          const uploadedUrl = responseData?.result?.imageUrl;
          if (uploadedUrl) {
            dispatch(setItem({ name: uploadedUrl, field: "imageUrl" }));
            message.success('Image uploaded successfully');
          }
        } catch (error) {
          message.error('Image upload failed');
          console.error('Upload error:', error);
        }
      };

    const handleSubmit = async () => {
        setFormSubmitted(true);
        if (!formData.imageUrl) {
          message.error("Please upload an image.");
          return;
        }


        const payload = {
          name: formData?.name,
          unitId: formData?.unit?.id!,
          categoryId: formData?.category?.id!,
          imageUrl: formData?.imageUrl,
          hsnCode: formData?.hsnCode,
          subCategoryId: formData?.subCategory?.id!
        };

        try {
          const response = await createItem(payload);
          const responseData = response?.data;

          if(responseData?.status){
            message.success(responseData?.message ?? "Item Created Successfully");
            handleCreateCancel();
            fetchUpdatedList();
          }
        } catch (error) {
          console.error(error)
        }
      };
  
  return (
    <Modal
        title="Add New Item"
        open={isCreateModalOpen}
        onCancel={handleCreateCancel}
        onClose={handleCreateCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Form.Item label="Name" name="name" rules={[{ required: true, message: "Name is required" }]}>
            <Input
              name="name"
              onChange={handleFormChange}
              placeholder="Enter name"
              value={formData?.name}
            />
          </Form.Item>
          <Form.Item label="HSN Code" name="hsnCode" rules={[{ required: true, message: "HSN Code is required" }]}>
            <Input
              name="hsnCode"
              onChange={handleFormChange}
              placeholder="Enter HSN Code"
              value={formData?.hsnCode}
            />
          </Form.Item>
          <CustomDropdownWithAdd
            form={form}
            label="Unit"
            placeholder="Search or Select Unit"
            fetchItems={getUnits}
            addItem={createItemWrapper(createUnit)}
            onSelect={(item) => dispatch(setItemWithId({ id: item.id, name: item.name, field: "unit" }))}
            modalFields={[
              { name: 'name', label: 'Unit Name', required: true }
            ]}
            formItemName='unit'
            required={true}
            value={formData.unit?.name}
          />
          <CustomDropdownWithAdd
            form={form}
            label="Category"
            placeholder="Search or Select Category"
            fetchItems={getCategories}
            addItem={createItemWrapper(createCategory)}
            onSelect={(item) => dispatch(setItemWithId({ id: item.id, name: item.name, field: "category" }))}
            modalFields={[
              { name: 'name', label: 'Category Name', required: true }
            ]}
            formItemName='category'
            required={true}
            value={formData.category?.name}
          />
          <CustomDropdownWithAddById
            label="Sub Category"
            placeholder="Search or Select Sub Category"
            fetchItems={() => getSubCategories(formData.category?.id)}
            addItem={createItemWrapper(createSubCategory)}
            onSelect={(item) => dispatch(setItemWithId({ id: item.id, name: item.name, field: "subCategory" }))}
            modalFields={[
              { name: 'name', label: 'Sub Category Name', required: true }
            ]}
            formItemName="subCategory"
            required={true}
            form={form}
            parentId={formData.category?.id!}
            value={formData.subCategory?.name}
          />


          <Form.Item
            required
            validateStatus={!formData.imageUrl ? "error" : ""}
          // help={!formData.imageUrl && formSubmitted ? "Please upload an image" : ""}
          >
            <div className="flex flex-col items-center space-y-4">
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={handleImageUpload}
                className="flex items-center"
              >
                <Button
                  type="primary"
                  icon={<UploadOutlined />}
                  className="flex items-center px-5 py-2.5 text-white font-semibold transition-colors duration-300"
                  style={{ backgroundColor: palette.secondary }}
                >
                  Upload Image
                </Button>
              </Upload>

              {formData.imageUrl && (
                <div
                  className="relative border border-gray-300 rounded-lg overflow-hidden w-32 h-32 transition-transform duration-500 ease-in-out opacity-0 transform scale-90"
                  style={{ opacity: formData.imageUrl ? 1 : 0, transform: formData.imageUrl ? 'scale(1)' : 'scale(0.9)' }}
                >
                  <img
                    src={formData.imageUrl}
                    alt="Preview"
                    className="w-full h-full object-cover rounded-lg transition-opacity duration-500 ease-in-out"
                  />
                </div>
              )}
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: palette.secondary, width: '100%' }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
  )
}

export default CreateNewItemModal
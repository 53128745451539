import React, { useEffect, useState } from "react";
import { Form, Input, Button, DatePicker, Row, Col, message } from "antd";
import { formatDate, formatDateToDDMMYYYY } from "../../../../utilities/helper";
import {
  IMaterialReceivableRequestBody,
  IReceivableListItem,
} from "../../../../types/groyyo_plus/materialRecievable";
import { createMaterialRecievable } from "../../../../services/orderServiceApi/materialRecievableApi";
import palette from "../../../../constants/pallete";
import HistoryModal from "../HistoryModal";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { MaterialStatusType } from "../../../../constants/enums";
import RolePermission from "../../../../utilities/RolePermission/RolePermission";
import { useTranslation } from "react-i18next";

interface IRecievableListItemProps {
  recievableListItem: IReceivableListItem;
  salesOrderItemId: number;
}

const RecievableListItem: React.FC<IRecievableListItemProps> = ({
  recievableListItem,
  salesOrderItemId,
}) => {
  const { t }: any = useTranslation();
  const [form] = Form.useForm();

  const [searchParams] = useSearchParams();
  const materialStatusType = searchParams.get("materialStatusType");

  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [receivedQuantity, setReceivedQuantity] = useState<number>(0);
  const [pendingQuantity, setPendingQuantity] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (recievableListItem) {
      const { quantity, extra, materialReceivable } = recievableListItem;
      const calculatedTotalQuantity = quantity + (quantity * extra) / 100;
      const calculatedReceivedQuantity =
        materialReceivable?.rejectedQuantity ||
        materialReceivable?.receivedQuantity
          ? materialReceivable?.receivedQuantity +
            materialReceivable?.rejectedQuantity
          : 0;
      const calculatedPendingQuantity =
        calculatedTotalQuantity - calculatedReceivedQuantity;

      setTotalQuantity(calculatedTotalQuantity);
      setReceivedQuantity(calculatedReceivedQuantity);
      setPendingQuantity(calculatedPendingQuantity);
    }
  }, [recievableListItem]);

  const showModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const handleSubmit = async (values: {
    acceptedQuantity: number;
    rejectedQuantity: number;
    receivedDate: string;
  }) => {
    const payload: IMaterialReceivableRequestBody = {
      bomItemId: recievableListItem?.id,
      receivedDate: formatDate(values?.receivedDate),
      receivedQuantity: +values?.acceptedQuantity,
      rejectedQuantity: +values?.rejectedQuantity,
      salesOrderItemId,
    };

    try {
      const response = await createMaterialRecievable(payload);
      const responseData = response?.data;

      if (responseData?.status) {
        message.success(
          t("material.receivable.recievable_list_item.messages.success")
        );
        setReceivedQuantity(
          receivedQuantity + payload.receivedQuantity + payload.rejectedQuantity
        );
        setPendingQuantity(
          pendingQuantity - payload?.receivedQuantity - payload.rejectedQuantity
        );
        form.resetFields();
      } else {
        message.error(
          responseData?.message ||
            t(
              "material.receivable.recievable_list_item.messages.failed_to_save"
            )
        );
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(
        t("material.receivable.recievable_list_item.messages.error")
      );
    }
  };

  const handleError = (errorInfo: any): void => {
    console.error("Form validation failed:", errorInfo);
    message.error(
      t(
        "material.receivable.recievable_list_item.form_errors.validation_failed"
      )
    );
  };

  return (
    <div className="flex flex-col shadow-md rounded-lg bg-white p-3 gap-3">
      <div className="flex flex-col gap-3 p-2">
        <div className="flex flex-row gap-4">
          <img
            src={
              recievableListItem?.material?.type === "Fabric"
                ? recievableListItem?.material?.fabric?.imageUrl ||
                  "/svg/NoImage.svg"
                : recievableListItem?.material?.trim?.imageUrl ||
                  "/svg/NoImage.svg"
            }
            alt={
              recievableListItem?.material?.type === "Fabric"
                ? recievableListItem?.material?.fabric?.name
                : recievableListItem?.material?.trim?.name
            }
            className="!w-[3rem] !h-[3rem] rounded-md"
          />
          <div className="flex flex-col justify-start">
            <p className="font-semibold">
              {recievableListItem?.material?.type === "Fabric"
                ? recievableListItem?.material?.fabric?.name
                : recievableListItem?.material?.trim?.name}
            </p>
            <p className="text-xs">
              {recievableListItem?.material?.type === "Fabric"
                ? recievableListItem?.material?.fabric?.composition
                : recievableListItem?.material?.trim?.description}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-start gap-2 text-sm">
          <span>
            {t(
              "material.receivable.recievable_list_item.labels.expected_delivery_date"
            )}
            :
          </span>
          <span>{formatDateToDDMMYYYY(recievableListItem?.inHouseDate)}</span>
        </div>
      </div>

      <div className="flex justify-between items-center border border-gray-300 rounded-lg p-4 bg-gray-50">
        <div className="flex-1 text-center">
          <div className="font-semibold">
            {t(
              "material.receivable.recievable_list_item.labels.total_ordered_quantity"
            )}
          </div>
          <div>
            {totalQuantity?.toFixed(2)} {recievableListItem?.unit?.name}
          </div>
        </div>
        <div className="flex-1 text-center">
          <div className="font-semibold">
            {t(
              "material.receivable.recievable_list_item.labels.received_quantity"
            )}
          </div>
          <div>
            {receivedQuantity?.toFixed(2)} {recievableListItem?.unit?.name}
          </div>
        </div>
        <div className="flex-1 text-center">
          <div className="font-semibold">
            {t(
              "material.receivable.recievable_list_item.labels.pending_quantity"
            )}
          </div>
          <div>
            {pendingQuantity?.toFixed(2)} {recievableListItem?.unit?.name}
          </div>
        </div>
      </div>

      <div
        className="flex flex-row-reverse font-semibold cursor-pointer"
        style={{ color: palette?.secondary }}
        onClick={showModal}
      >
        {t("material.receivable.recievable_list_item.labels.view_history")}
      </div>
      <RolePermission module="MATERIAL_RECEIVABLE" action="EDIT">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleError}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label={t(
                  "material.receivable.recievable_list_item.fields.accepted_quantity"
                )}
                name="acceptedQuantity"
                rules={[
                  {
                    required: true,
                    message: t(
                      "material.receivable.recievable_list_item.validation_messages.accepted_quantity_required"
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "material.receivable.recievable_list_item.fields.accepted_quantity"
                  )}
                  addonAfter={recievableListItem?.unit?.name}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  "material.receivable.recievable_list_item.fields.rejected_quantity"
                )}
                name="rejectedQuantity"
                rules={[
                  {
                    required: true,
                    message: t(
                      "material.receivable.recievable_list_item.validation_messages.rejected_quantity_required"
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "material.receivable.recievable_list_item.fields.rejected_quantity"
                  )}
                  addonAfter={recievableListItem?.unit?.name}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t(
                  "material.receivable.recievable_list_item.fields.received_date"
                )}
                name="receivedDate"
                rules={[
                  {
                    required: true,
                    message: t(
                      "material.receivable.recievable_list_item.validation_messages.received_date_required"
                    ),
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  placeholder={t(
                    "material.receivable.recievable_list_item.fields.received_date"
                  )}
                  disabledDate={(current) =>
                    current && current < dayjs().startOf("day")
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#d35d44",
                  borderColor: "#d35d44",
                  borderRadius: "1rem",
                  width: "100%",
                }}
              >
                {t("material.receivable.recievable_list_item.buttons.save")}
              </Button>
            </Col>
          </Row>
        </Form>
      </RolePermission>
      {isModalVisible && (
        <HistoryModal
          visible={isModalVisible}
          onClose={closeModal}
          history={recievableListItem?.materialReceivable?.history! as any}
          type={materialStatusType as MaterialStatusType}
        />
      )}
    </div>
  );
};

export default RecievableListItem;

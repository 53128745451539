export enum CreateSalesOrderTabEnum {
    ORDER_DETAILS = 'ORDER_DETAILS',
    ITEM_DETAILS = 'ITEM_DETAILS',
    BILLING_DETAILS = 'BILLING_DETAILS',
}


export enum SalesOrderCreationStatus {
    SALES_ORDER_ITEM_PENDING = 'SALES_ORDER_ITEM_PENDING',
    SALES_ORDER_BILLING_PENDING = 'SALES_ORDER_BILLING_PENDING',
    BOM_PENDING = 'BOM_PENDING',
    ORDER_DETAIL_PENDING = 'ORDER_DETAIL_PENDING'
}

export enum BOMCreationType {
    CREATED = "created",
    YET_TO_CREATE = "yetToCreate"
}

export enum MaterialStatusType {
    RECIEVED = "recieved",
    ISSUED = "issued",
}
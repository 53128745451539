export enum ORDER_SERVICE_API {
  SALES_ORDER = "/sales-order",
  SALES_ORDER_ITEM = "/sales-order-item",
  ITEM = "/item",
  UNIT = "/unit",
  BRANCH = "/branch",
  PAYMENT_TERM = "/payment-term",
  DELIVERY_TYPE = "/delivery-type",
  PRODUCT_TYPE = "/product-type",
  JOB_WORK_SUPPLIER = "/job-work-supplier",
  TAX = "/tax",
  CATEGORY = "/category",
  SUB_CATEGORY = "/sub-category",
  BUYER = "/buyer",
  BOM = "/bom",
  FABRIC = "/fabric",
  TRIM = "/trim",
  SUPPLIER = "/supplier",
  MATERIAL_RECEIVABLE = "material-receivable",
  MATERIAL_ISSUED = "material-issued",
  DEPARTMENT = "department",
  DISPATCH = "/dispatch",
  BULK = "/bulk",
  TNA = "/tna",
  TNA_TEMPLATE = "/tna-template",
  TNA_PROCESS = "/tna-process",
  DASHBOARD = "/dashboard"
}

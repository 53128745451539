import { useState } from "react";
import {
  Card,
  Upload,
  Button,
  Typography,
  Row,
  Col,
  Space,
  Alert,
  Divider,
  List,
} from "antd";
import {
  UploadOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  FileExcelOutlined,
  CheckCircleOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { sourcingRoute } from "../../../../routes";
import { useNavigate } from "react-router-dom";
import { getSoBulkUploadTemplateData } from "../../../../services/orderServiceApi/salesOrderBulkUpload";
import { generateTemplate } from "../../../../utilities/bulkOrderTemplate";
import Exceljs from "exceljs";
import { RcFile } from "antd/es/upload";
import BulkPreview from "./bulkPreview";
import { formatBulkSoData } from "./bulkDataFormat";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const SalesOrderBulkUpload = () => {
  const [dropdownData, setDropdownData] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [excelData, setExcelData] = useState<any>([]);
  const { t }: any = useTranslation();

  const fetchTemplateDetails = async () => {
    try {
      const response = await getSoBulkUploadTemplateData();
      const responseData = response?.data;

      return responseData?.result;
    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();
  const templateFeatures = [
    t("sales_order.create_bulk_order.template_card.templateFeatures.preformatted_columns_with_examples"),
    t("sales_order.create_bulk_order.template_card.templateFeatures.data_validation_rules"),
    t("sales_order.create_bulk_order.template_card.templateFeatures.import_guidelines"),
    t("sales_order.create_bulk_order.template_card.templateFeatures.sample_data_entries"),
  ];

  const downloadTemplate = async () => {
    const data = await getDropdownData();
    generateTemplate(data);
  };

  const navigateToSalesOrder = () => {
    navigate(`${sourcingRoute}/sales-order`);
  };

  const getDropdownData = async () => {
    let data = dropdownData;
    if (!data) {
      data = await fetchTemplateDetails();
      setDropdownData(data);
    }

    return data;
  };

  const formatExcelData = async (file: RcFile) => {
    const dropdownData = await getDropdownData();
    const fileBuffer = await file.arrayBuffer();
    const workbook = new Exceljs.Workbook();
    await workbook.xlsx.load(fileBuffer);
    const sheetData = await formatBulkSoData(workbook, dropdownData);
    console.log(sheetData);

    if (sheetData?.length) {
      setExcelData(sheetData);
      setIsModalOpen(true);
    }
    return false;
  };

  return (
    <div style={{ background: "#f0f2f5", padding: "24px" }}>
      <BulkPreview
        initialData={excelData}
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        dropdownData={dropdownData}
      />
      <div style={{ margin: "0 auto" }}>
        {/* Header Section */}
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <div className="flex gap-2 items-center">
            <LeftOutlined
              style={{ fontSize: "1rem", fontWeight: "bold" }}
              onClick={navigateToSalesOrder}
            />
            <h1 className="text-2xl font-bold">{t("sales_order.create_bulk_order.heading.create_sales_order")}</h1>
          </div>

          <Alert
            message={t("sales_order.create_bulk_order.disclaimer.important_notice")}
            description={t("sales_order.create_bulk_order.disclaimer.please_ensure_your_data_follows_the_template_format_for_successful_upload")}
            type="info"
            showIcon
            icon={<InfoCircleOutlined />}
            style={{ marginBottom: 12 }}
          />
        </Space>

        {/* Cards Section */}
        <Row gutter={[24, 24]}>
          {/* Template Card */}
          <Col xs={24} lg={12}>
            <Card
              title={
                <Space>
                  <DownloadOutlined />
                  <span>{t("sales_order.create_bulk_order.template_card.download_template")}</span>
                </Space>
              }
              bordered={false}
              style={{ height: "100%" }}
            >
              <List
                itemLayout="horizontal"
                dataSource={templateFeatures}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <CheckCircleOutlined style={{ color: "#52c41a" }} />
                      }
                      title={item}
                    />
                  </List.Item>
                )}
                style={{ marginBottom: 12 }}
              />

              <Divider />

              <Space direction="vertical" style={{ width: "100%" }}>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  size="large"
                  onClick={downloadTemplate}
                  block
                >
                  {t("sales_order.create_bulk_order.template_card.download_template")}
                </Button>
                <Text
                  type="secondary"
                  style={{ textAlign: "center", display: "block" }}
                >
                  {t("sales_order.create_bulk_order.template_card.download_our_standardized_template_to_ensure_proper_data_formatting")}
                </Text>
              </Space>
            </Card>
          </Col>
          {/* Upload Card */}
          <Col xs={24} lg={12}>
            <Card
              title={
                <Space>
                  <UploadOutlined />
                  <span>                {t("sales_order.create_bulk_order.upload_card.upload_data")}
</span>
                </Space>
              }
              bordered={false}
              style={{ height: "100%" }}
            >
              <Upload.Dragger
                name="file"
                accept=".xlsx,.xls"
                multiple={false}
                maxCount={1}
                beforeUpload={formatExcelData}
                style={{ marginBottom: 24 }}
              >
                <p className="ant-upload-drag-icon">
                  <FileExcelOutlined
                    style={{ fontSize: 48, color: "#40a9ff" }}
                  />
                </p>
                <p className="ant-upload-text">
                {t("sales_order.create_bulk_order.upload_card.click_or_drag_file_to_this_area_to_upload")}
                </p>
                <p className="ant-upload-hint">
                {t("sales_order.create_bulk_order.upload_card.support_for_excel_and_CSV_files_only")}
                </p>
              </Upload.Dragger>

              <Divider />

              <Button
                type="primary"
                icon={<UploadOutlined />}
                size="large"
                block
              >
                {t("sales_order.create_bulk_order.upload_card.upload_file")}
              </Button>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SalesOrderBulkUpload;

import enUS from "antd/lib/locale/en_US";
import hiIN from "antd/lib/locale/hi_IN";

export const getCurrentLocale = (language: string) => {
  switch (language) {
    case "hi":
      return hiIN;
    case "en":
      return enUS;
    default:
      return enUS;
  }
};
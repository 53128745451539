import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISingleSalesOrderResponse } from "../../types/groyyo_plus/salesOrder";

const initialState: ISingleSalesOrderResponse | null = {} as ISingleSalesOrderResponse | null;

const singleSalesOrderSlice = createSlice({
    name: "singleSalesOrder",
    initialState,
    reducers: {
        setSingleSalesOrder: (state, action) => {
            return action.payload;
        },
        clearSingleSalesOrder: () => {
            return null;
        },
        setSoDateForOrderDetail: (state, action: PayloadAction<string>) => {
            if (state) {
                state.soDate = action.payload;
            }
        },
        setExpectedDateForOrderDetail: (state, action: PayloadAction<string>) => {
            if (state) {
                state.expectedDate = action.payload;
            }
        },
        setReferenceForOrderDetail: (state, action: PayloadAction<string>) => {
            if (state) {
                state.reference = action.payload;
            }
        },
        setExpectedMarginForOrderDetail: (state, action: PayloadAction<number>) => {
            if (state) {
                state.expectedMargin = action.payload;
            }
        },
    }
});

export const {
    setSingleSalesOrder,
    clearSingleSalesOrder,
    setExpectedDateForOrderDetail, 
    setExpectedMarginForOrderDetail,
    setReferenceForOrderDetail,
    setSoDateForOrderDetail
} = singleSalesOrderSlice.actions;

export default singleSalesOrderSlice.reducer;
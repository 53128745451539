import { CreateTaxDto } from "../../types/taxes";
import { orderApi } from "./orderService";
import { ORDER_SERVICE_API } from ".";

const taxUrl = ORDER_SERVICE_API.TAX;

export const getTaxes = async () => {
    return orderApi.get(`${taxUrl}`);
}

export const createTax = async (payload: CreateTaxDto) => {
    return orderApi.post(`${taxUrl}`, {
        ...payload,
        percentage: payload?.percentage ? +payload?.percentage : 0
    });
}

export const bulkUploadTaxes = async (taxes: CreateTaxDto[]) => {
    return orderApi.post(`${taxUrl}/bulk`, taxes);
};
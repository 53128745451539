import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../utilities/helper";

const ProtectedRoute: React.FC = () => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;

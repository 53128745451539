import { useState, useEffect } from "react";
import { PERMISSION_ACTION, PERMISSION_MODULE } from "../helper";

export default function useRolePermission(
  module: keyof typeof PERMISSION_MODULE,
  action: keyof typeof PERMISSION_ACTION
) {
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const isLoaded = localStorage.getItem("permissionsLoaded") === "true";
      if (isLoaded) {
        const storedPermissionString = localStorage.getItem("userPermissionState");
        const userPermissions = storedPermissionString
          ? JSON.parse(storedPermissionString)
          : {};
        setHasPermission(userPermissions[action]?.includes(module) || false);
        setPermissionsLoaded(true);
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [module, action]);

  if (!permissionsLoaded) {
    return false;
  }

  return hasPermission;
}
import { CreateBranchDto } from "../../types/branch"
import { orderApi } from "./orderService";
import { ORDER_SERVICE_API } from ".";

const branchUrl = ORDER_SERVICE_API.BRANCH;

export const getBranches = async () => {
    return orderApi.get(`${branchUrl}`)
}

export const createBranch = async (payload: {
    name: string
    shortHand: string
}) => {
    return orderApi.post(`${branchUrl}`, payload)
}

export const bulkUploadBranches = async (branches: CreateBranchDto[]) => {
    return orderApi.post(`${branchUrl}/bulk`, branches); 
};
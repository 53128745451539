import React from 'react';
import { ReactSVG } from 'react-svg';

interface SVGProps {
  src: string;
  fillColor?: string;
  width?: string | number;
  height?: string | number;
}

const SVGComponent: React.FC<SVGProps> = ({ src, fillColor = 'black', width = '100px', height = '100px' }) => {
  return (
    <div style={{ width, height }}>
      <ReactSVG
        src={src}
        beforeInjection={(svg) => {
          svg.setAttribute('style', `width: ${width}; height: ${height}`);
          svg.querySelector('path')?.setAttribute('fill', fillColor);
        }}
      />
    </div>
  );
};

export default SVGComponent;

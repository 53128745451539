import { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import CustomFloatButton from "../../../../components/CustomFloatButton";
import BomList from "./BomList";
import { IBomItem, IBomItemForm } from "../../../../types/groyyo_plus/bomItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { getBomItem } from "../../../../services/orderServiceApi/bomApi";
import { useDispatch } from "react-redux";
import AddMaterialModal from "./AddMaterialModal";
import CollectNewMaterials from "./CollectNewMaterials";
import { calculateIndividualBomTotal } from "../../../../utilities/helper";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { sourcingRoute } from "../../../../routes";
import { BOMCreationType } from "../../../../constants/enums";
import dayjs from "dayjs";
import palette from "../../../../constants/pallete";
import RolePermission from "../../../../utilities/RolePermission/RolePermission";
import { useTranslation } from "react-i18next";

const CreateBomItem = () => {
  const { t }: any = useTranslation();

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const { salesOrderId } = useParams();

  const { selectedBom, selectedYetToCreateBomList } = useSelector(
    (state: RootState) => state.createBom
  );
  const [bomItems, setBomItems] = useState<IBomItemForm[]>([]);
  const [collectNewMaterials, setCollectNewMaterials] = useState<
    IBomItemForm[]
  >([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const fetchBomItem = async () => {
    if (!selectedBom) {
      navigate(`${sourcingRoute}/bom/create-bom/${salesOrderId}`);
      return;
    }
    setLoading(true);
    try {
      const response = await getBomItem({ bomItemId: +selectedBom?.bom?.id! });
      const responseData = response?.data;

      if (responseData?.status) {
        const responseBody = responseData?.result?.map((item: IBomItem) => {
          return {
            id: item?.id,
            quantity: item?.quantity,
            currency: item?.currency,
            extra: item?.extra,
            average: item?.average,
            rate: item?.rate,
            inHouseDate: item?.inHouseDate ? dayjs(item?.inHouseDate) : null,
            unit: item?.unit,
            supplier: item?.supplier,
            materialReceivable: null,
            total: calculateIndividualBomTotal(item as any),
            material:
              item?.material?.type === "Fabric"
                ? {
                    type: "Fabric",
                    id: item?.material?.id,
                    fabric: {
                      id: item?.material?.fabric?.id,
                      name: item?.material?.fabric?.name,
                      description: item?.material?.fabric?.description,
                      imageUrl: item?.material?.fabric?.imageUrl!,
                      composition: item?.material?.fabric?.composition,
                    },
                  }
                : {
                    type: "Trim",
                    id: item?.material?.id,
                    trim: {
                      id: item?.material?.trim?.id!,
                      name: item?.material?.trim?.name!,
                      description: item?.material?.trim?.description,
                      imageUrl: item?.material?.trim?.imageUrl!,
                    },
                  },
          };
        });
        setBomItems(responseBody);
      }
    } catch (error) {
      console.error(t("sales_order_bom.create_bom.bom_item.messages.error_fetching_bom"), error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      type === BOMCreationType.YET_TO_CREATE &&
      selectedYetToCreateBomList?.length === 0
    ) {
      navigate(`${sourcingRoute}/bom/create-bom/${salesOrderId}`);
      return;
    } else if (type === BOMCreationType.CREATED) {
      fetchBomItem();
    }
  }, [selectedBom, dispatch]);

  const handleAddMaterial = (selectedMaterial: IBomItemForm) => {
    if (type === BOMCreationType.CREATED) {
      setBomItems((prev: IBomItemForm[]) => [...prev, selectedMaterial]);
    } else if (type === BOMCreationType.YET_TO_CREATE) {
      setCollectNewMaterials((prev: IBomItemForm[]) => [
        ...prev,
        selectedMaterial,
      ]);
    }
    setIsModalVisible(false);
  };

  return (
    <div>
      {type === BOMCreationType.CREATED && (
        <BomList
          bomItems={bomItems}
          setBomItems={setBomItems}
          loading={loading}
        />
      )}
      {type === BOMCreationType.YET_TO_CREATE && (
        <CollectNewMaterials
          collectNewMaterials={collectNewMaterials}
          setCollectNewMaterials={setCollectNewMaterials}
        />
      )}
      <RolePermission module="BOM" actions={["CREATE", "EDIT"]}>
        <CustomFloatButton
          icon={<PlusOutlined style={{ fontSize: "24px" }} />}
          onClick={() => setIsModalVisible(true)}
          backgroundColor={palette.secondary}
          color={palette.white}
          bottom={32}
          right={32}
          size={60}
          shadow={true}
        />
      </RolePermission>
      <AddMaterialModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onAdd={handleAddMaterial}
        bomType={type as BOMCreationType}
      />
    </div>
  );
};

export default CreateBomItem;

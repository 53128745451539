import React, { useRef } from "react";

interface OTPInputProps {
  value: string;
  onChange: (value: string) => void;
  numInputs?: number;
  inputStyle?: string;
  focusStyle?: string;
}

const OTPInput: React.FC<OTPInputProps> = ({
  value,
  onChange,
  numInputs = 6,
  inputStyle = "",
  focusStyle = "",
}) => {
  const inputs = Array.from({ length: numInputs });
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
const inputValue = e.target.value.replace(/[^0-9]/g, ""); 
    if (!inputValue) return;

    const otpArray = value.split("");
    otpArray[index] = inputValue;
    onChange(otpArray.join(""));

    if (index < numInputs - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace") {
      const otpArray = value.split("");

      if (otpArray[index]) {        
        otpArray[index] = "";
        onChange(otpArray.join(""));
      } else if (index > 0) {        
        otpArray[index - 1] = "";
        onChange(otpArray.join(""));
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").replace(/[^0-9]/g, "").slice(0, numInputs);
    onChange(pastedData.padEnd(numInputs, ""));
  };

  return (
    <div className="flex justify-between gap-2">
      {inputs.map((_, index) => (
        <input
          key={index}
          type="text"
          value={value[index] || ""}
          maxLength={1}
          ref={(el) => (inputRefs.current[index] = el)}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleBackspace(e, index)}
          onPaste={handlePaste}
          className={`w-10 h-10 text-center text-lg border border-gray-300 rounded-md focus:outline-none focus:ring ${inputStyle} ${focusStyle}`}
        />
      ))}
    </div>
  );
};

export default OTPInput;

import { DatePicker, Input, Modal, Table } from "antd";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import CustomDropdownWithInput from "../../../../components/CustomDropdownInput";
import BulkTable from "./bulkTable";
import { createBulkSaleOrder } from "../../../../services/orderServiceApi/salesOrderBulkUpload";

const BulkPreview = ({ visible, onClose, initialData, dropdownData }: any) => {
  const [data, setData] = useState(initialData);
  const dropdownDataKeyMap: any = {
    "Customer Name": "buyers",
    Branch: "branches",
    Merchandiser: "user",
    Approver: "user",
    "Payment terms": "paymentTerms",
    "Delivery Type": "deliveryTypes",
    "Product Type": "productTypes",
    Currency: "currencies",
    Name: "items",
    Unit: "units",
    Category: "categories",
    Tax: "taxes",
  };

  useEffect(() => {
    setData(initialData);
  }, [initialData]);
  if (!initialData) return null;

  const canSave = () => {
    return data.some((row: any) => row.error);
  };

  const onSave = async () => {
    try {
      const body = data.map((salesOrder: any) => ({
        customerName: salesOrder?.customerName,
        branch: salesOrder?.branch,
        buyerPoNumber: salesOrder?.buyerPoNumber?.value,
        reference: salesOrder?.reference?.value,
        expectedMargin: salesOrder?.expectedMargin?.value,
        merchandiser: salesOrder.merchandiser,
        approver: salesOrder.approver,
        soDate: salesOrder?.soDate?.value,
        expectedDate: salesOrder?.expectedDate?.value,
        paymentTerms: salesOrder?.paymentTerms,
        deliveryType: salesOrder?.deliveryType,
        productType: salesOrder?.productType,
        currency: salesOrder.currency,

        discount: salesOrder?.discount?.value || 0,
        shipmentCharges: salesOrder?.shipmentCharges?.value || 0,
        customerNote: salesOrder?.customerNote?.value,
        tnc: salesOrder?.tnc?.value,
        salesOrderItems: salesOrder?.salesOrderItems.map(
          (salesOrderItem: any) => {
            return {
              itemName: salesOrderItem.name,
              quantity: salesOrderItem?.quantity?.value,
              rate: salesOrderItem?.rate?.value,
              tax: salesOrderItem?.tax,
              unit: salesOrderItem?.unit,
              category: salesOrderItem?.category,
              subCategory: salesOrderItem?.subCategory,
              hsnCode: salesOrderItem?.hsnCode?.value,
              taxPercentage: salesOrderItem?.taxPercentage?.value,
            };
          }
        ),
      }));
      await createBulkSaleOrder(body);
      onClose();
      alert("Orders saved Successfully");
    } catch (error) {
      alert("Something went wrong");
    }
  };

  return (
    <Modal
      title="Sales Order Details"
      style={{ top: 20 }}
      open={visible}
      onCancel={onClose}
      width={"90%"}
      footer={[
        <button
          key="back"
          onClick={onClose}
          className="px-4 py-2 bg-gray-200 rounded mr-2"
        >
          Cancel
        </button>,
        <button
          key="submit"
          onClick={onSave}
          className={`px-4 py-2 ${
            !canSave() ? "bg-blue-500" : "bg-gray-200"
          } text-white rounded`}
        >
          Save Changes
        </button>,
      ]}
    >
      <BulkTable data={data} />
    </Modal>
  );
};

export default BulkPreview;

import React, { useState, useEffect } from "react";
import { Form, Input, Button, message, Select } from "antd";
import { getRoles } from "../services/userServiceApi/rolesApi";
import { createUser } from "../services/userServiceApi/authApi";
import palette from "../constants/pallete";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const CreateUser: React.FC<{ onCreate: () => void }> = ({ onCreate }) => {
  const { t }: any = useTranslation();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getRoles();
        if (Array.isArray(response.data?.result)) {
          setRoles(response.data.result);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        message.error(t("createUserControl.createUser.user_creation_failed"));
      }
    };
    fetchRoles();
  }, [t]);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      const userData = {
        ...values,
        platform: "GROYYO_PLUS",
      };
      await createUser(userData);
      message.success(t("createUserControl.createUser.user_created_success"));
      onCreate();
    } catch (error) {
      message.error(t("createUserControl.createUser.user_creation_failed"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        label={t("createUserControl.createUser.name")}
        name="name"
        rules={[{ required: true, message: t("createUserControl.createUser.please_input_name") }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={t("createUserControl.createUser.email")} name="email">
        <Input />
      </Form.Item>
      <Form.Item
        label={t("createUserControl.createUser.mobile_no")}
        name="mobileNo"
        rules={[{ required: true, message: t("createUserControl.createUser.please_input_mobile") }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("createUserControl.createUser.role")}
        name="roleId"
        rules={[{ required: true, message: t("createUserControl.createUser.please_select_role") }]}
      >
        <Select placeholder={t("createUserControl.createUser.select_role_placeholder")}>
          {roles.map((role: any) => (
            <Option key={role.id} value={role.id}>
              {role.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading} block style={{ backgroundColor: palette.secondary }}>
        {t("createUserControl.createUser.create_button")}
      </Button>
    </Form>
  );
};

export default CreateUser;
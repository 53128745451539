import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { IProductionWIP } from '../../../../types/groyyo_plus/wip';
import palette from '../../../../constants/pallete';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface IWIPCardProps {
    wip: IProductionWIP;
    isSelected: boolean;
    onCheckboxChange: () => void;
    departmentMap: [];
}

const WIPCard = ({ wip, isSelected, onCheckboxChange, departmentMap }: IWIPCardProps) => {
    const { t }: any = useTranslation();
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore((prev) => !prev);
    };

    const getProductionDetails = () => {
        const data: { name: string; Completed: number; Rejected: number }[] = [];
        wip?.orderFlows
            ?.sort((a, b) => a.departmentSequence - b.departmentSequence)
            ?.forEach((orderFlow) => {
                const department:any = departmentMap?.find((dep: any) => dep?.id === orderFlow.departmentId)
                
                const departmentName = department?.name || `${t('production.wip.wip_card.department')} ${orderFlow.departmentId}`;

                data.push({
                    name: t(`production.wip.wip_card.departments.${departmentName}`, departmentName),
                    Completed: orderFlow.completedQuantity,
                    Rejected: orderFlow.rejectedQuantity || 0,
                });
            });
        return data;
    };

    return (
        <div
            className="bg-white shadow-md rounded-lg p-4 border relative"
            style={{ borderColor: palette.secondary }}
        >
            <div
                className="flex justify-between items-start px-6 py-4 text-black rounded-t-lg cursor-pointer"
                style={{
                    backgroundColor: palette.white,
                }}
                onClick={onCheckboxChange}
            >
                <div className="flex flex-col gap-2">
                    <div className="flex flex-row gap-3">
                        <img src={"/svg/OrderDress.svg"} alt={t('production.wip.wip_card.icon_alt')} className=" !w-[3.5rem] !h-[3.5rem] rounded-md" />
                        <div className="flex flex-col">
                            <span className="text-lg font-bold text-gray-600">{wip?.salesOrder.buyer.name}</span>
                            <span className="text-lg font-semibold text-gray-800">{wip?.item.name}</span>
                        </div>
                    </div>
                    <span className="text-base font-bold text-gray-700">{wip?.salesOrder.soNo}</span>
                </div>
                <div>
                    <input
                        type="checkbox"
                        className="w-5 h-5 cursor-pointer"
                        style={{
                            accentColor: palette.secondary,
                        }}
                        checked={isSelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={onCheckboxChange}
                    />
                </div>
            </div>
            <div className="px-6 py-4 text-gray-800">
                <div className="flex justify-between text-sm mb-2">
                    <span>{t('production.wip.wip_card.so_date')}</span>
                    <span>{moment(wip?.salesOrder?.soDate).format('DD-MM-YYYY')}</span>
                </div>
                <div className="flex justify-between text-sm mb-2">
                    <span>{t('production.wip.wip_card.expected_delivery_date')}</span>
                    <span>{moment(wip?.salesOrder?.expectedDate).format('DD-MM-YYYY')}</span>
                </div>
                <div className="flex justify-between text-sm">
                    <span>{t('production.wip.wip_card.expected_quantity')}</span>
                    <span>{wip?.quantity}</span>
                </div>
            </div>
            {showMore && (
                    <div className="px-6 py-4">
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                                data={getProductionDetails()}
                                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="name"
                                    label={{
                                        value: t('production.wip.wip_card.department'),
                                        position: 'insideBottom',
                                        offset: -5,
                                    }}
                                />
                                <YAxis />
                                <Tooltip
                                    formatter={(value, name) => [
                                        value,
                                        name === 'Completed'
                                            ? t('production.wip.wip_card.completed')
                                            : t('production.wip.wip_card.rejected'),
                                    ]}
                                />
                                <Legend
                                    formatter={(value) =>
                                        value === 'Completed'
                                            ? t('production.wip.wip_card.completed')
                                            : t('production.wip.wip_card.rejected')
                                    }
                                />
                                <Bar
                                    dataKey="Completed"
                                    name={t('production.wip.wip_card.completed')}
                                    fill="#13B466"
                                />
                                <Bar
                                    dataKey="Rejected"
                                    name={t('production.wip.wip_card.rejected')}
                                    fill="#ED6665"
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                )}
            <div className="flex justify-end px-6">
                <span
                    className="font-bold text-sm cursor-pointer"
                    style={{ color: palette.secondary }}
                    onClick={toggleShowMore}
                >
                    {showMore ? t('production.wip.wip_card.see_less_info') : t('production.wip.wip_card.see_more_info')}
                </span>
            </div>
        </div>
    );
};

export default WIPCard;
import React, { useEffect, useState } from 'react';
import DashboardLayout from '../layouts/DashboardLayout';
import { Button, Table, Modal, Form, Input, InputNumber, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { getTaxes, createTax, bulkUploadTaxes } from '../services/orderServiceApi/taxesApi';
import { ApiResponse } from '../types/response';
import { CreateTaxDto } from '../types/taxes';
import { render } from '@testing-library/react';
import DownloadTemplateButton from '../components/DownloadTemplateButton';

const Taxes = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [taxes, setTaxes] = useState<CreateTaxDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTaxes();
  }, []);

  const fetchTaxes = async () => {
    try {
      const response = await getTaxes();
      const responseData: ApiResponse<CreateTaxDto[]> = response?.data;
      if (responseData?.status && responseData?.statusCode === 200) {
        setTaxes(responseData.result);
      }
    } catch (error) {
      console.error("Failed to fetch taxes:", error);
    }
  };

  const handleAddTax = async (values: CreateTaxDto) => {
    setLoading(true);
    try {
      const response = await createTax(values);
      const responseData: ApiResponse<CreateTaxDto> = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Tax added successfully!");
        setIsModalVisible(false);
        fetchTaxes();
        form.resetFields();
      } else {
        message.error("Failed to add tax");
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error adding tax");
      console.error("Error adding tax:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBulkUpload = async (file: File) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: CreateTaxDto[] = XLSX.utils.sheet_to_json(worksheet);

      if (jsonData?.length === 0) {
        message.error("Empty File!");
        return;
      }

      if (!jsonData.every(item => item.name && item.desc && item.percentage)) {
        message.error('Invalid file format. Ensure it contains "name", "desc", and "percentage" columns.');
        return;
      }

      const response: any = await bulkUploadTaxes(jsonData);
      const responseData = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Taxes uploaded successfully!");
        fetchTaxes();
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error uploading taxes");
      console.error("Error uploading taxes:", error);
    }
  };

  const columns = [
    {
      title: 'Tax Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      key: 'desc',
      render: (text: string) => !text ? "-" : text,
      className: 'text-left'
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
      render: (text: number) => `${text}%`,
    }
  ];

  return (
    <DashboardLayout>
      <DashboardLayout.Header title="Manage Taxes">
        <DownloadTemplateButton templateName="bulk_taxes_template.xlsx" buttonText="Download Taxes Template" />
        <Button type="primary" onClick={() => setIsModalVisible(true)}>Add Tax</Button>
        <Upload
          beforeUpload={(file) => {
            handleBulkUpload(file);
            return false;
          }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Bulk Upload</Button>
        </Upload>
      </DashboardLayout.Header>
      <DashboardLayout.Body>
        <Table dataSource={taxes} columns={columns} rowKey="id" />

        <Modal
          title="Add New Tax"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleAddTax}
          >
            <Form.Item
              name="name"
              label="Tax Name"
              rules={[{ required: true, message: 'Please enter the tax name' }]}
            >
              <Input placeholder="Enter tax name" />
            </Form.Item>

            <Form.Item
              name="desc"
              label="Description"
              rules={[{ required: true, message: 'Please enter the description' }]}
            >
              <Input placeholder="Enter description" />
            </Form.Item>

            <Form.Item
              name="percentage"
              label="Percentage"
              rules={[{ required: true, message: 'Please enter the percentage' }]}
            >
              <InputNumber min={0} max={100} placeholder="Enter percentage" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Add Tax
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </DashboardLayout.Body>
    </DashboardLayout>
  );
}

export default Taxes;
export const en = {
    global: {
        no_data: "No data available.",
        loading: "Loading...",
        yes: "Yes",
        no: "No"
    },
    components: {
        custom_dropdown: {
            errors: {
                fetch_failed: "Failed to fetch items",
                required: "{{label}} is required"
            }
        },
        custom_dropdown_with_add: {
            errors: {
                fetch_failed: "Failed to fetch items",
                required: "{{label}} is required",
                add_failed: "Error while adding {{label}}"
            },
            no_data: "No data available",
            add_new: "Add New {{label}}",
            modal_title: "Add New {{label}}",
            modal_placeholder: "Enter {{label}}"
        },
        custom_dropdown_with_add_by_id: {
            errors: {
                fetch_failed: "Failed to fetch items.",
                parent_id_missing: "Please select a parent ID before creating an item.",
                field_required: "{{field}} is required.",
                add_failed: "Error while creating {{label}}."
            },
            messages: {
                no_data: "No data available."
            },
            buttons: {
                add_new: "Add New {{label}}"
            },
            modal: {
                title: "Add New {{label}}",
                placeholder: "Enter {{field}}"
            }
        },
        custom_dropdown_with_input: {
            no_matching_options: "No matching options",
            placeholder: "Select or enter a value"
        },
        factory_select: {
            placeholder: "Select a factory",
            no_factories: "No factories found",
            errors: {
                load_failed: "Failed to load factories.",
                fetch_error: "Error fetching factories."
            }
        },
        footer: {
            copy: "Groyyo Plus ©2024"
        },
        header: {
            title: "Groyyo Plus",
            errors: {
                parse_user_details: "Failed to parse user details"
            },
            language: {
                switch_success: "Language switched to {{lang}}"
            },
            menu: {
                profile: "Profile",
                factories: "Factories",
                logout: "Logout",
                default_user: "User"
            }
        },
        sortable_item: {
            untitled_process: "Untitled Process",
            dependency_on: "Dependency On",
            none: "None"
        }
    },
    home: {
        dashboard_title: "Dashboard",
        total_styles: "Total Styles",
        total_quantity: "Total Quantity",
        avg_delivery_days: "Avg. Delivery Days",
        total_values: "Total Values",
        completion_progress: "Completion Progress",
        dispatch_progress: "Dispatch Progress",
        delayed_materials: "Delayed Materials",
        dispatched_quantity: "Dispatched Quantity",
        delayed_orders: "Delayed Orders",
        completed_styles: "Completed Styles",
        days: "days",
        no_delayed_materials: "No delayed materials found.",
        no_delayed_orders: "No delayed orders found.",
        delayed_message: "Delayed by {{days}} days",
        filter: {
            filter: "Filter",
            title: "Filter Options",
            clear: "Clear Filter",
            apply: "Apply",
            buyer: "Buyer",
            style: "Style",
            sales_order: "Sales Order",
            product: "Product",
            date: "Date",
        },
    },
    sidebar: {
        module1: {
            go_to_users: "Go to Users",
            branches: "Branches",
            product_type: "Product Type",
            delivery_type: "Delivery Type",
            payment_term: "Payment Term",
            customers: "Customers",
            categories: "Categories",
            subcategories: "Subcategories",
            job_work_supplier: "Job Work Supplier",
            taxes: "Taxes"
        },
        module2: {
            home: "Home",
            order: "Order",
            sales_order: "Sales Order",
            bom: "BOM",
            material: "Material",
            receivable: "Receivable",
            issue: "Issue",
            material_tracking: "Material Tracking",
            production: "Production",
            wip: "WIP",
            tna: "TNA",
            dispatch: "Dispatch",
            users: "Users"
        },
        module3: {
            item1: "Module 3 Item 1",
            item2: "Module 3 Item 2"
        }
    },
    currentFactoryUser: {
        factory_members: "Factory Members",
        name: "Name",
        email: "Email",
        mobile_number: "Mobile Number",
        role: "Role",
        unexpected_response: "Unexpected response format.",
        fetch_error: "Failed to fetch users.",
    },
    createUserControl: {
        create_user: "Create User",
        user_created_success: "User created successfully!",
        user_creation_failed: "Failed to create user.",
        createUser: {
            name: "Name",
            email: "Email",
            mobile_no: "Mobile No",
            role: "Role",
            please_input_name: "Please input the name!",
            please_input_mobile: "Please input the mobile number!",
            please_select_role: "Please select a role!",
            select_role_placeholder: "Select a role",
            user_created_success: "User created successfully.",
            user_creation_failed: "Failed to create user.",
            create_button: "Create",
        },
    },
    readyForDispatch: {
        title: "Available for Dispatch",
        columns: {
            so_number: "SO Number",
            buyer_name: "Buyer Name",
            merchandiser: "Merchandiser",
            so_date: "SO Date",
            expected_date: "Expected Date"
        }
    },
    dispatch: {
        title: "Dispatch",
        no_available_data: "No Available Dispatch Data",
        so_no: "SO No:",
        buyer: "Buyer:",
        so_date: "SO Date:",
        expected_delivery: "Expected Delivery:",
        ready_for_dispatch: "Ready For Dispatch",
        dispatched: "Dispatched",
        overage: "Overage",
        view_history: "View History",
        start_inspection: "Start Inspection",
        dispatch: "Dispatch",
        mark_as_delivered: "Mark As Delivered",
        modal: {
            title: "Hold on",
            message: "There are still {{remainingOrders}} order(s) available! Are you sure that you want to mark this order as delivered?",
            cancel: "Cancel",
            confirm: "Yes",
        },
        messages: {
            marked_success: "Order marked as delivered successfully!",
            marked_failed: "Failed to mark the order as delivered.",
            delivery_error: "An error occurred while marking the order as delivered.",
            fetch_status_failed: "Failed to fetch delivery status.",
            status_error: "An error occurred while checking the delivery status.",
        },
        dispatchHistory: {
            title: "Dispatch History",
            no_data: "No dispatch history available.",
            dispatched_quantity: "Dispatch Quantity:",
            received_quantity: "Received Quantity:",
            rejected_quantity: "Rejected Quantity:",
            error_fetching: "Error fetching dispatch history.",
        },
        startInspection: {
            title: "Start Inspection",
            errors: {
                missingDispatchId: "Dispatch ID is missing. Please try again.",
                quantityMismatch:
                    "Inspected Quantity must equal the sum of Inspection Passed and Inspection Failed quantities.",
                submitError: "An error occurred while submitting inspection data.",
                requiredFields: "Please fill all required fields!",
            },
            success: {
                submitSuccess: "Inspection data submitted successfully!",
            },
            form: {
                inspectedQuantity: {
                    label: "Inspected Quantity",
                    placeholder: "Enter inspected quantity",
                    required: "Please enter inspected quantity",
                },
                passedQuantity: {
                    label: "Inspection Passed Quantity",
                    placeholder: "Enter passed quantity",
                    required: "Please enter passed quantity",
                },
                failedQuantity: {
                    label: "Inspection Failed Quantity",
                    placeholder: "Enter failed quantity",
                    required: "Please enter failed quantity",
                },
                date: {
                    label: "Inspection Date",
                    placeholder: "Select date",
                    required: "Please select an inspection date",
                },
            },
            submitButton: "Submit Inspection",
        },
        startDispatch: {
            title: "Start Dispatch",
            errors: {
                requiredFields: "Please fill all required fields!",
                submitError: "An error occurred while submitting dispatch data.",
            },
            success: {
                submitSuccess: "Dispatch data submitted successfully!",
            },
            form: {
                dispatchQuantity: {
                    label: "Dispatch Quantity",
                    placeholder: "Enter dispatch quantity",
                    required: "Please enter dispatch quantity",
                },
                dispatchDate: {
                    label: "Dispatch Date",
                    placeholder: "Select date",
                    required: "Please select a dispatch date",
                },
            },
            submitButton: "Submit Dispatch",
        },
    },
    tnaPage: {
        title: "TNA",
        loadingMessage: "Loading TNA orders...",
        noOrders: "No TNA orders available.",
        tnaCard: {
            selectTemplate: "Select Template",
            searchTemplate: "Search Template",
            createTNA: "Create TNA",
            loadingTemplates: "Loading templates...",
            custom: "Custom",
            add: "Add",
            soDate: "SO Date",
            expectedDeliveryDate: "Expected Delivery Date",
            processes: "Processes",
        },
        tnaDetails: {
            title: "TNA Process Timeline",
            loading: "Loading...",
            noDetails: "No TNA Details Found",
            plannedDate: "Planned Date",
            actualDate: "Actual Date",
            selectActualDate: "Select Actual Date",
            save: "Save",
            addActualDate: "Add Actual Date",
            dependencyOn: "Dependency On",
            updateSuccess: "Actual date updated successfully!",
            updateFailed: "Failed to update actual date.",
            updateError: "An error occurred while updating the actual date.",
            selectDateError: "Please select an actual date!",
        },
        create: {
            title: "Create TNA",
            add_new_process: "Add New Process",
            process: "Process",
            process_name: "Process Name",
            process_placeholder: "Search or Select Process Name",
            dependency_on: "Dependency On",
            dependency_on_placeholder: "Search or Select Dependency On",
            plan_date: "Plan Date",
            manual_entry: "Manual Entry for Actual Date",
            save: "Save",
            errors: {
                fix_before_save: "Please fix the errors before saving.",
                plan_date_required: "Plan Date is required",
            },
        },
        create_tna_template: {
            title: "Create TNA Template",
            template_name: "Template Name",
            template_name_placeholder: "Enter Template Name",
            process: "Process",
            process_name: "Process Name",
            process_name_placeholder: "Search or Select Process Name",
            dependency_on: "Dependency On",
            dependency_on_placeholder: "Search or Select Dependency On",
            manual_entry: "Manual Entry for actual Date",
            add_process: "Add Process",
            save: "Save",
            rearrange_processes: "Rearrange Processes",
            errors: {
                template_name_required: "Template Name is required",
                at_least_one_process: "At least one process is required!",
                validation_errors: "Please fix the validation errors before rearranging the processes.",
                save_failed: "Failed to save the template. Please try again.",
                save_success: "TNA Template created successfully!"
            }
        }
    },
    production: {
        wip: {
            title: "Work In Progress (WIP)",
            clear_all: "Clear All",
            no_data: "No data available.",
            download_selected: "Download Selected",
            file_downloaded: "File downloaded successfully!",
            no_cards_selected: "No cards selected for download.",
            no_data_for_selected_cards: "No data found for selected cards.",
            excel_sheet_name: "Production WIP",
            excel_headers: {
                buyer: "Buyer",
                so_number: "SO Number",
                style_number: "Style Number",
                so_date: "SO Date",
                order_quantity: "Order Quantity",
                expected_delivery_date: "Expected Delivery Date",
                fabric_in_house_date: "Fabric In-House Date",
                cutting_date: "Cutting Date",
                cutting_quantity: "Cutting Quantity",
                sewing_date: "Sewing Date",
                sewing_quantity: "Sewing Quantity",
                finishing_date: "Finishing Date",
                finishing_quantity: "Finishing Quantity",
                dispatch_date: "Dispatch Date",
                dispatch_quantity: "Dispatch Quantity"
            },
            wip_card: {
                icon_alt: "Order Icon",
                department: "Department",
                so_date: "SO Date",
                expected_delivery_date: "Expected Delivery Date",
                expected_quantity: "Expected Quantity",
                completed: "Completed",
                rejected: "Rejected",
                see_more_info: "See More Info",
                see_less_info: "See Less Info",
                departments: {
                    SEWING: "SEWING",
                    FINISHING: "FINISHING",
                    CUTTING: "CUTTING",
                }
            }
        }
    },
    material: {
        history_modal: {
            title: "History",
            no_data: "No history available.",
            labels: {
                received_quantity: "Received Quantity",
                rejected_quantity: "Rejected Quantity",
                issued_quantity: "Issued Quantity"
            }
        },
        receivable: {
            recievable_list: {
                title: "Material Receivable",
                loading: "Loading...",
                error_fetching_list: "Error fetching Receivable list:"
            },
            list: {
                title: "Material Receivable",
                columns: {
                    so_number: "SO Number",
                    buyer_name: "Buyer Name",
                    merchandiser: "Merchandiser",
                    so_date: "SO Date",
                    expected_date: "Expected Date"
                },
                loading: "Loading..."
            },
            material_sales_order_item: {
                back_button_tooltip: "Go back to list",
                page_title: "Select Items",
                loading: "Loading...",
                no_items_available: "No items available",
                item_details: {
                    amount: "Amount",
                    unit: "Unit",
                    category_separator: "/"
                }
            },
            recievable_list_item: {
                fields: {
                    accepted_quantity: "Accepted Quantity",
                    rejected_quantity: "Rejected Quantity",
                    received_date: "Received Date"
                },
                validation_messages: {
                    accepted_quantity_required: "Accepted Quantity is required!",
                    rejected_quantity_required: "Rejected Quantity is required!",
                    received_date_required: "Received Date is required!"
                },
                buttons: {
                    save: "Save"
                },
                labels: {
                    expected_delivery_date: "Expected Delivery Date",
                    total_ordered_quantity: "Total Ordered Quantity",
                    received_quantity: "Received Quantity",
                    pending_quantity: "Pending Quantity",
                    view_history: "View History"
                },
                messages: {
                    success: "Material receivable successfully assigned!",
                    error: "An error occurred while saving the material receivable.",
                    failed_to_save: "Failed to save material receivable."
                },
                form_errors: {
                    validation_failed: "Please fill all required fields correctly."
                }
            },

        },
        issued: {
            title: "Material Issued",
            table: {
                so_number: "SO Number",
                buyer_name: "Buyer Name",
                merchandiser: "Merchandiser",
                so_date: "SO Date",
                expected_date: "Expected Date"
            },
            list: {
                title: "Material Issued",
                errors: {
                    fetch_failed: "Error fetching Issued list:"
                },
                no_data: "No items available"
            },
            list_item: {
                expected_delivery_date: "Expected Delivery Date",
                total_ordered_quantity: "Total Ordered Quantity",
                issued_quantity: "Issued Quantity",
                pending_quantity: "Pending Quantity",
                view_history: "View History",
                department: "Department",
                select_department: "Select Department",
                issued_quantity_placeholder: "Enter Issued Quantity",
                issue_date: "Issue Date",
                issue_date_placeholder: "Select Issue Date",
                save: "Save",
                messages: {
                    issue_success: "Material issued successfully!",
                    issue_failed: "Failed to issue material.",
                    unexpected_error: "Unexpected error occurred.",
                    validation_failed: "Form validation failed.",
                    fill_required_fields: "Please fill all required fields correctly.",
                    quantity_required: "Issued Quantity is required!",
                    date_required: "Issue Date is required!"
                },
                selected_department: "Selected Department:"
            }
        },
        tracking: {
            no_data: "No history available.",
            title: "Material Tracking",
            clear_all: "Clear All",
            download_selected: "Download Selected",
            excel_sheet_name: "Materials",
            file_name_prefix: "wip-materials",
            messages: {
                no_cards_selected: "No cards selected for download.",
                material_not_found: "Material with ID {{id}} not found.",
                no_data_found: "No data found for selected cards.",
                file_downloaded: "Downloaded file: {{fileName}}"
            },
            excel_headers: {
                buyer: "Buyer",
                so_number: "SO Number",
                style_number: "Style Number",
                so_date: "SO Date",
                order_quantity: "Order Quantity",
                material: "Material",
                unit: "Unit",
                type: "Type",
                average: "Average",
                expected_quantity: "Expected Quantity",
                received_quantity: "Received Quantity",
                rejected_quantity: "Rejected Quantity",
                expected_in_house_date: "Expected In-House Date",
                actual_in_house_date: "Actual In-House Date"
            },
            card: {
                so_date: "SO Date",
                expected_delivery: "Expected Delivery",
                material_delayed: "Material Delayed",
                material_pending: "Material Pending",
                material_completed: "Material Completed",
                material_name: "Material Name",
                type: "Type",
                in_house_date: "In House Date",
                actual_date: "Actual Date",
                received: "Received",
                rejected: "Rejected",
                pending: "Pending",
                see_more_info: "See More Info",
                see_less_info: "See Less Info"
            }
        }
    },
    sales_order_bom: {
        bom: {
            title: "BOM",
            so_number: "SO Number",
            buyer_name: "Buyer Name",
            merchandiser: "Merchandiser",
            reference: "Reference",
            so_date: "SO Date",
            expected_date: "Expected Date"
        },
        create_bom: {
            title: "Manage BOM for Your Sales Order",
            already_created_title: "BOM Items Already Created",
            yet_to_create_title: "BOM Creation Needed for These Items",
            create_button: "Create",
            error_fetching_items: "Error fetching sales order items.",
            bom_item: {
                messages: {
                    error_fetching_bom: "Error fetching BOM items.",
                }
            }
        },
        bom_list: {
            validation_failed: "Validation failed. Please check errors in the form.",
            submission_success: "Forms submitted successfully!",
            submission_error: "An error occurred during submission.",
            error_fetching_details: "Error fetching BOM details.",
            download_filename: "Purchase_Order.pdf",
            download_button: "Download",
            submit_button: "Submit All"
        },
        bom_list_item: {
            supplier_label: "Supplier",
            supplier_placeholder: "Search or Select Supplier",
            supplier_help: "Please select a supplier",
            quantity_label: "Quantity",
            quantity_placeholder: "Enter Quantity",
            quantity_help: "Please enter the quantity",
            unit_label: "Unit",
            unit_placeholder: "Search or Select Unit",
            unit_name_label: "Unit Name",
            unit_help: "Please select a unit",
            currency_label: "Currency",
            currency_placeholder: "Select Currency",
            currency_help: "Please select a currency",
            extra_label: "Extra",
            extra_placeholder: "Enter Extra",
            extra_help: "Please enter the extra",
            average_label: "Average",
            average_placeholder: "Enter Average",
            average_help: "Please enter the average",
            rate_label: "Rate",
            rate_placeholder: "Enter Rate",
            rate_help: "Please enter the rate",
            in_house_date_label: "In House Date",
            in_house_date_placeholder: "Select In-House Date",
            in_house_date_help: "Please select the in-house date",
            common: {
                name: "Name",
                address_line_1: "Address Line 1",
                address_line_2: "Address Line 2",
                city: "City",
                state: "State",
                country: "Country",
                pin_code: "Pin Code",
                gst_no: "GST No",
                contact_person: "Contact Person",
                contact_no: "Contact No",
                email: "Email"
            },
        },
        add_material_modal: {
            title: "Add Material",
            search_placeholder: "Search Material",
            type_placeholder: "Select Type",
            type_fabric: "Fabric",
            type_trim: "Trim",
            add_new_button: "Add New"
        },
        collect_new_materials: {
            validation_failed: "Validation failed. Please check errors in the form.",
            success_message: "Forms submitted successfully!",
            submit_all: "Submit All",
            list_item: {
                supplier: "Supplier",
                supplier_placeholder: "Search or Select Supplier",
                supplier_help: "Please select a supplier",
                quantity: "Quantity",
                quantity_placeholder: "Enter Quantity",
                quantity_help: "Please enter the quantity",
                unit: "Unit",
                unit_placeholder: "Search or Select Unit",
                unit_help: "Please select a unit",
                currency: "Currency",
                currency_placeholder: "Select Currency",
                currency_help: "Please select a currency",
                extra: "Extra",
                extra_placeholder: "Enter Extra",
                extra_help: "Please enter the extra",
                average: "Average",
                average_placeholder: "Enter Average",
                average_help: "Please enter the average",
                rate: "Rate",
                rate_placeholder: "Enter Rate",
                rate_help: "Please enter the rate",
                in_house_date: "In-House Date",
                in_house_date_placeholder: "Select Date",
                in_house_date_help: "Please select the in-house date"
            },
            supplier_form: {
                name: "Name",
                address1: "Address Line 1",
                address2: "Address Line 2",
                city: "City",
                state: "State",
                country: "Country",
                pin_code: "Pin Code",
                gst_no: "GST No",
                contact_person: "Contact Person",
                contact_no: "Contact No",
                email: "Email"
            },
            unit_form: {
                name: "Unit Name"
            }
        },
        create_new_material: {
            tabs: {
                fabric: "Fabric",
                trim: "Trim"
            },
            form: {
                name: "Name",
                name_placeholder: "Enter name",
                name_required: "Name is required",
                composition: "Composition",
                composition_placeholder: "Enter composition",
                composition_required: "Composition is required",
                description: "Description",
                description_placeholder: "Optional",
                hsn_code: "HSN Code",
                hsn_code_placeholder: "Optional",
                color: "Color",
                color_placeholder: "Search or Select Color",
                color_required: "Please select a color",
                color_modal: {
                    name: "Color Name",
                    hex_code: "Hex Code"
                },
                size: "Size",
                size_placeholder: "Search or Select Size",
                size_required: "Please select a size",
                size_modal: {
                    name: "Size Name"
                },
                unit: "Unit",
                unit_placeholder: "Search or Select Unit",
                unit_required: "Please select a unit",
                unit_modal: {
                    name: "Unit Name"
                }
            },
            image: {
                upload: "Upload Image",
                upload_success: "Image uploaded successfully",
                upload_failed: "Image upload failed",
                upload_requirement: "Image must be smaller than 5MB!",
                image_required: "Image is required."
            },
            actions: {
                done: "Done",
                cancel: "Cancel"
            }
        },
    },
    sales_order: {
        title: "Sales Order",
        actions: {
            create_sales_order: "Create Sales Order",
            create_single_order: "Create Single Sales Order",
            create_bulk_order: "Create Bulk Sales Order"
        },
        list: {
            columns: {
                so_number: "SO Number",
                buyer_name: "Buyer Name",
                merchandiser: "Merchandiser",
                reference: "Reference",
                so_date: "SO Date",
                expected_date: "Expected Date",
                total_amount: "Total Amount"
            },
            messages: {
                fetch_error: "Failed to fetch sales orders"
            }
        },
        creation: {
            title: "Create Sales Order",
            steps: {
                order_detail: "Order Detail",
                item_detail: "Item Detail",
                billing_detail: "Billing Detail"
            },
            order_detail: {
                fields: {
                    customer_name: "Customer Name",
                    branch: "Branch",
                    so_name: "SO Name",
                    reference: "Reference",
                    expected_margin: "Expected Margin",
                    merchandiser: "Merchandiser",
                    approver: "Approver",
                    so_date: "SO Date",
                    expected_delivery_date: "Expected Delivery Date",
                    payment_terms: "Payment Terms",
                    delivery_type: "Delivery Type",
                    product_type: "Product Type",
                    currency: "Currency"
                },
                placeholders: {
                    customer_name: "Search or Select Customer",
                    branch: "Search or Select Branch",
                    so_name: "Select branch, SO Name will display here",
                    reference: "Enter Reference",
                    expected_margin: "Enter Expected Margin",
                    merchandiser: "Select Merchandiser",
                    approver: "Select Approver",
                    so_date: "Select SO Date",
                    expected_delivery_date: "Select Expected Delivery Date",
                    payment_terms: "Search or Select Payment Terms",
                    delivery_type: "Search or Select Delivery Type",
                    product_type: "Search or Select Product Type",
                    currency: "Select Currency"
                },
                errors: {
                    reference_required: "Reference is required",
                    expected_margin_required: "Expected Margin is required",
                    so_date_required: "SO Date is required",
                    expected_delivery_date_required: "Expected Delivery Date is required"
                },
                buttons: {
                    save: "Save"
                },
                messages: {
                    success: "Successfully created Order Detail"
                }
            },
            item_details: {
                fields: {
                    quantity: "Quantity",
                    rate: "Rate",
                    total: "Total",
                    tax: "Tax",
                    tax_name: "Tax Name",
                    tax_desc: "Description",
                    tax_percentage: "Percentage"
                },
                placeholders: {
                    quantity: "Enter Quantity",
                    rate: "Enter Rate",
                    tax: "Search or Select Tax"
                },
                errors: {
                    quantity_required: "Quantity is required",
                    rate_required: "Rate is required",
                    submit_failed: "Failed to submit item details"
                },
                buttons: {
                    next: "Next"
                },
                messages: {
                    success: "Successfully created Item Details"
                }
            },
            billing_details: {
                fields: {
                    sub_total: "Sub Total",
                    discount: "Discount",
                    shipment_charges: "Shipment Charges",
                    total: "Total",
                    customer_note: "Customer Note",
                    terms_and_conditions: "Terms and Conditions",
                    attachments: "Attachments"
                },
                placeholders: {
                    discount: "Enter Discount",
                    shipment_charges: "Enter Shipment Charges",
                    customer_note: "Enter Customer Note",
                    terms_and_conditions: "Enter Terms and Conditions"
                },
                errors: {
                    file_size: "File {{file}} must be less than 5MB!",
                    file_format: "File {{file}} has an invalid format!",
                    upload_failed: "Failed to upload {{file}}. Please try again.",
                    upload_network: "Error uploading {{file}}. Please check your network or try again.",
                    attach_po: "Attach PO file",
                    customer_note_required: "Customer note is required",
                    terms_conditions_required: "Terms and conditions are required"
                },
                buttons: {
                    upload_files: "Upload Files",
                    create_order: "Create Order"
                },
                messages: {
                    file_uploaded: "{{file}} uploaded successfully!",
                    order_created: "Sales Order created successfully!"
                }
            }
        },
        create_bulk_order: {
            heading:{
                create_sales_order:"Create Sales Order"
            },
            disclaimer:{
                important_notice:"Important Notice",
                please_ensure_your_data_follows_the_template_format_for_successful_upload: "Please ensure your data follows the template format for successful upload. Maximum file size is 10MB.",
            },
            template_card: {
                download_template:"Download Template",
                download_our_standardized_template_to_ensure_proper_data_formatting : "Download our standardized template to ensure proper data formatting", 
                templateFeatures: {
                    preformatted_columns_with_examples : "Pre-formatted columns with examples",
                    data_validation_rules: "Data validation rules",
                    import_guidelines : "Import guidelines",
                    sample_data_entries : "Sample data entries",
                }
            },
            upload_card: {
                upload_data:"Upload Data",
                click_or_drag_file_to_this_area_to_upload:"Click or drag file to this area to upload",
                support_for_excel_and_CSV_files_only:"Support for Excel and CSV files only",
                upload_file:"Upload File"
            }
        },
        updation: {
            billing_details: {
                modal: {
                    title: "Update Billing Details"
                },
                fields: {
                    discount: "Discount (%)",
                    shipment_charges: "Shipment Charges",
                    total: "Total",
                    customer_note: "Customer Note",
                    terms_and_conditions: "Terms and Conditions"
                },
                placeholders: {
                    discount: "Enter discount in percentage",
                    shipment_charges: "Enter shipment charges",
                    customer_note: "Enter any notes for the customer",
                    terms_and_conditions: "Enter terms and conditions"
                },
                errors: {
                    discount_required: "Discount is required.",
                    shipment_charges_required: "Shipment charges are required.",
                    customer_note_required: "Customer note is required.",
                    terms_conditions_required: "Terms and conditions are required."
                },
                messages: {
                    update_success: "Order details updated successfully!",
                    update_fail: "Failed to update order details. Please try again.",
                    validation_error: "Please correct the highlighted fields."
                },
                buttons: {
                    update: "Update"
                }
            },
            item_details: {
                title:"Update Sales Order Items",
                fields: {
                    quantity: "Quantity",
                    rate: "Rate",
                    total: "Total",
                    tax: "Tax"
                },
                placeholders: {
                    quantity: "Enter quantity",
                    rate: "Enter rate",
                    tax: "Select tax"
                },
                errors: {
                    quantity_required: "Quantity is required.",
                    quantity_min: "Quantity must be at least 1.",
                    rate_required: "Rate is required.",
                    rate_min: "Rate must be a non-negative number."
                },
                messages: {
                    update_success: "Item details updated successfully!",
                    update_fail: "Failed to update item details. Please try again.",
                    save_success: "New items saved successfully!",
                    save_fail: "Failed to save new items. Please try again.",
                    validation_error: "Please correct the highlighted fields.",
                    duplicate_item_warning: "This item has already been added."
                },
                buttons: {
                    update: "Update",
                    save: "Save"
                },
                headers: {
                    new_items: "New Items"
                }
            }
        },
        single_order_detail: {
            order_detail: "Order Detail",
            order_details: "Order Details",
            buyer: "Buyer",
            so_number: "SO Number",
            reference: "Reference",
            so_date: "SO Date",
            expected_delivery_date: "Expected Delivery Date",
            item_details: "Item Details",
            style: "Style",
            quantity: "Quantity",
            rate: "Rate",
            tax: "Tax",
            total: "Total",
            bom_details: "BOM Details",
            material: "Material",
            extra: "Extra",
            received_quantity: "Received Quantity",
            rejected_quantity: "Rejected Quantity",
            issued_quantity: "Issued Quantity",
            production_details: "Production Details",
            dispatch_details:"Dispatch Details",
            flow: "Flow",
            pass_quantity: "Pass Quantity",
            billing_details: "Billing Details",
            customer_note: "Customer Note",
            no_customer_note: "No customer note provided.",
            sub_total: "Sub Total",
            discount: "Discount",
            shipment_charges: "Shipment Charges",
            attachments: "Attached PO Files",
            upload_files: "Upload Files",
            max_file_size: "Max File Size: 5 MB",
            delete_icon_alt: "Delete Icon",
            dispatch_date: "Dispatch Date",
            dispatch_quantity: "Dispatch Quantity"
        },
        departments: {
            cutting: "Cutting",
            sewing: "Sewing",
            finishing: "Finishing"
        },
        department_not_found: "Department not found",
        file: "File",
        file_icon_alt: "File Icon",
        delete_confirm_title: "Are you sure you want to delete this file?",
        deletion_cancelled: "Deletion cancelled",
        delete_success: "deleted successfully",
        delete_error: "Failed to delete",
        try_again: "Please try again",
        upload_success: "uploaded successfully!",
        upload_fail: "Failed to upload.",
        upload_error: "Error uploading. Please try again.",
        invalid_file_format: "Invalid file format.",
        edit_order_detail: {
            edit_order_detail: "Edit Order Detail",
            fields: {
                customer_name: "Customer Name",
                branch: "Branch",
                so_name: "SO Name",
                reference: "Reference",
                expected_margin: "Expected Margin",
                merchandiser: "Merchandiser",
                approver: "Approver",
                so_date: "SO Date",
                expected_delivery_date: "Expected Delivery Date",
                payment_terms: "Payment Terms",
                delivery_type: "Delivery Type",
                product_type: "Product Type",
                currency: "Currency"
            },
            placeholder: {
                search_or_select_customer: "Search or Select customer",
                search_or_select_branch: "Search or Select branch",
                select_branch_so_name_will_display_here: "Select branch, SO Name will display here",
                enter_reference: "Enter Reference",
                enter_expected_margin: "Enter Expected Margin",
                select_merchandiser: "Select Merchandiser",
                select_approver: "Select Approver",
                search_or_select_payment_terms: "Search or Select Payment Terms",
                search_or_select_delivery_type: "Search or Select Delivery Type",
                search_or_select_product_type: "Search or Select Product Type",
                select_currency: "Select currency",
                expected_delivery_date_is_required:"Expected Delivery Date is required",
                so_date_is_required:"SO Date is required",
                expected_margin_is_required:"Expected Margin is required",
                reference_is_required:"Reference is required"
            },            
            buttons:{
                save:"save",
                cancel:"cancel"
            }
        }
    },
};
import React, { useEffect, useState } from "react";
import { getTnaOrders } from "../../../services/orderServiceApi/salesOrderItemApi";
import { ITNAOrder } from "../../../types/groyyo_plus/tna";
import TNACard from "./TNACard";
import { Skeleton, Empty } from "antd";
import { useTranslation } from "react-i18next";

const TNAPage = () => {
  const { t }: any = useTranslation();
  const [tnaOrders, setTnaOrders] = useState<ITNAOrder[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchTnaOrders = async () => {
    setLoading(true);
    try {
      const response = await getTnaOrders();
      const responseData = response?.data;

      if (responseData?.status) {
        setTnaOrders(responseData?.result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTnaOrders();
  }, []);

  return (
    <>
      <div className="text-2xl font-bold flex flex-row gap-2 items-center justify-between w-full mb-4">
        <p>{t("tnaPage.title")}</p>
      </div>
      <div className="flex flex-col gap-3">
        {loading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <Skeleton.Node
              key={index}
              active
              style={{ width: "100%", height: "20vh" }}
            />
          ))
        ) : tnaOrders.length > 0 ? (
          tnaOrders.map((tna) => <TNACard tna={tna} key={tna?.id} />)
        ) : (
          <Empty description={t("tnaPage.noOrders")} />
        )}
      </div>
    </>
  );
};

export default TNAPage;
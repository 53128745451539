import { USER_SERVICE_API } from ".";
import { userApi } from "./userService";

const factoriesUrl = USER_SERVICE_API.FACTORIES;

export const factoryList = async () => {
    return userApi.get(`${factoriesUrl}/list`)
}


export const generateFactoryToken = async (payload: { factoryId: number }) => {
    return userApi.post(`${factoriesUrl}/generate-token`, payload)
}
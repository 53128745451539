import React, { useEffect, useState } from 'react'
import DashboardLayout from '../layouts/DashboardLayout'
import { Button, Table, Modal, Form, Input, InputNumber, message, Upload } from 'antd';
import { CreatePaymentTermDto, IPaymentTerm } from '../types/paymentTerm';
import { ApiResponse } from '../types/response';
import { bulkUploadPaymentTermes, createPaymentTerm, getPaymentTerms } from '../services/orderServiceApi/paymentTermApi';
import * as XLSX from 'xlsx';
import { UploadOutlined } from '@ant-design/icons';
import DownloadTemplateButton from '../components/DownloadTemplateButton';

const Payments = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [paymentTerms, setPaymentTerms] = useState<IPaymentTerm[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchPaymentTerms();
  }, []);

  const fetchPaymentTerms = async () => {
    try {
      const response = await getPaymentTerms();
      const responseData: ApiResponse<IPaymentTerm[]> = response?.data;
      if (responseData?.status && responseData?.statusCode === 200) {
        setPaymentTerms(responseData.result);
      }
    } catch (error) {
      console.error("Failed to fetch branches:", error);
    }
  };


  const columns = [
    {
      title: 'Payment Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Advance Percentage',
      dataIndex: 'advancePercentage',
      key: 'advancePercentage',
    },
    {
      title: 'Due Day',
      dataIndex: 'dueDay',
      key: 'dueDay',
    }
  ];

  const handleAddPaymentTerm = async (values: CreatePaymentTermDto) => {
    setLoading(true);
    try {
      const response = await createPaymentTerm(values);
      const responseData: ApiResponse<IPaymentTerm> = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Payment term added successfully!");
        setIsModalVisible(false);
        fetchPaymentTerms();
        form.resetFields();
      } else {
        message.error("Failed to add payment term");
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error adding payment term");
      console.error("Error adding payment term:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBulkUpload = async (file: File) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: CreatePaymentTermDto[] = XLSX.utils.sheet_to_json(worksheet);

      if(jsonData?.length === 0){
        message.error("Empty File!")
        return;
      }

      if (!jsonData.every(item => item.name && item.advancePercentage && item.dueDay)) {
        message.error('Invalid file format. Ensure it contains "name", "advancePercentage", and "dueDay" columns.');
        return;
      }

      const response: any = await bulkUploadPaymentTermes(jsonData);
      const responseData = response?.data;
      if (responseData?.status && responseData?.statusCode === 201) {
        message.success("Payment terms uploaded successfully!");
        fetchPaymentTerms();
      } 
    } catch (error: any) {
      message.error(error?.response?.data?.message ?? "Error uploading payment terms");
      console.error("Error uploading payment terms:", error);
    }
  };

  return (
    <DashboardLayout>
        <DashboardLayout.Header title="Manage Payments">
          <DownloadTemplateButton templateName="bulk_paymentTerms_template.xlsx" buttonText="Download Payment Terms Template" />
          <Button type="primary" onClick={() => setIsModalVisible(true)}>Add Payment Term</Button>
          <Upload
            beforeUpload={(file) => {
              handleBulkUpload(file);
              return false;
            }}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Bulk Upload</Button>
          </Upload>
      </DashboardLayout.Header>
      <DashboardLayout.Body>
        <Table dataSource={paymentTerms} columns={columns} rowKey="id" />


        <Modal
        title="Add New Payment Term"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddPaymentTerm}
        >
          <Form.Item
            name="name"
            label="Payment Name"
            rules={[{ required: true, message: 'Please enter the payment name' }]}
          >
            <Input placeholder="Enter payment name" />
          </Form.Item>

          <Form.Item
            name="advancePercentage"
            label="Advance Percentage"
            rules={[{ required: true, message: 'Please enter the advance percentage' }]}
          >
            <InputNumber min={0} max={100} placeholder="Enter advance percentage" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="dueDay"
            label="Due Day"
            rules={[{ required: true, message: 'Please enter the due day' }]}
          >
            <InputNumber min={1} placeholder="Enter due day" style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Add Payment Term
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      </DashboardLayout.Body>
    </DashboardLayout>
  )
}

export default Payments
